import { Component, OnInit } from '@angular/core';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-terms-of-service-modal',
  templateUrl: './terms-of-service-modal.component.html'
})
export class TermsOfServiceModalComponent implements OnInit {

  constructor (public modalRef: BsModalRef) {
  }

  ngOnInit(): void {
  }

  close() {
    this.modalRef.hide();
  }

}
