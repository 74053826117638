import {ObjectNode} from '../../../../models/tenant-mapping/object-node';
import {ChildObjectsSearch} from '../../../../models/tenant-mapping/child-objects-search';
import {ChildObjectsSearchParams} from '../../../../models/tenant-mapping/child-object-search-params';
import {CumuService} from '../../../../http/cumu.service';
import {Observable} from 'rxjs/internal/Observable';
import {NodeType} from '../../../../models/tenant-mapping/node-type';
import {ManagedObject} from '../../../../models/tenant-mapping/managed-object';
import {map, switchMap} from 'rxjs/operators';
import {of} from 'rxjs/internal/observable/of';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {flatMap} from 'rxjs/internal/operators';
import {SupportedSourcesResponse} from '../../../../models/source';
import {ManagedObjectNodeStore} from '../store/managed-object-node-store';
import {Executor} from '../../../../../core/executors/executor';
import {TenantBrowserConstants} from '../../../../models/tenant-browser-constants';

export type CompletedCallback = (children: ObjectNode[], completed: boolean) => void;

export class LoadChildrenExecutor implements Executor<CompletedCallback, void> {

  private readonly node: ObjectNode;

  private activeSearch: ChildObjectsSearch;

  private childAssetsQuery = true;

  private cumuService: CumuService;

  private tenantId: number;

  private managedObjectNodeStore: ManagedObjectNodeStore;

  private onlyWithMeasurements: boolean;

  constructor(node: ObjectNode,
              tenantId: number,
              managedObjectNodeStore: ManagedObjectNodeStore,
              cumuService: CumuService,
              onlyWithMeasurements: boolean) {
    this.node = node;
    this.tenantId = tenantId;
    this.managedObjectNodeStore = managedObjectNodeStore;
    this.cumuService = cumuService;
    this.onlyWithMeasurements = onlyWithMeasurements;
  }

  public execute(callback: CompletedCallback): void {

    this.doSearch(this.node, this.node.children, callback);

  }

  public isOnlyWithMeasurements(): boolean {
    return this.onlyWithMeasurements;
  }

  private doSearch(parentNode: ObjectNode, children: ObjectNode[], callback: CompletedCallback): void {
    if (!parentNode.managedObject) {
      callback([], false);
      return;
    }

    let loadMoreNodeId = null;
    if (children && children.length > 0) {
      let lastChild = children[children.length - 1];
      if (lastChild.name == 'LOAD_MORE') {
        loadMoreNodeId = lastChild.id;
      }
    }

    const search = this.composeChildObjectSearch();

    this.getChildDevices(parentNode, search).subscribe(
      async (data: any) => {
        search.statistics = data.statistics;
        search.params.currentPage = data.statistics.currentPage;
        let references = data.references;
        let added = 0;
        for (let reference of references) {
          let managedObject = reference.managedObject;
          if (!this.managedObjectNodeStore.get(managedObject.id)) {
            let addChild = true;

            if (this.onlyWithMeasurements) {
              let hasDevices = await this.checkHasDeviceWithMeasurements(managedObject);
              if (!hasDevices) {
                addChild = false;
                console.debug(managedObject.id + " " + managedObject.name + " has no devices");
              } else {
                console.debug(managedObject.id + " " + managedObject.name + " has devices");
              }
            }

            if (addChild) {
              children.push(this.managedObjectNodeStore.createFromManagedObject(managedObject));
              added++;
            }

          }
        }


        if (loadMoreNodeId != null) {
          this.managedObjectNodeStore.delete(loadMoreNodeId);
          children = children.filter(c => c.id !== loadMoreNodeId);
        }

        if (data.statistics.currentPage < data.statistics.totalPages) {
          if (added == 0) {
            console.debug("LOADING NEXT PAGE " + parentNode.name);
            this.doSearch(parentNode, children, callback);
            return;
          }

          let id = parentNode.id + '_LOAD_MORE';
          const loadMoreNode = new ObjectNode(
            id,
            NodeType.NODE,
            'LOAD_MORE',
            parentNode.managedObject,
            null,
            false,
            parentNode.id
          );
          this.managedObjectNodeStore.set(id, loadMoreNode);
          children.push(loadMoreNode);
        }

        if (data.statistics.currentPage >= data.statistics.totalPages && this.childAssetsQuery) {
          this.childAssetsQuery = false;
          this.doSearch(parentNode, children, callback);
          return;
        }

        let completed = false;
        if (data.statistics.currentPage >= data.statistics.totalPages) {
          completed = true;
        }

        callback(children, completed);
      },
      (err: any) => {
        console.error(err);
        callback([], false);
      }
    );

  }

  private getChildDevices(parentNode: ObjectNode, activeSearch: ChildObjectsSearch): Observable<any> {

    return new Observable<any>((observer) => {
      if (activeSearch.childAssetsQuery) {
        this.cumuService.retrieveChildAssets(this.tenantId, parentNode.managedObject.id, activeSearch.params).subscribe((data: any) => {
          observer.next(data);
        }, (error) => {
          observer.error(error);
        });
      } else {
        this.cumuService.retrieveChildDevices(this.tenantId, parentNode.managedObject.id, activeSearch.params).subscribe((data: any) => {
          observer.next(data);
        }, (error) => {
          observer.error(error);
        });
      }

    });
  }

  protected composeChildObjectSearch(): ChildObjectsSearch {
      const activeSearch: ChildObjectsSearch = this.createAndUpdateSearch();
      let params = activeSearch.params;
      params.currentPage = params.currentPage + 1;

      let query: string = '';
      let queryParts = TenantBrowserConstants.EXCLUDE_DEVICE_TYPES.map(type => 'not (type eq ' + type + ')');

      if (queryParts.length > 0) {
        let excludeTypeQuery = queryParts.join(' and ');
        if (query) {
          query += ' and ' + excludeTypeQuery;
        } else {
          query = '$filter=' + excludeTypeQuery;
        }
      }

      params.query = query;

      return activeSearch;
  }

  private createAndUpdateSearch(): ChildObjectsSearch {
    let result = this.activeSearch;
    if (!result) {
      result = new ChildObjectsSearch();
      result.params = new ChildObjectsSearchParams();
      result.childAssetsQuery = true;
    } else {
      if (result.childAssetsQuery != this.childAssetsQuery) {
        result.childAssetsQuery = this.childAssetsQuery;
        result.params.currentPage = 0;
      }
    }

    this.activeSearch = result;

    return this.activeSearch;
  }

  private checkHasDeviceWithMeasurements(managedObject: ManagedObject): Promise<boolean> {

    return new Promise((resolve) => {
      if (managedObject.c8y_IsDevice) {
        resolve(true);
      } else {
        this.getObjectDevicesAndDeviceGroups(managedObject).pipe(switchMap((data) => {
          if (!data) {
            return of(false);
          }
          return this.checkSourceHasDeviceWithMeasurements([data]);
        })).subscribe((result: boolean) => {
          resolve(result);
        });
      }
    });

  }

  private checkSourceHasDeviceWithMeasurements(items: {
    source: ManagedObject,
    devices: ManagedObject[],
    deviceGroups: ManagedObject[],
  }[], level = 0): Observable<boolean> {
    let itemsWithDevices = items.filter(item => item.devices.length > 0);
    if (itemsWithDevices.length > 0) {
      return of(true);
    }

    let itemsWithDeviceGroups = items.filter(item => item.deviceGroups.length > 0);
    if (itemsWithDeviceGroups.length == 0) {
      return of(false);
    }

    if (level === 2) {
      console.log("reached level 2");
      return of(true);
    }

    let deviceGroups = [];
    for (let itemWithDeviceGroup of itemsWithDeviceGroups) {
      deviceGroups.push(...itemWithDeviceGroup.deviceGroups);
    }

    return new Observable<{ source: ManagedObject, devices: ManagedObject[], deviceGroups: ManagedObject[] }[]>((observer) => {

      forkJoin(deviceGroups.map(deviceGroup => this.getObjectDevicesAndDeviceGroups(deviceGroup))).subscribe((resultItems: any[]) => {
        observer.next(resultItems);
      });

    }).pipe(flatMap((data) => {
      level++;
      return this.checkSourceHasDeviceWithMeasurements(data, level);
    }));
  }

  private getObjectDevicesAndDeviceGroups(managedObject: ManagedObject): Observable<{ source: ManagedObject, devices: ManagedObject[], deviceGroups: ManagedObject[] }> {

    let params = {
      currentPage: 1,
      pageSize: 999,
      withTotalPages: false,
      withChildren: false
    };

    return forkJoin([
      this.cumuService.retrieveChildAssets(this.tenantId, managedObject.id, Object.assign({}, params, {
        query: `$filter=(has(c8y_IsDeviceGroup) or type eq 'c4t_root')`
      })),
      this.cumuService.retrieveChildAssets(this.tenantId, managedObject.id, Object.assign({}, params, {
        query: `$filter=(not(type eq 'c8y_DeviceGroup') and (not(type eq 'c4t_root')) and not(has(c8y_IsDeviceGroup)))`
      })).pipe(
        switchMap((res: any) => {
          console.debug(managedObject.id + " " + managedObject.name + " has number of " + res.references.length + " child assets as devices");
          let managedObjects = (res.references.length > 0 ? res.references.map(ref => ref.managedObject) : [managedObject]);

          return forkJoin(...managedObjects.map(o => this.checkDeviceHasMeasurements(o).pipe(map((val) => {
            return [o, val]
          }))))
        }),
        map((results: any[]) => {
          return results.filter(result => result[1])
            .map(result => result[0]);
        })

      ),
    ]).pipe(map(([deviceGroupReferences, devices]: [any, any]) => {
      return {
        source: managedObject,
        devices: devices,
        deviceGroups: deviceGroupReferences.references.map(reference => reference.managedObject)
      }
    }));
  }

  private checkDeviceHasMeasurements(managedObject: ManagedObject): Observable<boolean> {

    return new Observable<boolean>((observer) => {
      this.cumuService.loadSupportedSources(this.tenantId, managedObject.id).subscribe((res: SupportedSourcesResponse) => {
        observer.next(res.series.length > 0);
        observer.complete();
      });
    });
  }

}
