import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subject } from 'rxjs/internal/Subject';
import { UserService } from '../../../core/http/user.service';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Mandate } from '../../../core/model/profile';
import { NavbarService } from './navbar.service';
import { BsModalService } from 'ngx-bootstrap/modal';
import { TermsOfServiceModalComponent } from '../terms-of-service/terms-of-service-modal.component';
import { IdpAuthService } from '../../../core/auth/idp-auth.service';
import { TokenAuthService } from '../../../core/auth/token-auth.service';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

const selector = 'app-navbar';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.css']
})
export class NavbarComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public menuComponent$ = this.navbarService.menuTemplate$;
  public actionsComponent$ = this.navbarService.actionsTemplate$;
  public breadcrumbs$ = this.navbarService.breadcrumbs$;
  public feedbackEnabled: boolean;

  constructor(
    public authService: TokenAuthService,
    public idpAuthService: IdpAuthService,
    public userService: UserService,
    private router: Router,
    private toastr: ToastrService,
    private navbarService: NavbarService,
    private modalService: BsModalService
  ) {

  }

  ngOnInit() {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  selectPerson(person) {
    this.authService.switchToRole(person).then(() => {
      this.router.navigate(['/']);
    }, (err) => {
      this.toastr.error($localize`Error on role update`);
    });
  }

  showTermsAndConditions(): void {
    const modalRef = this.modalService.show(TermsOfServiceModalComponent, {
      class: 'modal-lg'
    });
  }

  public logout() {
    this.authService.startLogout();
  }

  openFeedbackModal() {
    this.modalService.show(FeedbackModalComponent, {
      ignoreBackdropClick: true
    });
  }
}
