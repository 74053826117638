import {Injectable, OnDestroy} from '@angular/core';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {SupportedEvent} from '../../../models/source';
import {CumuService} from '../../../http/cumu.service';
import {AbstractSourceDatabase} from './abstract-source-database';
import {SelectedSourcesService} from './selected-sources.service';
import {ObjectNode} from '../../../models/tenant-mapping/object-node';
import {ObjectFlatNode} from '../../../models/tenant-mapping/object-flat-node';
import {NodeType} from '../../../models/tenant-mapping/node-type';
import {ManagedObjectSearch} from '../../../models/tenant-mapping/managed-object-search';

@Injectable()
export class SelectedSourcesDatabase extends AbstractSourceDatabase implements OnDestroy{

  dataChange = new BehaviorSubject<ObjectNode[]>([]);

  constructor(protected cumuService: CumuService, private selectedSourcesService: SelectedSourcesService) {
    super(cumuService);
  }

  public onDataChange(data: any): void {
    this.dataChange.next(data);
  }

  initialize(tenantId: number) {
    this.tenantId = tenantId;
    this.getManagedObjectStore().clear();
    this.dataChange.next([]);
  }

  addItem(node: ObjectNode): void {
    const nestedNode = this.getManagedObjectStore().get(node.id);
    if (!nestedNode) {
      this.getManagedObjectStore().set(node.id, node);
      this.data.push(node);
      this.dataChange.next(this.data);
    }
  }

  removeItem(node: ObjectFlatNode): void {
    let nestedNode = this.getManagedObjectStore().get(node.id);
    if (nestedNode) {
      this.removeNestedNode(nestedNode);
      this.dataChange.next(this.data);
    }
  }

  private removeNestedNode(node: ObjectNode): void {
    this.getManagedObjectStore().delete(node.id);
    const idx = this.data.indexOf(node);
    if (idx != -1) {
      this.data.splice(this.data.indexOf(node), 1);
    }

    for (let child of node.children) {
      this.removeNestedNode(child);
    }
  }

  buildEventNode(id: string, parentNode: ObjectNode, event?: SupportedEvent) {

    let result = new ObjectNode(
      id,
      NodeType.TENANT_EVENT,
      'Event',
      parentNode.managedObject,
      event ? event.eventType : null,
      false,
      null,
      event && event.id != null
    );

    result.control = this.selectedSourcesService.createSelectedSourceFormGroup(result);

    return result;
  }

  ngOnDestroy(): void {
    this.data = [];
    this.getManagedObjectStore().clear();
  }

  checkChildren(parent: ObjectNode): void {
  }

  loadChildren(params, cb): void {
    cb();
  }

  composeManagementObjectSearch(): ManagedObjectSearch {
    return null;
  }

  onSeriesNodeAdd(seriesNode: ObjectNode): void {
  }

}
