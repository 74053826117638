import {IdpCode} from '../auth/idp-code';

export class IdpCodeUtil {

  public static fromString(str: string): IdpCode {
    let idpCode: IdpCode = null;
    Object.values(IdpCode).forEach((value, idx) => {
      if (value === str) {
        idpCode = IdpCode[Object.keys(IdpCode)[idx]];
      }
    });

    return idpCode;
  }

}
