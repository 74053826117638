import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {SupportedSourcesResponse} from '../models/source';
import {Observable} from 'rxjs/internal/Observable';
import {ManagedObjectsResponse} from '../models/tenant-mapping/managed-objects-response';
import {ObjectSearchParams} from '../models/tenant-mapping/object-search-params';

@Injectable()
export class CumuService {

  constructor(private http: HttpClient) {
  }

  public searchManagedObjects(tenantId: number, params: ObjectSearchParams): Observable<ManagedObjectsResponse> {
    return this.http.get<ManagedObjectsResponse>(environment.apiUrl + '/cumu-api/' + tenantId + '/managed-objects', {params: params as any});
  }

  public retrieveChildDevices(tenantId: number, managedObjectId: string, params: any) {
    return this.http.get(environment.apiUrl + '/cumu-api/' + tenantId + '/managed-objects/' + managedObjectId + '/child-devices', {params: params});
  }

  public retrieveChildAssets(tenantId: number, managedObjectId: string, params: any) {
    return this.http.get(environment.apiUrl + '/cumu-api/' + tenantId + '/managed-objects/' + managedObjectId + '/child-assets', {params: params});
  }

  public loadSupportedSources(tenantId: number, managedObjectId: string): Observable<SupportedSourcesResponse> {
    return this.http.get<SupportedSourcesResponse>(environment.apiUrl + '/cumu-api/' + tenantId + '/managed-objects/' + managedObjectId + '/supported-sources');
  }

}
