import {Component, OnInit} from '@angular/core';
import {IdpAuthService} from '../../../core/auth/idp-auth.service';
import {TokenAuthService} from '../../../core/auth/token-auth.service';

@Component({
  selector: 'app-source',
  templateUrl: './setup-wizard.component.html'
})
export class SetupWizardComponent implements OnInit {

  public stepSequence: string[] = ['source-type', 'configure', 'finalize'];

  public activeStepIndex = 0;

  public sourceType: 'tenant' | 'dal-api';

  public sourceSelection: any = null;

  constructor(public authService: TokenAuthService) { }

  ngOnInit() {
    this.authService.currentMandate$.subscribe(value => {
      this.setActiveStep('source-type');
    });
  }

  setSourceTypeAndContinue(sourceType: 'tenant' | 'dal-api') {
    this.sourceType = sourceType;
    this.setActiveStep('configure');
  }

  confirmSourceConfigAndContinue( event: any ) {
    this.sourceSelection = event;
    this.setActiveStep('finalize');
  }

  setActiveStep(step: number | string) {
    if (typeof step === 'number') {
      this.activeStepIndex = step;
    }
    if (typeof step === 'string') {
      const index = this.stepSequence.indexOf(step);
      if (index >= 0) {
        this.activeStepIndex = index;
      }
    }
  }

  isCompletedStep(step: number | string) {
    if (typeof step === 'number') {
      return this.activeStepIndex > step;
    }
    if (typeof step === 'string') {
      return this.activeStepIndex > this.stepSequence.indexOf(step);
    }
  }

  isActiveStep(step: number | string) {
    if (typeof step === 'number') {
      return this.activeStepIndex === step;
    }
    if (typeof step === 'string') {
      return this.activeStepIndex === this.stepSequence.indexOf(step);
    }
  }
}


