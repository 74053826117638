import {AfterViewInit, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, FormControl, UntypedFormControl, ValidationErrors, Validators} from '@angular/forms';
import {PersonCodeControlValidator} from '../../../../core/validators/form/control/person-code-control-validator.service';
import {catchError, debounceTime, filter, switchMap, takeUntil} from 'rxjs/operators';
import {AssignType, Person, TokenPerson} from '../../../../core/model/person';
import {UserService} from '../../../../core/http/user.service';
import {Subject} from 'rxjs/internal/Subject';
import {of} from 'rxjs/internal/observable/of';
import {NgSelectComponent} from '@ng-select/ng-select';
import {environment} from '../../../../../environments/environment';


const selector = 'token-person-select';
let nextId = 0;
@Component({
  selector: 'token-person-select',
  templateUrl: './token-person-select.component.html'
})
export class TokenPersonSelectComponent implements OnInit, OnDestroy, AfterViewInit {
  id = `${selector}-${nextId++}`;

  private ngDestroy = new Subject<void>();

  public tokenPersonControl: FormControl = new FormControl<any>(null, [this.tokenPersonDetailsValidator.bind(this)]);

  public personTypeahead$ = new Subject<string>();

  public persons: Person[] = [];

  @ViewChild(NgSelectComponent, {static: false}) ngSelect: NgSelectComponent;

  @Output()
  onTokenPersonSelect: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private cd: ChangeDetectorRef,
    private personCodeControlValidator: PersonCodeControlValidator,
    private userService: UserService,
  ) {
    this.initUserTypeahead();
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit(): void {
  }

  public clear(): void {
    this.tokenPersonControl.setValue(null);
  }

  public addNewValue = (value) => {
    return value;
  }

  public addTokenPerson(): void {
    if (this.tokenPersonControl.invalid) {
      console.error("Invalid", this.tokenPersonControl);
      return;
    }

    const formValue = this.tokenPersonControl.getRawValue();
    if (!formValue) {
      return;
    }

    this.onTokenPersonSelect.next(this.createTokenPerson(formValue));
    this.clear();
  }

  public isString(value): boolean {
    return typeof value === 'string';
  }

  private initUserTypeahead() {
    this.personTypeahead$
      .pipe(
        filter((term) => term && term.length >= 4),
        debounceTime(200),
        switchMap(term => this.userService.searchUsers(term).pipe(
          catchError(() => of([])),
        )),
        takeUntil(this.ngDestroy)
      ).subscribe((result: any[]) => {
        this.persons = result.map((person) => {
          let p = new Person();
          p.registrationNumber = person.personalId;
          p.displayName = person.displayName;
          p.countryCode = person.countryCode;
          p.dob = person.dob;
          p.idPrivacyType = person.idPrivacyType;

          return p;
        });
        this.cd.markForCheck();
      }, (err) => {
        this.persons = [];
        this.cd.markForCheck();
      });
  }

  private tokenPersonDetailsValidator(control: AbstractControl): ValidationErrors|null {
    const value = control.value;
    if (!value) {
      return;
    }

    let valid = true;
    if (typeof value === 'string') {
      let isNumber = /^\d+$/.test(value);
      if (isNumber) {
        if (this.personCodeControlValidator.check(new UntypedFormControl(value))) {
          valid = false;
        }
      } else {
        if (Validators.email(new UntypedFormControl(value))) {
          valid = false;
        }
      }
    }

    if (valid) {
      return;
    }

    return {
      invalidTokenPersonDetails: true
    };
  }

  private createTokenPerson(formValue: Person|string|number): TokenPerson {

    let tokenPerson: TokenPerson = new TokenPerson();
    if (this.isString(formValue)) {
      formValue = <string>formValue;
      //TODO: validate, kas on email või reg kood?
      let isNumber = /^\d+$/.test(formValue);
      if (isNumber) {
        tokenPerson.registrationNumber = formValue;
        tokenPerson.countryCode = environment.defaultCountryCode;
        tokenPerson.assignType = AssignType.REG_NO;
      } else {
        tokenPerson.email = formValue;
        tokenPerson.assignType = AssignType.EMAIL;
      }
    } else {
      formValue = <Person>formValue;
      tokenPerson.assignType = AssignType.PERSON;
      tokenPerson.person = formValue;
      tokenPerson.countryCode = formValue.countryCode;
      tokenPerson.registrationNumber = formValue.registrationNumber;
    }

    return tokenPerson;
  }

  ngAfterViewInit(): void {
    // Setting typeahead on NgSelectComponent separately and not in view, because ngx-bootstrap also has [typeahead] attribute that causes error
    this.ngSelect.typeahead = this.personTypeahead$;
    this.cd.detectChanges();
  }

}
