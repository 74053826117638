export class TenantBrowserConstants {

  public static IS_DEVICE_QUERY_PART = `has(c8y_IsDevice) and not(type eq 'c8y_DeviceGroup')`;

  public static IS_DEVICE_GROUP_QUERY_PART = `type eq 'c8y_DeviceGroup' and not(has(c8y_IsDevice))`;

  public static EXCLUDE_DEVICE_TYPES = ['DAL_series_aggregation', /*'DAL_series_target'*/];

  public static DAL_RESERVED_SERIES_PATHS = ["dal.series", "measure.item"];

}
