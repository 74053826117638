import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'value-checkbox',
  templateUrl: './checkbox.component.html'
})
export class CheckboxComponent implements OnInit {

  public checked = false;

  @Input()
  public value: any;

  @Input()
  public label = '';

  @Output()
  public valueChange: EventEmitter<any> = new EventEmitter<any>();

  constructor() { }

  ngOnInit(): void {
  }

  public modelChange(value): void {
    this.checked = !this.checked;
    if (value) {
      this.valueChange.emit(this.value);
    } else {
      this.valueChange.emit(null);
    }

  }

}
