import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {NavbarService} from '../navbar/navbar.service';

@Component({
  selector: 'app-source-mapping-page',
  templateUrl: './source-mapping-page.component.html',
  styleUrls: ['./source-mapping-page.component.css']
})
export class SourceMappingPageComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navMenu', {static: true})
  public navTempalte: TemplateRef<any>;

  constructor(private navbarService: NavbarService) { }

  ngOnDestroy(): void {
    this.navbarService.setMenuTemplate(null);
  }

  ngOnInit(): void {
    this.navbarService.setMenuTemplate(this.navTempalte);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
