import {Injectable, OnDestroy} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {ObjectFlatNode} from '../../../models/tenant-mapping/object-flat-node';
import {ObjectNode} from '../../../models/tenant-mapping/object-node';

@Injectable()
export class SelectedSourcesService implements OnDestroy {

  private seriesSelectSubject = new ReplaySubject<ObjectFlatNode>(1);

  public seriesSelect$ = this.seriesSelectSubject.asObservable();

  private seriesDeselectSubject = new ReplaySubject<ObjectFlatNode>(1);

  public seriesDeselect$ = this.seriesDeselectSubject.asObservable();

  private sourceDeselectSubject = new ReplaySubject<ObjectFlatNode>(1);

  public sourceDeselect$ = this.sourceDeselectSubject.asObservable();

  public mappingForm: UntypedFormGroup;

  public selectedSourcesFormArray: UntypedFormArray;

  public selectedSeriesCache: Map<string, ObjectFlatNode> = new Map<string, ObjectFlatNode>();

  constructor() {
    this.selectedSourcesFormArray = new UntypedFormArray([]);
    this.mappingForm = new UntypedFormGroup({
      selectedSources: this.selectedSourcesFormArray
    });
  }

  public deselectSource(node: ObjectFlatNode): void {
    this.sourceDeselectSubject.next(node);
  }

  public selectSeries(node: ObjectFlatNode): void {
    this.selectedSeriesCache.set(node.id, node);
    this.seriesSelectSubject.next(node);
  }

  public deselectSeries(node: ObjectFlatNode): void {
    this.selectedSeriesCache.delete(node.id);
    this.seriesDeselectSubject.next(node);
  }

  public createSelectedSourceFormGroup(node: ObjectNode): UntypedFormGroup {

    let formGroup = new UntypedFormGroup({
      checked: new UntypedFormControl({value: !!node.sourcePath, disabled: node.disabled}),
      sourcePath: new UntypedFormControl({
        value: node.sourcePath,
        disabled: node.disabled
      }, [(control: AbstractControl) => {
        const value = control.value;
        if (control.parent && control.parent.get('checked').value && !value) {
          return {
            required: {
              value: value
            }
          }
        }

        return null;
      }])
    });

    this.selectedSourcesFormArray.push(formGroup);

    return formGroup;
  }

  ngOnDestroy(): void {
    this.mappingForm.reset();
    this.selectedSourcesFormArray.clear();
  }

}
