import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {CumuService} from '../../../http/cumu.service';
import {SupportedEvent} from '../../../models/source';
import {AbstractSourceDatabase} from './abstract-source-database';
import {ObjectNode} from '../../../models/tenant-mapping/object-node';
import {NodeType} from '../../../models/tenant-mapping/node-type';
import {LoadChildrenExecutor} from './executors/load-children-executor';
import {SearchParams} from '../../../models/tenant-mapping/search-params';

@Injectable()
export class LoadmoreDatabase extends AbstractSourceDatabase {

  public dataChange = new BehaviorSubject<ObjectNode[]>([]);

  private loadChildrenExecutors: Map<ObjectNode, LoadChildrenExecutor> = new Map<ObjectNode, LoadChildrenExecutor>();

  constructor(protected cumuService: CumuService) {
    super(cumuService);
  }

  onDataChange(data) {
    this.dataChange.next(data);
  }

  initialize(tenantId: number, searchParams: SearchParams, cb) {
    this.tenantId = tenantId;
    this.initLoadManagedObjects(searchParams);
    this.loadManagedObjects(cb);
  }

  buildEventNode(id: string, parentNode: ObjectNode, event?: SupportedEvent) {
    return new ObjectNode(
      id,
      NodeType.TENANT_EVENT,
      'Event',
      parentNode.managedObject,
      event ? event.eventType : null,
      false,
      null,
      event && event.id != null
    );
  }

  public loadChildren(params: { parent: ObjectNode, onlyWithMeasurements: boolean }, cb) {
    let parentNode = params.parent;
    let executor = this.loadChildrenExecutors.get(parentNode);
    if (!executor || executor.isOnlyWithMeasurements() !== params.onlyWithMeasurements) {
      executor = new LoadChildrenExecutor(parentNode, this.tenantId, this.managedObjectNodeStore, this.cumuService, params.onlyWithMeasurements);
      this.loadChildrenExecutors.set(parentNode, executor);
    }

    executor.execute((children, finished) => {
      params.parent.childrenChange.next(children);
      if (finished) {
        this.loadChildrenExecutors.delete(parentNode);
      }
      this.onDataChange(this.data);
      cb();
    });
  }

  public checkChildren(parent) {
    const children = parent.children;
    if (!children || children.length === 0) {
      console.log('Has no children');
      parent.hasChildren = false;
      this.clearChildObjectsSearch(parent);
    }

    this.onDataChange(this.data);
  }

  private clearChildObjectsSearch(parentNode: ObjectNode) {
    this.loadChildrenExecutors.delete(parentNode);
  }

}
