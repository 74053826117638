<div class="container-fluid p-4">
  <div class="row">
    <div [formGroup]="tenantForm" class="col-lg-4">
      <h3 i18n>Tenant information</h3>
      <div class="form-group">
        <div class="form-row">
          <div class="col">
            <label [attr.for]="id + '-username'" i18n>Tenant admin username</label>
            <input type="text" class="form-control" [id]="id + '-username'" formControlName="username" placeholder="Username" i18n-placeholder>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-row">
          <div class="col">
            <label [attr.for]="id + '-url'" i18n>Url</label>
            <input type="text" class="form-control" [id]="id + '-url'" formControlName="url" placeholder="Url" i18n-placeholder>
          </div>
        </div>
      </div>
      <div class="form-group" *ngIf="ui.isAdd || ui.isEdit">
        <div class="form-row">
          <div class="col">
            <label [attr.for]="id + '-password'" i18n>Password</label>
            <input type="password" class="form-control" [id]="id + '-password'" formControlName="password" placeholder="Password" i18n-placeholder>
          </div>
        </div>
      </div>
      <button *ngIf="tenant?.id && !ui.isEdit" (click)="startEdit()" type="button" class="btn btn-primary btn-sm float-right" i18n>Edit</button>
      <button *ngIf="ui.isAdd || ui.isEdit" (click)="saveTenant()" [ladda]="saveTenantProgress" type="button" class="btn btn-primary btn-sm float-right" [disabled]="!tenantForm.valid" i18n>Save</button>
    </div>
    <div *ngIf="!loading && tenant && tenant.id" class="col-lg-8 text-center">
      <h3 class="text-center" i18n>Import from Cumulocity</h3>
      <app-tenant-browser [tenant]="tenant"></app-tenant-browser>
    </div>
  </div>
</div>
