import {Component, Input, OnChanges, OnInit, TemplateRef} from '@angular/core';
import {AbstractControl, AbstractControlDirective} from '@angular/forms';

@Component({
  selector: 'form-field-errors',
  templateUrl: './field-error-messages.component.html'
})
export class FieldErrorMessages implements OnInit, OnChanges {

  errors: string[] = [];

  public errorMessageMap = {
    'default': (params) => $localize`This field has errors`,
    'required': (params) => $localize`Required`,
    'invalidTokenPersonDetails': (params) => $localize`Personal identification or registry code or e-mail not valid`,
    'minlength': (params) => $localize`Must be more than ${params.requiredLength} characters`,
    'maxlength': (params) => $localize`Must be less than ${params.requiredLength} characters`,
    'personRegistrationNumber': (params) => $localize`Person code invalid`,
  };

  @Input()
  control: AbstractControl | AbstractControlDirective;

  @Input()
  errorsTemplate: TemplateRef<unknown>;

  constructor() {
  }

  ngOnInit(): void {
  }

  ngOnChanges(): void {

  }

  listErrors(): string[] {
    if (!this.control) return [];
    let result: string[] = [];
    if (this.control.errors && (this.control.touched || this.control.dirty)) {
      Object.keys(this.control.errors).map((error) => {
        let errorFn = (this.errorMessageMap[error] ?? this.errorMessageMap['default']);
        result.push(errorFn(this.control.errors[error]));
      });
    }

    return result;
  }


}
