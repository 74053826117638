import {
  AfterViewChecked,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {GroupingTags, LocationTypes, SeriesType} from '../../../models/series';
import {SourceGridItemDto} from '../../../models/source';
import {SourceFormService} from '../source-form.service';
import {environment} from '../../../../../environments/environment';
import {debounceTime, distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {SourceItemRow} from './source-item-row.model';
import {AbstractControl, FormControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {SourceService} from '../../../http/source.service';
import {UserService} from '../../../../core/http/user.service';
import {Observable} from 'rxjs/internal/Observable';
import {SourceGridService} from '../source-grid.service';
import {ToastrService} from 'ngx-toastr';
import {simpleFadeAnimation} from '../../../../core/animations/simple-fade-animation';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';

@Component({
  selector: 'app-source-item-row',
  templateUrl: './source-item-row.component.html',
  styleUrls: ['../source-grid.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [simpleFadeAnimation]
})
export class SourceItemRowComponent implements OnInit, OnDestroy, AfterViewChecked {
  private ngDestroy = new Subject<void>();

  public hide = true;

  public checked = false;

  public sourceItemRowSubject = new ReplaySubject<SourceItemRow>(1);
  public sos$ = this.sourceItemRowSubject.asObservable().pipe(debounceTime(250));

  @Input()
  set row(value) {
    this.sourceItemRowSubject.next(value);
  }

  @Input()
  set mappedStatus(value) {
    this._mappedStatus = value;
    this.cdRef.markForCheck();
  }

  @ViewChild('noRootTemplate', {static: true})
  template: TemplateRef<any>;

  // FIXME new-design
  // @ViewChild('groupingTagAuto', { static: true }) groupingTagsCompleter: MdbAutoCompleterComponent;

  public locationTypes = LocationTypes.types;

  public _edit: boolean;
  public _row: SourceItemRow;
  public _mappedStatus: boolean;
  public _source: SourceGridItemDto;

  public _group: UntypedFormGroup;
  private personRegistrationNumberControl: AbstractControl;

  public seriesTypes = SeriesType.types;
  public addressControlType = 'input';

  public addedToEdit = false;

  personTypeahead$ = new Observable((observer: any) => {
    observer.next(this.personRegistrationNumberControl?.value);
  }).pipe(
    distinctUntilChanged(),
    switchMap((term: string) => this.userService.searchPersons(term)),
  );

  public groupingTags = GroupingTags.tags;

  set edit(value) {
    this._edit = value;
    this.cdRef.markForCheck();
  }

  constructor(
    private cdRef: ChangeDetectorRef,
    private vcRef: ViewContainerRef,
    private sourceFormService: SourceFormService,
    private sourceService: SourceService,
    private userService: UserService,
    private sourceGrid: SourceGridService,
    private toastr: ToastrService
  ) {
    this.addressControlType = environment.addressControl;
  }

  ngOnInit() {
    this.vcRef.createEmbeddedView(this.template);

    // this.sourceGrid.selectedRows$.pipe(takeUntil(this.ngDestroy)).subscribe((selectedRowSet) => {
    //   const isSelected = this.sourceGrid.isSelected(this._row);
    //   if (isSelected !== this.selectControl.value) {
    //     this.selectControl.setValue(isSelected, {emitEvent: false});
    //   }
    // });
    this.sourceItemRowSubject.pipe(takeUntil(this.ngDestroy)).subscribe((value) => {
      // if (value) {
        this._row = value;
        this._source = this._row?.item;
        this._group = this._row?.control;
        this.personRegistrationNumberControl = this._group?.get('personRegistrationNumber');
        // const isSelected = this.sourceFormService.isSelected(this._row);
        // if (isSelected !== this.selectControl.value) {
        //   this.selectControl.setValue(isSelected, {emitEvent: false});
        // }
      // }

      this.cdRef.markForCheck();

    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  control(name?: string) {
    if (!name) {
      return this._group;
    }
    return this._group.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this._group;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this._group.get(name);
    return control ? control.errors : null;
  }

  typeaheadOnSelect(event) {
    this._group?.get('personRegistrationNumber').setValue(event.item.registrationNumber);
    this._group?.get('personCountryCode').setValue(event.item.countryCode);
  }

  toggleSelect(event): void {
    if (!this.sourceGrid.canAddMore(1)) {
      this.sourceGrid.bulkEditLimitExceeded.next();
      event.preventDefault();
      return;
    }
    this.selectRow();
  }

  saveSource() {
    this._group.markAllAsTouched();
    this._group.updateValueAndValidity();

    if(this._group.invalid) {
      console.error(this._group);
      return;
    }
    const data = this._group.getRawValue();
    this.sourceService.saveSource(data).subscribe(() => {
      this.sourceFormService.updateRowSourceItem(data);
      this._group.markAsUntouched();
      this.edit = false;
    });
  }

  save() {
    this.saveSource();
  }

  cancel(): void {
    this.edit = false;
    this._group.reset(this._row.item);
  }

  editRow(): void {
    this.edit = true;
    this.deselectRow();
  }

  private selectRow(): void {
    this.hide = true;
    setTimeout(() => {
      this.sourceGrid.selectRow(this._row);
      this.toastr.info($localize`Added source for editing`);
    }, 200);
  }

  private deselectRow(): void {
    this.checked = false;
  }

  ngAfterViewChecked() {
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.hide = false;
    }, 0);
  }

}
