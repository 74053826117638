import {AbstractControl, UntypedFormControl, UntypedFormGroup} from '@angular/forms';

export function RequireAddress(control: AbstractControl) {
  const input = <UntypedFormControl>control;
  const parent = <UntypedFormGroup>input.parent;

  if (!parent) {
    return null;
  }

  if (parent.get('locationType').value !== 'ADDRESS') {
    return null;
  }

  if (!input.value) {
    return {
      required: true
    };
  }

  return null;
}
