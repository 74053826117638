import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-mapping-layout',
  templateUrl: './mapping-layout.component.html'
})
export class MappingLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
