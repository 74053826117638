import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {UserService} from '../../../../core/http/user.service';
import {Mandate} from '../../../../core/model/profile';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
import {NavbarService} from '../../navbar/navbar.service';

@Component({
  selector: 'app-mandate-grid',
  templateUrl: './mandate-grid.component.html'
})
export class MandateGridComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public actionsTemplate: TemplateRef<any>;

  public mandates: Mandate[];

  constructor (
    private navbarService: NavbarService,
    private userService: UserService,
    public toastr: ToastrService
  ) { }

  ngOnInit () {
    this.navbarService.setActionsTemplate(this.actionsTemplate);
    this.loadMandates();
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadMandates () {
    this.userService.getMandates().pipe(takeUntil(this.ngDestroy)).subscribe(
      (data: any) => {
        this.mandates = data.content;
      },
      (err: any) => {
        console.error(err);
        this.toastr.error($localize`Error on loading page!`);
      }
    );
  }

  deleteMandate (mandate) {
    if (confirm('Sure?')) {
      this.userService.deleteMandate(mandate).pipe(takeUntil(this.ngDestroy)).subscribe(
        (data: any) => {
          this.loadMandates();
          this.toastr.success($localize`Deleted!`);
        }
      );
    }
  }
}
