<div id="landing__wrapper">
  <div class="container">
    <div class="row">
      <div class="col-xl-6 col-lg-12">
        <div class="row">
          <div class="col-xl-6 col-lg-4 col-md-5 text-center text-md-left">
            <div class="logo-wrapper d-inline-block">
              <img [src]="dalLogo" class="dal-logo" alt="Data mapping" />
            </div>
          </div>
          <div class="col-xl-6 col-lg-8 col-md-7 d-flex align-items-center justify-content-center justify-content-md-start">
            <h4 class="my-0" i18n>Data mapping portal</h4>
          </div>
        </div>
        <div *ngIf="noPersonalCodeError" role="main" class="container" i18n>User personal code can't be found. Use real person based authentication.</div>
        <div id="iframeContainer" class="text-center pt-4"></div>
        <div class="row mt-5">
          <div class="col-md-12 d-flex justify-content-center justify-content-md-start">
            <div class="text-center mb-3">
              <button  class="btn btn-login mr-3" (click)="loginRedirect()" i18n>Enter portal</button>
            </div>
            <div class="text-center">
              <button (click)="loginAsDemoUser()" class="btn btn-primary btn-demo-login">DEMO USER</button>
            </div>
          </div>
        </div>
      </div>
      <div class="col-xl-6 col-lg-12 mt-3 mt-lg-0 d-flex justify-content-center">
        <img [src]="iconSatelliteDish" class="img-fluid" alt="Telia satellite dish" />
        <img [src]="iconRocket" class="img-fluid" alt="Telia rocket" />
        <img [src]="iconMast" class="img-fluid" alt="Telia mast" />
      </div>
    </div>
  </div>
</div>
