import {BrowserModule} from '@angular/platform-browser';
import {APP_INITIALIZER, ErrorHandler, InjectionToken, LOCALE_ID, NgModule, TRANSLATIONS} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS, HttpClientJsonpModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgProgressModule} from 'ngx-progressbar';
import {NgxPermissionsModule} from 'ngx-permissions';
import {AppComponent} from './app.component';
import {ConsentService} from './core/services/consent.service';
import {UserService} from './core/http/user.service';
import {TenantService} from './core/http/tenant.service';
import {RequestInterceptor} from './core/interceptors/request.interceptor';
import {AppRoutingModule} from './app-routing.module';
import {CoreModule} from './core/core.module';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatNativeDateModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatTreeModule } from '@angular/material/tree';
import {PersonService} from './core/services/person.service';
import {ModalModule} from 'ngx-bootstrap/modal';
import {PaginationModule} from 'ngx-bootstrap/pagination';
import {TypeaheadModule} from 'ngx-bootstrap/typeahead';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {LaddaModule} from 'angular2-ladda';
import {ErrorHandlerService} from './core/services/error-handler.service';
import {LoggerService} from './core/services/logger.service';
import {ToastrModule} from 'ngx-toastr';
import {LocalizeExtractsComponent} from './localize-extracts/localize-extracts.component';
import {cacheServiceProvider} from './core/cache/cache-service.provider';
import {idpAuthServiceProvider} from './core/auth/idp-auth-service-provider';
import {IdpService} from './core/auth/idp.service';
import {appInitializerFactory} from './app-initializer';
import {IdpAuthService} from './core/auth/idp-auth.service';
import {CacheService} from './core/cache/cache.service';
import {C} from '@angular/cdk/keycodes';
import { PrivateModule } from './private/private.module';

@NgModule({
  declarations: [
    AppComponent,
    LocalizeExtractsComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    HttpClientJsonpModule,
    NgbModule,
    NgProgressModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgxPermissionsModule.forRoot(),
    AppRoutingModule,
    ToastrModule.forRoot(),
    CoreModule,
    PrivateModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatNativeDateModule,
    BrowserModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatTreeModule,
    TypeaheadModule.forRoot(),
    ModalModule.forRoot(),
    LaddaModule.forRoot({
      style: 'expand-left',
      spinnerSize: 20,
      spinnerColor: 'white'
    }),
    PaginationModule.forRoot(),
    BsDropdownModule.forRoot(),
    PopoverModule.forRoot()
  ],
  providers: [
    ConsentService,
    UserService,
    PersonService,
    TenantService,
    {
      provide: ErrorHandler,
      useClass: ErrorHandlerService,
      deps: [LoggerService, CacheService]
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializerFactory,
      deps: [IdpService],
      multi: true
    },
    idpAuthServiceProvider,
    cacheServiceProvider,
    IdpService
    // { provide: LOCALE_ID, useValue: 'et-EE' }
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
