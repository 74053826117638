<div class="form-row">
  <div class="col d-block">
    <ng-select
      #ngSelect
      [id]="id + '-assigned-person'"
      [class.is-invalid]="tokenPersonControl.invalid"
      [clearable]="true"
      [searchable]="true"
      appendTo="body"
      placeholder="Personal identification or registry code or e-mail"
      i18n-placeholder
      i18n-addTagText
      [items]="persons"
      [selectOnTab]="true"
      [addTag]="addNewValue"
      (clear)="clear()"
      [formControl]="tokenPersonControl"
      class="custom"
      addTagText="Add persons">
      <ng-template ng-label-tmp let-item="item">
        <span *ngIf="!isString(item)">{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item|personalId:{codeFieldName: 'registrationNumber'}) + ')'}}</span>
        <span *ngIf="isString(item)">{{item}}</span>
      </ng-template>
      <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
        <span *ngIf="!isString(item)">{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item|personalId:{codeFieldName: 'registrationNumber'}) + ')'}}</span>
        <span *ngIf="isString(item)">{{item}}</span>
      </ng-template>
    </ng-select>

    <form-field-errors [control]="tokenPersonControl"></form-field-errors>
  </div>
  <div class="col d-block">
    <button type="button" class="btn btn-sm btn-primary" (click)="addTokenPerson()">Add person</button>
  </div>
</div>

