import {IdpService} from './core/auth/idp.service';

export function appInitializerFactory(idpService: IdpService): () => void {
  return () => {
    const url = new URL(window.location.href);
    const loginMode = url.searchParams.get('loginMode');
    if (loginMode) {
      idpService.setActiveIdpString(loginMode, true);
    }
  };
}
