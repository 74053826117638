import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import { Router } from '@angular/router';
import {environment} from '../../../../environments/environment';
import {locale} from 'moment';

@Component({
  selector: 'header-language-select',
  templateUrl: './header-languages-select.component.html'
})
export class HeaderLanguagesSelect implements OnInit {
  i18nBaseUrls: {en: string; et: string; da: string} = environment.i18nBaseUrls;

  constructor(
    public router: Router,
    @Inject(LOCALE_ID) public locale: string
  ) {}

  ngOnInit(): void {
    console.log("locale", this.locale);
  }


}
