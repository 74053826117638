import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import {IdpService} from '../auth/idp.service';
import {TokenAuthService} from '../auth/token-auth.service';
import {IdpAuthService} from '../auth/idp-auth.service';

@Injectable({
  providedIn: 'root',
})
export class AuthGuard implements CanActivate {

  constructor(private authService: TokenAuthService,
              private idpService: IdpService,
              // Lisatud hetkel siia, et IdpAuthService peaks olema saadaval enne guardi ja token auth servicet, mis saadab logout eventi välja
              // ... aga kui ei ole IdpAuthService-t constructitud, siis ei ole midagi, mis logout eventi kuulaks
              private idpAuthService: IdpAuthService,
              private router: Router) {}

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {
    return new Promise((resolve, reject) => {
      this.getAuthentication(state.url, this.router.url, next.queryParams['token']).then((isAuthenticated: boolean) => {
        if (!isAuthenticated) {
          resolve(false);
          this.router.navigateByUrl('/login/login' + (state.url && state.url != '/' ? `?redirectUri=${state.url}` : ''));
        }
        resolve(true);
      }).catch((reason: any) => {
        reject(reason);
      });
    });
  }

  getAuthentication(targetUrlPath: string, urlPath: string, token?: string): Promise<boolean> {
    return this.authService.getAuthenticationStatus({targetUrlPath: targetUrlPath, currentUrlPath: urlPath, token: token});
  }
}
