import {ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {Observable, of, Subject} from 'rxjs/index';
import {GroupingTags, LocationTypes, SeriesType} from '../../models/series';
import {SourceFormService} from './source-form.service';
import {environment} from '../../../../environments/environment';
import {distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {UserService} from '../../../core/http/user.service';
import {SourceGridService} from './source-grid.service';

@Component({
  selector: '[app-sources-bulk-edit]',
  styleUrls: ['source-grid.component.css'],
  templateUrl: './sources-bulk-edit.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourcesBulkEditComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @Input()
  public mappedStatus = false;

  public locationTypes = LocationTypes.types;

  public form: UntypedFormGroup;

  public groupingTags = GroupingTags.tags;

  public seriesTypes = SeriesType.types;
  public addressControlType = 'input';

  personTypeahead$ = new Observable((observer: any) => {
    observer.next(this.form.get('personRegistrationNumber').value);
  }).pipe(
    distinctUntilChanged(),
    switchMap((term: string) => this.userService.searchPersons(term)),
  );

  constructor(
    private cdRef: ChangeDetectorRef,
    public sourceFormService: SourceFormService,
    private userService: UserService,
    private sourceGrid: SourceGridService
  ) {
    this.addressControlType = environment.addressControl;
    this.form = sourceFormService.bulkEditForm;
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngOnInit() {

  }

  control(name?: string) {
    if (!name) {
      return this.form;
    }
    return this.form.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.form;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.form.get(name);
    return control ? control.errors : null;
  }

  typeaheadOnSelect(event) {
    this.form.get('personRegistrationNumber').setValue(event.item.registrationNumber);
    this.form.get('personCountryCode').setValue(event.item.countryCode);
  }

  close() {
    this.sourceGrid.bulkSource.next(false);
  }

}
