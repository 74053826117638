
  <div class="header__languages-list">
    <ul class="list__inner">
      <li class="list__item header__languages-item"  [ngClass]="{'active': locale === 'en-GB'}">
        <div class="list__item-inner">
          <div class="list__item-content">
            <a [href]="i18nBaseUrls?.en + router?.url" class="header__languages-link">EN</a>
          </div>
        </div>
      </li>
      <li class="list__item header__languages-item"  [ngClass]="{'active': locale === 'et'}">
        <div class="list__item-inner">
          <div class="list__item-content">
            <a [href]="i18nBaseUrls?.et + router?.url" class="header__languages-link">ET</a>
          </div>
        </div>
      </li>
      <li class="list__item header__languages-item" [ngClass]="{'active': locale === 'da'}">
        <div class="list__item-inner">
          <div class="list__item-content">
            <a [href]="i18nBaseUrls?.da + router?.url" class="header__languages-link">DA</a>
          </div>
        </div>
      </li>
    </ul>
  </div>

