import {ManagedObject} from '../../../../models/tenant-mapping/managed-object';
import {ObjectNode} from '../../../../models/tenant-mapping/object-node';
import {NodeType} from '../../../../models/tenant-mapping/node-type';

export class ManagedObjectNodeStore {

  private nodeMap: Map<string, ObjectNode>

  constructor() {
    this.nodeMap = new Map<string, ObjectNode>();
  }

  has(key: string): boolean {
    return this.nodeMap.has(key);
  }

  set(key: string, node: ObjectNode): void {
    this.nodeMap.set(key, node);
  }

  get(key: string): ObjectNode {
    return this.nodeMap.get(key);
  }

  delete(key: string): void {
    this.nodeMap.delete(key);
  }

  clear(): void {
    this.nodeMap.clear();
  }

  public createFromManagedObject(managedObject: ManagedObject): ObjectNode {
    if (this.nodeMap.has(managedObject.id)) {
      return this.nodeMap.get(managedObject.id)!;
    }

    const result = new ObjectNode(
      managedObject.id,
      NodeType.NODE,
      managedObject.name ? managedObject.name : managedObject.id,
      managedObject,
      null,
      this.isParent(managedObject),
      null
    );
    this.nodeMap.set(managedObject.id, result);
    return result;
  }

  isParent(managedObject: ManagedObject): boolean {
    // const parent = managedObject.c8y_IsDeviceGroup
    //   || managedObject.c8y_IsDevice
    //   || managedObject.type == 'c8y_DeviceGroup'
    //   || managedObject.type == 'c4t_root';
    // return parent || managedObject.childDevices?.references.length > 0;

    // Could not determine if managed object is parent, because managed object is not device nor device group.
    // Therefore, treat it always as parent.
    return true;
  }

}
