import {User} from './user';

export class Profile {
  mandate: Mandate;
  availablePersons: MandatePerson[];
  availableMandates: MandatePerson[];
  token: string;
  user: User;
}

export class Mandate {
  user: MandateUser;
  person: MandatePerson;
  permissions: MandatePermission[];
  type: string;
}

export class MandateUser {
  countryCode: string;
  personalId: string;
  displayName: string;
  juridical: boolean;
  idPrivacyType: string;
  dob: string;
}

export class MandatePerson {
  countryCode: string;
  registrationNumber: string;
  displayName: string;
  juridical: boolean;
  idPrivacyType: string;
  dob: string;
}

export class MandatePermission {
  key: string;
  value: boolean;

  constructor (key: string, value: boolean) {
    this.key = key;
    this.value = value;
  }
}
