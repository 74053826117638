import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxPaginationModule} from 'ngx-pagination';
import {NgSelectModule} from '@ng-select/ng-select';
import {NgxPermissionsModule} from 'ngx-permissions';
import {TenantViewComponent} from '../private/components/tenant/view/tenant-view.component';
import {MandateGridComponent} from '../private/components/mandate/grid/mandate-grid.component';
import {MandateEditComponent} from '../private/components/mandate/edit/mandate-edit.component';
import {ConsentService} from './services/consent.service';
import {UserService} from './http/user.service';
import {JsonEditorComponent} from '../private/components/jsoneditor/jsoneditor.component';
import {TenantService} from './http/tenant.service';
import {RequestInterceptor} from './interceptors/request.interceptor';
import {AutosizeDirective} from './directives/autosize.directive';
import {CommonModule} from '@angular/common';
import {AppRoutingModule} from '../app-routing.module';
import {HeaderLanguagesSelect} from '../private/components/language-select/header-languages-select.component';
import {LoginComponent} from '../public/components/login/login.component';
import {FullLayoutComponent} from '../private/components/layout/full-layout/full-layout.component';
import {MatButtonModule} from '@angular/material/button';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {MatNativeDateModule} from '@angular/material/core';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatIconModule} from '@angular/material/icon';
import {MatInputModule} from '@angular/material/input';
import {MatTreeModule} from '@angular/material/tree';
import {CumuService} from '../private/http/cumu.service';
import {InputUppercaseDirective} from './directives/input-uppercase.directive';
import {InputLowercaseDirective} from './directives/input-lowercase.directive';
import {ConfirmModalComponent} from '../private/components/confirm-modal/confirm-modal.component';
import {ModalModule} from 'ngx-bootstrap/modal';
import {CprModalComponent} from '../private/components/cpr-modal/cpr-modal.component';
import {LaddaModule} from 'angular2-ladda';
import {MatProgressBarModule} from '@angular/material/progress-bar';
import {ToastrModule} from 'ngx-toastr';
import {NavbarComponent} from '../private/components/navbar/navbar.component';
import {BsDropdownModule} from 'ngx-bootstrap/dropdown';
import {AuthorizationsPageComponent} from '../private/components/authorizations-page/authorizations-page.component';
import {AddMandateModalComponent} from '../private/components/add-mandate-modal/add-mandate-modal.component';
import {LabelPipe} from './pipes/label.pipe';
import {RegNoPipe} from './pipes/reg-no.pipe';
import {PersonalIdPipe} from './pipes/personal-id.pipe';
import {AuthRedirectComponent} from '../public/components/auth-redirect/auth-redirect.component';
import {SelectedSourcesComponent} from '../private/components/tenant/browser/selected-sources.component';
import {SelectedSourcesService} from '../private/components/tenant/browser/selected-sources.service';
import {CheckboxComponent} from '../private/components/checkbox/checkbox.component';
import {TokenPageService} from '../private/components/token/token-page.service';
import {PopoverModule} from 'ngx-bootstrap/popover';
import {TermsOfServiceAgreementModalComponent} from '../private/components/terms-of-service/terms-of-service-agreement-modal.component';
import {TermsOfServiceComponent} from '../private/components/terms-of-service/terms-of-service.component';
import {FooterComponent} from '../private/components/footer/footer.component';
import {TermsOfServiceModalComponent} from '../private/components/terms-of-service/terms-of-service-modal.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import {DashboardResolver} from '../private/resolvers/dashboard.resolver';
import { FieldErrorMessages } from './components/form-field-error/field-error-messages.component';
import { SharePersonItemListComponent } from './components/share-person-item-list/share-person-item-list.component';
import {MappingLayoutComponent} from '../private/components/layout/mapping-layout/mapping-layout.component';

@NgModule({
  imports: [
    CommonModule,
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    NgbModule,
    NgxPaginationModule,
    BrowserAnimationsModule,
    NgSelectModule,
    NgxPermissionsModule,
    AppRoutingModule,
    ToastrModule,
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    MatNativeDateModule,
    BrowserModule,
    MatDatepickerModule,
    BrowserAnimationsModule,
    MatFormFieldModule,
    MatInputModule,
    MatCheckboxModule,
    MatIconModule,
    MatButtonModule,
    MatTreeModule,
    MatProgressBarModule,
    ModalModule,
    LaddaModule,
    BsDropdownModule,
    PopoverModule,
    TooltipModule
  ],
  declarations: [
    TenantViewComponent,
    MandateGridComponent,
    MandateEditComponent,
    JsonEditorComponent,
    AutosizeDirective,
    HeaderLanguagesSelect,
    LoginComponent,
    FullLayoutComponent,
    MappingLayoutComponent,
    InputUppercaseDirective,
    InputLowercaseDirective,
    ConfirmModalComponent,
    CprModalComponent,
    NavbarComponent,
    AuthorizationsPageComponent,
    AddMandateModalComponent,
    LabelPipe,
    PersonalIdPipe,
    RegNoPipe,
    AuthRedirectComponent,
    CheckboxComponent,
    TermsOfServiceAgreementModalComponent,
    TermsOfServiceComponent,
    FooterComponent,
    TermsOfServiceModalComponent,
    FieldErrorMessages,
    SharePersonItemListComponent
  ],
    exports: [
        TenantViewComponent,
        MandateGridComponent,
        MandateEditComponent,
        JsonEditorComponent,
        AutosizeDirective,
        HeaderLanguagesSelect,
        LoginComponent,
        FullLayoutComponent,
      MappingLayoutComponent,
        InputUppercaseDirective,
        InputLowercaseDirective,
        ConfirmModalComponent,
        CheckboxComponent,
        PersonalIdPipe,
        FieldErrorMessages,
        RegNoPipe,
        LabelPipe,
        SharePersonItemListComponent
    ],
  providers: [
    ConsentService,
    UserService,
    TenantService,
    CumuService,
    DashboardResolver,
    SelectedSourcesService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: RequestInterceptor,
      multi: true
    },
    LabelPipe,
    PersonalIdPipe,
    RegNoPipe,
    TokenPageService
  ]
})
export class CoreModule {
}
