<ng-template #navMenu>
  <nav class="header__nav">
    <ul class="header__nav-list">
      <li class="header__nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">
        <a routerLink="/mapping/setup" i18n class="header__nav-item-link">Choose source</a>
      </li>
      <li class="header__nav-item" routerLink="/mapping/source-grid" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}" >
        <a i18n class="header__nav-item-link">Waiting for mapping</a>
      </li>
      <li class="header__nav-item" routerLink="/mapping/mapped-source-grid" routerLinkActive="active">
        <a i18n class="header__nav-item-link">Mapped sources</a>
      </li>
    </ul>
  </nav>
</ng-template>
<router-outlet></router-outlet>






