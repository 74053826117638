import {CumuService} from '../../../http/cumu.service';
import {SupportedEvent, SupportedSourcesResponse} from '../../../models/source';
import {ObjectNode} from '../../../models/tenant-mapping/object-node';
import {NodeType} from '../../../models/tenant-mapping/node-type';
import {FilterParams} from '../../../models/tenant-mapping/filter-params';
import {ManagedObject} from '../../../models/tenant-mapping/managed-object';
import {ManagedObjectSearch} from '../../../models/tenant-mapping/managed-object-search';
import {ManagedObjectNodeStore} from './store/managed-object-node-store';
import {LoadManagedObjectsExecutor} from './executors/load-managed-objects-executor';
import {SearchParams} from '../../../models/tenant-mapping/search-params';
import {TenantBrowserConstants} from '../../../models/tenant-browser-constants';

export interface LoadManagedObjectsResult {
  showLoadMore: boolean;
  items: ManagedObject[]
}

export interface CreateSearchParams {
  text?: string;
  wildcardSearch: boolean,
  deviceGroupQuery: boolean,
  namedQuery: boolean
}

export abstract class AbstractSourceDatabase {

  protected tenantId: number;

  protected filterParams: FilterParams;

  protected overallSearch: ManagedObjectSearch;

  protected textSearch: ManagedObjectSearch;

  protected wildcardSearch: ManagedObjectSearch;

  protected namedQuery = true;

  protected deviceGroupQuery = true;

  protected childAssetsQuery = true;

  protected managedObjectNodeStore: ManagedObjectNodeStore;

  private loadManagedObjectsExecutor: LoadManagedObjectsExecutor;

  private _data: ObjectNode[] = [];

  get data(): ObjectNode[] { return this._data; }

  set data(data: ObjectNode[]) { this._data = data; }

  constructor(protected cumuService: CumuService) {
    this.managedObjectNodeStore = new ManagedObjectNodeStore();
  }

  public abstract onDataChange(data): void;

  public abstract buildEventNode(id: string, parentNode: ObjectNode, event: SupportedEvent);

  public abstract loadChildren(params: {parent: ObjectNode, onlyWithMeasurements: boolean }, cb): void;

  public abstract checkChildren(parent: ObjectNode): void;

  public getManagedObjectStore(): ManagedObjectNodeStore {
    return this.managedObjectNodeStore;
  }

  loadMore(params: {itemId: string, onlyWithMeasurements: boolean, onlyFirstTime: boolean }, cb) {
    if (!this.managedObjectNodeStore.has(params.itemId)) {
      cb();
      return;
    }

    if (params.itemId == '-1') {
      console.log('Load more from root');
      return this.loadManagedObjects(cb);
    }

    const loadMoreNode = this.managedObjectNodeStore.get(params.itemId);
    const parent = this.managedObjectNodeStore.get(loadMoreNode.managedObject.id);

    if (params.onlyFirstTime && parent.children!.length > 0) {
      console.log('Not first. Some conflict');
      cb();
      return;
    }

    if (parent.children!.length === 0) {
      console.log('Load series');
      this.loadSeries(parent, () => {
        console.log('Load children after series');
        this.loadChildren({
          parent: parent,
          onlyWithMeasurements: params.onlyWithMeasurements
        }, () => {
          console.log('check children');
          this.checkChildren(parent);
          cb();
        });
      });
    } else {
      console.log('Load children');
      this.loadChildren({
        parent: parent,
        onlyWithMeasurements: params.onlyWithMeasurements
      }, () => {
        console.log('check children');
        this.checkChildren(parent);
        cb();
      });
    }
  }

  public initLoadManagedObjects(searchParams: SearchParams) {
    this.getManagedObjectStore().clear();
    this.data = [];
    this.loadManagedObjectsExecutor = new LoadManagedObjectsExecutor(this, this.tenantId, this.managedObjectNodeStore, this.cumuService, searchParams);
  }

  public loadManagedObjects(cb): void {
    let executor = this.loadManagedObjectsExecutor;

    executor.execute((children: ObjectNode[], completed: boolean) => {
      this.updateRootManagedObjects(children);
      cb();
    });

  }

  protected _generateManagedObjectNode(item: ManagedObject): ObjectNode {
    return this.managedObjectNodeStore.createFromManagedObject(item);
  }

  private loadSeries(parentNode: ObjectNode, cb: any) {
    if (!parentNode.managedObject || parentNode.managedObject.c8y_IsDeviceGroup) {
      cb();
      return;
    }

    // Is device, search supported sources
    this.cumuService.loadSupportedSources(this.tenantId, parentNode.managedObject.id).subscribe(
      (data: SupportedSourcesResponse) => {
        let children = parentNode.children;

        for (let i = 0; i < data.series.length; i++) {
          let series = data.series[i];
          let id = parentNode.id + '_' + series.seriesPath;
          if (this.managedObjectNodeStore.has(id)) {
            continue;
          }

          const result = new ObjectNode(
            id,
            NodeType.TENANT_MEASUREMENT,
            series.seriesPath,
            parentNode.managedObject,
            series.seriesPath,
            false,
            null,
            this.isMapped(series)
          );
          this.managedObjectNodeStore.set(id, result);
          children.push(result);
        }

        for (let i = 0; i < data.events.length; i++) {
          let event = data.events[i];
          let id = parentNode.id + '_' + event.eventType;
          if (this.managedObjectNodeStore.has(id)) {
            continue;
          }

          const result = this.buildEventNode(id, parentNode, event);
          this.managedObjectNodeStore.set(id, result);
          children.push(result);
        }

        let id = parentNode.id + '_EVENT';
        const result = this.buildEventNode(id, parentNode, null);
        this.managedObjectNodeStore.set(id, result);
        children.push(result);

        parentNode.childrenChange.next(children);
        this.updateRootManagedObjects(this.data);
        cb();

      },
      (err: any) => {
        console.error(err);
        cb();
      }
    );
  }

  private updateRootManagedObjects(data: ObjectNode[]) {
    this._data = data;
    this.onDataChange(data);
  }

  private isMapped(series): boolean {

    return series.id != null || TenantBrowserConstants.DAL_RESERVED_SERIES_PATHS.includes(series.seriesPath);
  }

}
