<ng-template #navActions>
  <button type="button" class="btn btn-sm btn-primary d-none d-lg-inline-block" routerLink="add">
    <i class="fal fa-plus"></i>&nbsp;<span i18n>Create new</span>
  </button>
  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" routerLink="add">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>
</ng-template>

<ng-template #sharedPopover let-token="token">
  <div>
    <span *ngIf="isMyToken(token)" i18n>Shared by me</span>
    <span *ngIf="!isMyToken(token)" i18n>Shared to me</span>
  </div>
</ng-template>

<div class="container-fluid pt-4">
  <div class="form-row">
    <div class="col-3">
      <form [formGroup]="searchForm" class="input-group">
        <select class="custom-select" formControlName="searchType">
          <option [ngValue]="null" i18n>All</option>
          <option [value]="'my-tokens'" i18n>My API keys</option>
          <option [value]="'my-shared-tokens'" i18n>My shared API keys</option>
          <option [value]="'tokens-shared'" i18n>API keys shared with me</option>
        </select>
      </form>
    </div>
  </div>


  <table class="table table-striped btn-table table-selectable table-sm">
    <thead>
      <tr>
        <th i18n>Name</th>
        <th i18n>Value</th>
        <th i18n>Shared persons</th>
        <th i18n>Shared history</th>
        <th i18n>Owner</th>
        <th></th>
      </tr>
    </thead>
    <tbody *ngIf="tokensPage">
      <tr
        *ngFor="let item of tokenItems">
        <td>
          <div style="display: flex; vertical-align: middle;">
            <mat-icon *ngIf="item.token.primary" class="btn-tooltip ml-2" tooltip="My personal key" i18n-tooltip>key</mat-icon>

            <span *ngIf="item.token.assigned" class="btn-tooltip ml-2" [popover]="sharedPopover" [popoverContext]="{ token : item.token }" placement="right" triggers="mouseenter:mouseleave">
              <mat-icon *ngIf="isMyToken(item.token)">north_east</mat-icon>
              <mat-icon *ngIf="!isMyToken(item.token)">south_west</mat-icon>
            </span>
            <span>&nbsp;</span>
            <a class="title" [routerLink]="['view', {id: item.token.id}]">{{item.token.name}}</a>
          </div>
        </td>
        <td>
          <span>
            {{item.token.value}}
            <button mat-icon-button (click)="copyToClipboard(item.token)">
              <mat-icon>content_copy</mat-icon>
            </button>
          </span>
        </td>
        <td>
          <span *ngIf="isMyToken(item.token) && item.token.tokenPersons.length">
            <share-person-item-list (click)="showTokenPersons(item.token)" [persons]="mapTokenPersonToPersons(item.token.tokenPersons)"></share-person-item-list>
          </span>
        </td>
        <td>
           <span *ngIf="isMyToken(item.token) && item.history?.persons.length > 0">
                    <button type="button" class="btn btn-sm btn-light" i18n (click)="showHistory(item)">Show</button>
           </span>
        </td>
        <td>{{item.token.ownerPerson?.displayName}}</td>
        <td class="text-right">
          <div class="btn-group btn-more" dropdown *ngIf="isMyTokenOrTokenSharedToMe(item.token)">
            <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <a *ngIf="isMyToken(item.token)" class="dropdown-item" [routerLink]="['edit', {id: item.token.id}]"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
              <a *ngIf="isMyToken(item.token) && !item.token.primary" class="dropdown-item" (click)="deleteToken(item.token)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
              <a *ngIf="isTokenSharedToMe(item.token)" class="dropdown-item" (click)="declineToken(item.token)"><i class="fal fa-trash"></i> <ng-container i18n>Decline</ng-container></a>
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </table>

  <div class="row">
    <pagination-controls
      class="margin-center"
      (pageChange)="pageChanged($event)"
      maxSize="5"
      directionLinks="true"
      autoHide="true"
      previousLabel="Previous"
      nextLabel="Next"
      screenReaderPaginationLabel="Pagination"
      screenReaderPageLabel="page"
      screenReaderCurrentLabel="You're on page" i18n-previousLabel i18n-nextLabel i18n-screenReaderPaginationLabel i18n-screenReaderPageLabel i18n-screenReaderCurrentLabel>
    </pagination-controls>
  </div>
</div>
