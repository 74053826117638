import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment as config, environment} from '../../../environments/environment';
import {Person} from '../model/person';
import {Mandate, Profile} from '../model/profile';
import {map} from 'rxjs/operators';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class UserService {

  constructor(private http: HttpClient) {
  }

  getProfile(): Observable<any> {
    return this.http.get<Profile>(config.apiUrl + '/user/profile');
  }

  updateRole(person: Person): Observable<Mandate> {
    return this.http.put<Mandate>(environment.apiUrl + '/user/role', person);
  }

  searchUsers(term: string): Observable<any[]> {
    const params: any = {
      term: term
    };
    return this.http.get(environment.apiUrl + '/user/search', {params: params}).pipe(map((data: any) => data.content));
  }

  searchPersons(term: string): Observable<Person[]> {
    const params: any = {
      term: term
    };
    return this.http.get<{ content: Person[] }>(environment.apiUrl + '/person/search', {params: params}).pipe(
      map((response) => response.content)
    );
  }

  getMandates() {
    return this.http.get(environment.apiUrl + '/user/mandate');
  }

  getMandate(countryCode, personalId) {
    return this.http.get(environment.apiUrl + '/user/mandate/' + countryCode + '-' + personalId);
  }

  saveMandate(mandate: Mandate) {
    return this.http.post<Mandate>(environment.apiUrl + '/user/mandate', mandate);
  }

  deleteMandate(mandate: Mandate) {
    return this.http.delete(environment.apiUrl + '/user/mandate/' + mandate.user.countryCode + '-' + mandate.user.personalId);
  }

  acceptTerms(): Observable<any> {
    return this.http.put(config.apiUrl + '/user/terms/accept', {}, {
      params: {
        siteRef: 'data-mapping-site'
      }
    });
  }
}

