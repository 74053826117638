import {ObjectSearchParams} from './object-search-params';
import {ManagedObjectsResponse} from './managed-objects-response';
import {ManagedObject} from './managed-object';

export class ManagedObjectSearch {
  namedQuery = false;
  deviceGroupQuery = false;
  wildCardSearch = false;
  text: string;
  params: ObjectSearchParams;
  response: ManagedObjectsResponse;
  next = false;
  items: ManagedObject[] = [];

  constructor(props?: any) {
    if (!props) {
      return;
    }

    if (props.params) {
      this.params = props.params;
    }

    if (props.deviceGroupQuery != null) {
      this.deviceGroupQuery = props.deviceGroupQuery;
    }

    if (props.wildCardSearch != null) {
      this.wildCardSearch = props.wildCardSearch;
    }

    if (props.text != null) {
      this.text = props.text;
    }
  }

}
