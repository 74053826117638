<input [formControl]="dawaAddress"
       type="text"
       class="form-control mt-1"
       placeholder="Address"
       i18n-placeholder
       [ngbTypeahead]="addressSearch"
       [inputFormatter]="dawaInputFormatter"
       [resultFormatter]="dawaResultFormatter"
>
<b *ngIf="submitted && form.get('address').invalid" class="text-danger">*</b>
<input [formControl]="form.get('apartment')"
       type="text" class="form-control mt-1"
       placeholder="Apartment" i18n-placeholder
>
<input [formControl]="form.get('room')"
       type="text" class="form-control mt-1"
       placeholder="Room" i18n-placeholder>
<!-- FIXME [style.visibility]="searching ? 'visible' : 'hidden'" -->
