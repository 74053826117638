import {Injectable, OnDestroy, TemplateRef} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from "@angular/router";
import {filter, shareReplay} from 'rxjs/operators';
import {Observable, ReplaySubject} from "rxjs";

@Injectable({providedIn: "root"})
export class NavbarService implements OnDestroy {
  private menuTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
  private actionsTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
  private footerTemplateSource = new ReplaySubject<TemplateRef<any>>(1);
  public menuTemplate$: Observable<TemplateRef<any>> = this.menuTemplateSource.asObservable().pipe(shareReplay(1));
  public actionsTemplate$: Observable<TemplateRef<any>> = this.actionsTemplateSource.asObservable().pipe(shareReplay(1));
  public footerTemplate$: Observable<TemplateRef<any>> = this.footerTemplateSource.asObservable();

  private breadcrumbsSource = new ReplaySubject<any[]>(1);
  public breadcrumbs$: Observable<any[]> = this.breadcrumbsSource.asObservable().pipe(shareReplay(1));

  constructor(
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe(() => {
      this.generateBreadcrumbs();
    });
  }

  ngOnDestroy(): void {
    this.menuTemplateSource.complete();
    this.actionsTemplateSource.complete();
    this.footerTemplateSource.complete();
    this.breadcrumbsSource.complete();
  }

  private generateBreadcrumbs(): void {
    const breadcrumbs = [];
    let actionsComponent = null;

    let route = this.route.snapshot;

    while (route && route.outlet === 'primary') {
      let next = null;

      if (route.data.breadcrumb) {
        route.data.breadcrumb.path = route.url.map((e) => e.path);
        if (breadcrumbs.length) {
          route.data.breadcrumb.path = [...breadcrumbs[breadcrumbs.length - 1].path, ...route.data.breadcrumb.path];
        }
        breadcrumbs.push(route.data.breadcrumb);
      }

      if (route.data.navbarActionsComponent) {
        actionsComponent = route.data.navbarActionsComponent;
      }

      route.children.forEach(child => {
        if (child.outlet === 'primary') {
          next = child;
        }
      });

      route = next;
    }

    this.breadcrumbsSource.next(breadcrumbs);
  }

  public setMenuTemplate(template: TemplateRef<any>) {
    this.menuTemplateSource.next(template);
  }

  public setActionsTemplate(template: TemplateRef<any>) {
    this.actionsTemplateSource.next(template);
  }

  public setFooterTemplate(template: TemplateRef<any>) {
    this.footerTemplateSource.next(template);
  }
}
