import {Component, OnInit} from '@angular/core';
import {PersonService} from '../../../../core/services/person.service';
import {environment} from '../../../../../environments/environment';
import {Token} from '../../../../core/model/person';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-token-view',
  templateUrl: './token-view.component.html',
  providers: [
    PersonService
  ]
})
export class TokenViewComponent implements OnInit {

  public config = environment;

  /*public tokensPage: any;*/

  public pageFilter: any = {
    page: 1,
    size: 10,
    keyword: ''
  };

  public sampledata = {
    data: [
      {
        dataSourceId: 'device-sensor-property',
        deviceId: 'some-device-unique-id',
        values: [
          {
            time: '',
            unit: '%',
            value: 59
          }
        ]
      }
    ]
  };

  public sampleMetadata = {
    mappings: [
      {
        metaFields: {
          name: 'Demo datasource',
          groupingTag: 'BUILDINGS',
          seriesType: 'HEAT',
          locationType: 'ADDRESS',
          address: 'Main St 123',
          apartment: '5',
          room: 'Living room',
          lat: 58.3749329,
          lng: 26.6625058,
          uuid: 'some-identifier-123',
          personCountryCode: environment.defaultCountryCode,
          personRegistrationNumber: '50001010006'
        },
        source: {
          deviceId: 'some-device-unique-id',
          dataSourceId: 'device-sensor-property',
        }
      }
    ]
  };

  public token: Token = null;

  constructor(
    private personService: PersonService,
    private route: ActivatedRoute,
    public toastr: ToastrService
  ) {
    const time = new Date();
    this.sampledata.data[0].deviceId = 'some-device-unique-id';
    this.sampledata.data[0].values[0].time = time.toISOString();
    this.sampledata.data[0].values[0].value = Math.round(Math.random() * 100);
  }

  ngOnInit() {
    this.listenUrl();
  }

  listenUrl(): void {
    this.route.params.subscribe(
      (params: any) => {
        if (params.id) {
          this.personService.getToken(params.id).subscribe(
            (token: Token) => {
              this.token = token;
            },
            (sErr: any) => {
              this.toastr.error($localize`Error on receiving token!`);
            }
          );
        } else {
          this.toastr.error($localize`Error on receiving token!`);
        }
      });
  }

}
