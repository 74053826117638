import {Pipe, PipeTransform} from '@angular/core';

@Pipe({name: 'label'})
export class LabelPipe implements PipeTransform {

  private labelMap: any;

  constructor() {

    this.labelMap = {
      HEAT: $localize`Heat`, // series types
      HEAT_METER: $localize`Heat meter`,
      WATER: $localize`Water`,
      HOT_WATER: $localize`Hot water`,
      GAS: $localize`Gas`,
      GAS_METER: $localize`Gas meter`,
      HUMIDITY: $localize`Humidity`,
      LIGHT: $localize`Light`,
      TEMPERATURE: $localize`Temperature`,
      ELECTRICITY: $localize`Electricity`,
      ELECTRICITY_METER: $localize`Electricity meter`,
      SMOKE_DETECTOR: $localize`Smoke detector`,
      ILLUMINATION: $localize`Illumination`,
      OTHER: $localize`Other`,
      ENERGY_CONSUMPTION: $localize`Energy consumption`,
      ENERGY_PRODUCTION: $localize`Energy production`,

      EDIT_MANDATES: $localize`Edit delegation`, // user permissions

      // aggregation type options
      AVERAGE: $localize`Average`,
      SUM: $localize`Sum`,
      MIN: $localize`Minimum`,
      MAX: $localize`Maximum`,

      // Status types
      PENDING: $localize`Pending`,
      CANCELLED: $localize`Cancelled`,
      DECLINED: $localize`Declined`,
      IN_PROGRESS: $localize`In progress`,
      COMPLETED: $localize`Completed`,
      SENT: $localize`Sent`,
      DRAFT: $localize`Draft`

      // ...
    };
  }

  transform(value: string, classifier?: string): string {
    if (this.labelMap[value]) {
      return this.labelMap[value];
    } else {
      return value;
    }
  }
}
