import {Component, OnInit, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-full-layout',
  templateUrl: './full-layout.component.html',
  encapsulation: ViewEncapsulation.None,
  styles: [`:host {
    height: 100%;
  }`]
})
export class FullLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
