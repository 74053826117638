import { Injectable } from '@angular/core';
import {LocationStrategy} from '@angular/common';

@Injectable({
  providedIn: 'root'
})
export class LocationService {

  constructor(private locationStrategy: LocationStrategy) { }

  public getSiteUrl(): string {
    let url = window.location.protocol + "//" + window.location.host + this.locationStrategy.getBaseHref();
    url = url.replace(/\/$/, ""); //remove trailing slash

    return url;
  }

}
