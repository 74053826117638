import {Injectable, OnDestroy} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {Enums} from '../model/enums';
import {GroupingTags, SeriesType, StatusTypes} from '../../private/models/series';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {LabelPipe} from '../pipes/label.pipe';
import {environment} from '../../../environments/environment';

@Injectable({providedIn: 'root'})
export class EnumsService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  constructor(
    private http: HttpClient,
    private labelPipe: LabelPipe
  ) {
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadEnums() {
    SeriesType.types.splice(0, SeriesType.types.length);

    this.getEnums().pipe(takeUntil(this.ngDestroy)).subscribe((enums) => {
      if (enums) {
        SeriesType.types.splice(0, SeriesType.types.length);
        SeriesType.types.push(...enums.seriesType);

        StatusTypes.types.splice(0, StatusTypes.types.length);
        StatusTypes.types.push(...enums.confirmationStatus);

        GroupingTags.tags.splice(0, GroupingTags.tags.length);
        GroupingTags.tags.push(...enums.predefinedGroupingTag);
      }
    });
  }

  getEnums(): Observable<Enums> {
    return this.http.get<Enums>(environment.apiUrl + '/enums');
  }

  public getOptionsList(keys: string[]) {
    return keys.map((type: string) => ({
      value: type,
      label: this.labelPipe.transform(type)
    }));
  }
}
