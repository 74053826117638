import {Injectable, Injector} from '@angular/core';
import {AuthConstants} from './auth-constants';
import {AuthParams, AuthResult} from './idp-auth.service';
import {CacheService} from '../cache/cache.service';
import {Mandate, Profile} from '../model/profile';
import {Person} from '../model/person';
import {Subject} from 'rxjs/internal/Subject';
import {catchError, distinctUntilChanged, map, share, shareReplay, take, takeUntil} from 'rxjs/operators';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {HttpClient} from '@angular/common/http';
import {LocationService} from '../services/location.service';
import {NgxPermissionsService} from 'ngx-permissions';
import {UserService} from '../http/user.service';
import {ActivatedRoute} from '@angular/router';
import {environment as config} from '../../../environments/environment';
import {BsModalService} from 'ngx-bootstrap/modal';
import {TermsOfServiceAgreementModalComponent} from '../../private/components/terms-of-service/terms-of-service-agreement-modal.component';
import {of} from 'rxjs/internal/observable/of';
import {fromPromise} from 'rxjs/internal-compatibility';
import {IdpService} from './idp.service';

@Injectable({
  providedIn: 'root'
})
export class TokenAuthService {

  private role: Person;

  private token: string;

  public authenticated = false;

  public profileData: Profile;

  private ngDestroy = new Subject<void>();

  private authenticationResultSource: Subject<AuthResult> = new Subject<AuthResult>();

  public authenticationResult$ = this.authenticationResultSource.asObservable().pipe(takeUntil(this.ngDestroy), share());

  private logoutSource = new Subject<{  token: string }>();

  public logout$ = this.logoutSource.asObservable();

  private _currentMandate = new ReplaySubject<any>(1);

  public currentMandate$ = this._currentMandate.asObservable().pipe(distinctUntilChanged(), shareReplay(1));

  // Tracks sso onLogin -> ssoApiLogin/nemIdApiLogin results
  private loginResult$ = new Subject<boolean>();

  constructor(protected http: HttpClient,
              protected locationService: LocationService,
              protected cacheService: CacheService,
              protected permissionService: NgxPermissionsService,
              protected userService: UserService,
              protected injector: Injector,
              protected activateRoute: ActivatedRoute,
              protected idpService: IdpService) {

    this.authenticationResult$.subscribe((result) => {
      this.authenticated = result.result;
    });
  }

  public async ssoApiLogin(ssoData): Promise<any> {
    const profile: Profile = await this.http.post<Profile>(config.apiUrl + '/login', {
      token: ssoData.token,
      personRegistrationNumber: ssoData.mandate ? ssoData.mandate.person.registrationNumber : null,
      personCountryCode: ssoData.mandate ? ssoData.mandate.person.countryCode : null
    }).toPromise();

    await this.profileLogin(profile);
  }

  public async getAuthenticationStatus(authParams: AuthParams): Promise<boolean> {
    if (this.authenticated) {
      return true;
    }

    return this.authenticate(authParams);
  }

  public startLogout(): void {
    this.logoutSource.next({
      token: this.token
    });
  }

  public doLogout(skipRedirect: boolean = false): void {
    this.clearSession();
  //  this.clearAuthentication();
  //  this.authenticationResultSource.next({token: this.token, result: false, skipRedirect: skipRedirect, reason: 'logout'});
  }

  public getToken(): string {
    return this.token;
  }

  public setCurrentMandate(data: Mandate) {
    this.setRole(data.person);

    this.profileData.mandate = data;

    this.cacheService.setValue(AuthConstants.PROFILE_KEY, JSON.stringify(this.profileData));

    this.loadPermissions();
  }

  public async authenticate(authParams: AuthParams): Promise<boolean> {
    if (authParams.refresh) {
      if (this.token) {
        this.clearAuthentication();
      }
      return false;
    }
    if (await this.getLoginState()) {
      return true;
    }

    return this.defaultLogin(authParams);
  }

  public async getLoginState(): Promise<boolean> {
    return this.getTokenLoginState();
  }

  public async getTokenLoginState(): Promise<boolean> {
    const token = this.cacheService.getValue(AuthConstants.TOKEN_KEY);
    const profileStr =  this.cacheService.getValue(AuthConstants.PROFILE_KEY);
    const profile = profileStr ? JSON.parse(profileStr) : null;
    if (token && profile) {
      this.token = token;
      this.profileData = profile;

      try {
        await this.profileLogin(profile);
        this.loginResult$.next(true);
        return true;
      } catch (e) {
        console.error(e);
        this.clearAuthentication();
        this.loginResult$.next(false);
        return false;
      }
    }

    this.loginResult$.next(false);

    return false;
  }

  private async defaultLogin(authParams: AuthParams): Promise<boolean> {
    if (authParams.token) {
      return this.tokenLogin(authParams.token);
    }

    return false;
  }

  public async tokenLogin(token): Promise<boolean> {
    try {
      this.token = token;
      const profile = await this.reloadProfile();
      await this.profileLogin(profile);

      return true;
    } catch (e) {
      this.onAuthentication({result: false, reason: e});
    }

    return false;
  }

  private async profileLogin(profile: Profile) {
    this.setProfile(profile);

    if (!profile.user.dataMappingSiteTermsAccepted) {
      await this.acceptTerms();
      profile.user.dataMappingSiteTermsAccepted = true;
    }

    const lastRole: Person = this.getRole();
    let role: Person = null;
    if (lastRole) {
      role = this.profileData.availablePersons.find((e) => {
        return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
      });
      if (!role) {
        role = this.profileData.availableMandates.find((e) => {
          return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
        });
      }
    }

    if (!role && this.profileData.availablePersons.length > 0) {
      role = this.profileData.availablePersons[0];
    }

    await this.switchToRole(role);


    this.onAuthentication({result: true, reason: null});
  }

  public async switchToRole(person: Person): Promise<void> {
    const mandate: Mandate = await this.userService.updateRole(person).toPromise();
    this.setCurrentMandate(mandate);
  }

  public async reloadProfile() {
    const profile: Profile = await this.userService.getProfile().toPromise();
    this.setProfile(profile);

    const lastRole: Person = this.getRole();
    let role: Person = null;
    if (lastRole) {
      role = this.profileData.availablePersons.find((e) => {
        return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
      });
      if (!role) {
        role = this.profileData.availableMandates.find((e) => {
          return e.registrationNumber === lastRole.registrationNumber && e.countryCode === lastRole.countryCode;
        });
      }
    }
    return profile;
  }

  private getRole(): Person {
    if (this.role != null) {
      return this.role;
    }
    this.role = JSON.parse(this.cacheService.getValue(AuthConstants.ROLE_KEY));
    if (!this.role) {
      this.setRole(null);
    }
    return this.role;
  }

  private setProfile(profile: Profile) {
    this.profileData = profile;
    this.token = profile.token;

    this.cacheService.setValue(AuthConstants.TOKEN_KEY, this.token);
    this.cacheService.setValue(AuthConstants.PROFILE_KEY, JSON.stringify(this.profileData));
  }

  private loadPermissions() {
    if (this.profileData) {
      if (this.profileData.mandate.type === 'MANDATE') {
        this.permissionService.loadPermissions(this.profileData.mandate.permissions.map((item) => {
          return item.key;
        }));
      } else {
        this.permissionService.loadPermissions(['OWNER']);
      }
    } else {
      this.clearAuthentication();
      this.permissionService.loadPermissions([]);
    }

    this._currentMandate.next(this.profileData ? this.profileData.mandate : null);
  }

  private setRole(role: any): void {
    if (!role) {
      this.role = null;
      this.cacheService.removeValue(AuthConstants.ROLE_KEY);
      return;
    }
    this.cacheService.setValue(AuthConstants.ROLE_KEY, JSON.stringify(role));
  }

  private clearAuthentication() {
    this.token = null;
    this.profileData = null;
    this.setRole(null);
    this.clearSession();
  }

  private clearSession(): void {
    this.cacheService.removeValue(AuthConstants.TOKEN_KEY);
    this.cacheService.removeValue(AuthConstants.PROFILE_KEY);
    this.cacheService.removeValue(AuthConstants.ROLE_KEY);
    const cookies = document.cookie.split(';');
    for (let i = 0; i < cookies.length; i++) {
      const cookie = cookies[i];
      const eqPos = cookie.indexOf('=');
      const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
      document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT';
    }
  }

  async acceptTerms(): Promise<void> {
    if (config.tosContentEnabled) {
      const modalService = this.injector.get(BsModalService);

      const modalRef = modalService.show(TermsOfServiceAgreementModalComponent, {
        class: 'modal-lg'
      });

      modalRef.onHide.subscribe((event: string | any) => {
        if (event === 'backdrop-click') {
          this.onTermsDismissed();
        }
      });

      const resultData = await modalRef.content.result$.pipe(catchError((error) => {
        return of(false);
      })).toPromise();

      if (!resultData) {
        this.onTermsDismissed();
      }
    }

    await this.userService.acceptTerms().toPromise();
  }

  private onTermsDismissed(): void {
    this.startLogout();
  }

  public onAuthentication(authResult: AuthResult): void {
    this.authenticationResultSource.next(authResult);
  }

}
