import {AfterViewInit, Component, Inject, LOCALE_ID, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Route, Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {ToastrService} from 'ngx-toastr';
import {IdpService} from '../../../core/auth/idp.service';
import {IdpCode} from '../../../core/auth/idp-code';
import {TokenAuthService} from '../../../core/auth/token-auth.service';
import {IdpAuthService} from '../../../core/auth/idp-auth.service';

export enum LoginType {
  IFRAME = 'IFRAME',
  URL = 'URL'
}
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent implements OnInit, OnDestroy, AfterViewInit {
  private ngDestroy = new Subject<void>();

  public noPersonalCodeError: any = null;

  private redirectParams: any = null;

  public dalLogo = 'assets/img/city-portal-logo.svg';
  public iconRocket = 'assets/img/icon_rocket.svg';
  public iconMast = 'assets/img/icon_mast.svg';
  public iconSatelliteDish = 'assets/img/icon_satellite-dish.svg';

  constructor(
    public idpService: IdpService,
    public router: Router,
    public route: ActivatedRoute,
    public toastr: ToastrService,
    private idpAuthSwitchService: IdpAuthService,
    private tokenAuthService: TokenAuthService,
    @Inject(LOCALE_ID) public locale: string
  ) {

  }

  ngOnInit() {
    let idp: IdpCode = this.idpService.getActiveIdp();
    this.route.queryParamMap.subscribe((params: ParamMap) => {
      if (idp == IdpCode.TARA) {
        let urlParams = {
          locale: this.locale
        };

        let redirectUri = (params.get('redirectUri') ? params.get('redirectUri') : '/');
        this.redirectParams = {
          targetUrlPath: redirectUri,
          currentUrlPath: '/',
          params: urlParams
        };

      } else {
        let redirectUri = (params.get('redirectUri') ? params.get('redirectUri') : '/');
        this.redirectParams = {
          targetUrlPath: redirectUri,
          currentUrlPath: '/',
        };
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  ngAfterViewInit(): void {
  }

  loginRedirect(): void {
    this.idpAuthSwitchService.requestAuthentication(this.redirectParams);
  }

  loginAsDemoUser(): void {
    this.idpService.setActiveIdp(IdpCode.DEMO, true);
    this.idpAuthSwitchService.switchTo(IdpCode.DEMO);
    this.idpAuthSwitchService.requestAuthentication(this.redirectParams);
  }
}
