import {FlatTreeControl} from '@angular/cdk/tree';
import {SourceAsset, SourceAssetMapper} from '../models/series';
import {ObjectFlatNode} from '../models/tenant-mapping/object-flat-node';

export class TreeControlUtil {

  private treeControl: FlatTreeControl<ObjectFlatNode>;

  constructor(treeControl: FlatTreeControl<ObjectFlatNode>) {
    this.treeControl = treeControl;
  }

  public getParent(node: ObjectFlatNode): ObjectFlatNode {
    const currentLevel = TreeControlUtil.getLevel(node);
    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];
      if (TreeControlUtil.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

    return null;
  }

  public getAssetsHierarchy(node: ObjectFlatNode): SourceAsset[] {
    const parent = this.getParent(node);
    const result: SourceAsset[] = [SourceAssetMapper.objectFlatNodeToSourceAsset(node)];
    if (parent) {
      result.push(...this.getAssetsHierarchy(parent));
    }

    return result;
  }

  private static getLevel(node: ObjectFlatNode): number {
    return node.level;
  }

}
