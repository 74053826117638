import {Component, Inject, LOCALE_ID, OnInit} from '@angular/core';
import {environment as config} from '../../../../environments/environment';

@Component({
  selector: 'terms-of-service',
  templateUrl: './terms-of-service.component.html'
})
export class TermsOfServiceComponent implements OnInit {

  termsOfService: string = '';

  constructor(@Inject(LOCALE_ID) public locale: string) { }

  ngOnInit () {
    if (config.termsOfService) {
      if (config.termsOfService[this.locale]) {
        this.termsOfService = config.termsOfService[this.locale];
      } else {
        this.termsOfService = config.termsOfService['default'];
      }
    }
  }

}
