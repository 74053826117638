import {Injectable} from '@angular/core';
import {LogoutCallback} from '../logout-callback';
import {LocationService} from '../../services/location.service';
import {IdpAuthService} from '../idp-auth.service';
import {environment as config} from '../../../../environments/environment';
import {RedirectStrategy} from '../redirect/redirect-strategy';
import {NemLogInRedirectStrategy} from './nem-log-in-redirect-strategy';
import {NemLogInLogoutRedirectStrategy} from './nem-log-in-logout-redirect-strategy';
import {Observable} from 'rxjs/index';
import {HttpClient} from '@angular/common/http';
import {PkceUtil} from '../../utils/pkce-util';
import {TokenAuthService} from '../token-auth.service';
import {IdpCode} from '../idp-code';

@Injectable({
  providedIn: 'root'
})
export class NemLogInAuthService extends IdpAuthService {

  private redirectStrategy: RedirectStrategy;

  private logoutRedirectStrategy: RedirectStrategy;


  constructor(protected http: HttpClient,
              protected tokenAuthService: TokenAuthService,
              protected locationService: LocationService) {
    super(tokenAuthService);
  }

  private init() {
    this.redirectStrategy = new NemLogInRedirectStrategy(config, this.locationService.getSiteUrl());
    this.logoutRedirectStrategy = new NemLogInLogoutRedirectStrategy(config.apiUrl);
  }

  requestAuthentication(params?: any): void {
    if (!this.redirectStrategy) {
      this.init();
    }
    this.redirectStrategy.redirect(params);
  }

  authenticationCallback(urlParams?: any) {
    let params = {
      code: urlParams.code,
      codeVerifier: PkceUtil.getCodeVerifier(),
      state: PkceUtil.getState(),
      clientId: config.authServiceName ? config.authServiceName : 'default',
      redirectUrl: urlParams.next
    };

    this.requestToken(params).subscribe((result) => this.onNemLogInLogin(result));
  }

  doLogout(params?: any): Promise<boolean> {
    if (!this.logoutRedirectStrategy) {
      this.init();
    }
    return this.logoutRedirectStrategy.redirect(params);
  }

  public requestToken(params: any): Observable<any> {
    return this.http.post(config.apiUrl + '/nem-log-in/token', {}, {
      params: params
    });
  }

  private async onNemLogInLogin(result: any): Promise<void> {
    if (!result.token) {
      return;
    }

    try {
      await this.tokenAuthService.tokenLogin(result.token);
      this.onAuthentication({redirectTo: result.redirectUrl, result: true, reason: null});
    } catch (e) {
      this.onAuthentication({redirectTo: result.redirectUrl, result: false, reason: e});
    }
  }

  getIdp(): IdpCode {
    return IdpCode.NEM_LOG_IN;
  }

  switchTo(idp: IdpCode) {
  }

  reset(): void {
  }

}
