<div class="row justify-content-center d-flex mt-2">
  <div class="col-md-12 col-lg-6">
    <div class="form-row">
      <div class="col-12">
        <p i18n>To ensure that the API Key is working correctly, you can test the /data API...</p>
        <button type="button" class="btn btn-primary btn-xs" (click)="regenerateDatasource()"><i class="fas fa-refresh"></i>&nbsp;<ng-container i18n>New datasource</ng-container></button>
        <hr />

        <h5 i18n>Try sending measurement data</h5>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>{{sampleData | json }}</pre>
          </code>
        </div>
        <button (click)="test()" type="button" class="btn btn-outline-primary btn-sm" i18n>POST demo data</button>
        <hr>

        <h5 i18n>Try sending event data</h5>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>{{sampleEventData | json }}</pre>
          </code>
        </div>
        <button (click)="testEvent()" type="button" class="btn btn-outline-primary btn-sm" i18n>POST demo event</button>
        <hr>

        <h5 i18n>Automate datasource metadata mapping</h5>
        <p i18n>You can map metadata manually or use the /data/meta API to automate the process</p>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>{{sampleMetadata | json }}</pre>
          </code>
        </div>
        <button (click)="testMapping()" type="button" class="btn btn-outline-primary btn-sm" i18n>POST demo metadata</button>

        <hr>
        <p i18n>If you have any problems, please contact Customer Support for further assistance <a href="{{contactUrl}}">{{contactLabel}}</a>.</p>
      </div>
      <div class="col-12 text-center">
        <button type="button" class="btn btn-primary btn" routerLink="/mapping/source-grid" i18n>Start mapping</button>
      </div>
    </div>
  </div>
</div>
