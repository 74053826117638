<ng-template #navMenu>
  <nav class="header__nav">
    <ul class="header__nav-list">
      <li class="header__nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}" >
        <a routerLink="/authorizations/tenant" i18n class="header__nav-item-link">My tenant</a>
      </li>
      <li class="header__nav-item" routerLinkActive="active" [routerLinkActiveOptions]="{exact: false}">
        <a routerLink="/authorizations/token" i18n class="header__nav-item-link">Api keys</a>
      </li>
    </ul>
  </nav>
</ng-template>
<router-outlet></router-outlet>



