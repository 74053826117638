import { Injectable } from '@angular/core';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {debounceTime, startWith, tap} from 'rxjs/operators';

@Injectable()
export class TokenPageService {

  public searchForm: UntypedFormGroup = new UntypedFormGroup({
    searchType: new UntypedFormControl(null)
  });

  public search$ = this.searchForm.valueChanges.pipe(
    debounceTime(250),
    startWith(this.searchForm.getRawValue())
  );

  constructor() {
  }

}
