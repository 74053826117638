import {AbstractControl, UntypedFormControl} from '@angular/forms';
import {Injectable} from '@angular/core';
import {PersonCodeValidator} from '../../person-code-validator';
import {environment as config} from '../../../../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PersonCodeControlValidator {

  constructor() {
  }

  check(control: AbstractControl): any {

    const input = <UntypedFormControl>control;
    if (!input.value) {
      return;
    }

    let value;
    let cc;
    if (typeof(input.value) === 'string') {
        value = input.value;
        cc = config.defaultCountryCode;
    } else {
        value = input.value.registrationNumber;
        cc = input.value.countryCode;
    }

    if (PersonCodeValidator.validate(value, cc)) {
      return null;
    }

    return {
      personRegistrationNumber: true
    };

  }
}
