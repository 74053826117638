import { Injectable } from '@angular/core';
import {Tenant, TenantPage} from '../model/tenant';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class TenantService {

  constructor (private http: HttpClient) {
  }

  public getTenantsPage (filter: any): Observable<TenantPage> {
    const params: any = {
      page: filter.page - 1,
      size: filter.size
    };
    if (filter.keyword) {
      params.keyword = filter.keyword;
    }

    return this.http.get<TenantPage>(environment.apiUrl + '/tenant', {params: params});
  }

  getTenant (id: number) {
    return this.http.get<Tenant>(environment.apiUrl + '/tenant/' + id);
  }

  saveTenant (tenant: Tenant) {
    if (tenant.id) {
      return this.http.put<Tenant>(environment.apiUrl + '/tenant/' + tenant.id, tenant);
    } else {
      return this.http.post<Tenant>(environment.apiUrl + '/tenant', tenant);
    }
  }

  deleteTenant (id: number) {
    return this.http.delete(environment.apiUrl + '/tenant/' + id);
  }
}
