<ng-template #navActions>
  <button type="button" class="btn btn-sm btn-primary d-none d-lg-inline-block" routerLink="edit" *ngxPermissionsOnly="['EDIT_MANDATES','OWNER']" [routerLink]="['/','mandate','edit']">
    <i class="fal fa-plus"></i>&nbsp;<span i18n>Create new</span>
  </button>

  <div class="btn-floating-mobile-container">
    <button type="button" class="btn btn-sm btn-primary btn-floating-mobile" routerLink="edit" *ngxPermissionsOnly="['EDIT_MANDATES','OWNER']" [routerLink]="['/','mandate','edit']">
      <i class="fal fa-plus fa-2x"></i>
    </button>
  </div>
</ng-template>

<div class="container-fluid p-4">
  <table class="table table-striped btn-table table-sm table-mapping">
    <thead class="">
    <tr>
      <th i18n>Person</th>
      <th></th>
    </tr>
    </thead>
    <tbody *ngIf="mandates">
    <tr *ngFor="let item of mandates">
      <td>{{item.user.displayName}} ({{item.user | personalId}})</td>
      <td class="text-right">
        <ng-container *ngxPermissionsOnly="['EDIT_MANDATES','OWNER']">
          <div class="btn-group btn-more" dropdown *ngIf="item.type == 'MANDATE'">
            <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
            <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
              <a class="dropdown-item" [routerLink]="['edit', {personalId: item.user.personalId, countryCode: item.user.countryCode}]"><i class="fal fa-edit"></i> <ng-container i18n>Edit</ng-container></a>
              <a class="dropdown-item" (click)="deleteMandate(item)"><i class="fal fa-trash"></i> <ng-container i18n>Delete</ng-container></a>
            </div>
          </div>
        </ng-container>
      </td>
    </tr>
    </tbody>
  </table>
</div>
