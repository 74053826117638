import { Injectable } from '@angular/core';
import {CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router} from '@angular/router';
import { Observable } from 'rxjs';
import {TokenAuthService} from '../auth/token-auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticatedGuard implements CanActivate {

  constructor(private authService: TokenAuthService, private router: Router) {
  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return new Promise((resolve, reject) => {
      this.authService.getLoginState().then((authenticated) => {
        if (authenticated) {
          this.router.navigateByUrl("/");
          resolve(false);
          return;
        }

        resolve(true);
      }).catch((reason) => {
        reject(reason);
      });

    });

  }

}
