import {IdpAuthService} from './idp-auth.service';
import {TokenAuthService} from './token-auth.service';
import {IdpCode} from './idp-code';
import {TaraAuthService} from './tara/tara-auth.service';
import {NemLogInAuthService} from './nem-log-in/nem-log-in-auth.service';
import {DemoAuthService} from './demo/demo-auth.service';
import {IdpService} from './idp.service';

export class IdpAuthSwitchService extends IdpAuthService {

  private currentAuthService: IdpAuthService;

  private currentIdp: IdpCode;

  private defaultIdp: IdpCode;

  constructor(tokenAuthService: TokenAuthService,
              private idpService: IdpService,
              private taraAuthService: TaraAuthService,
              private nemLogInAuthService: NemLogInAuthService,
              private demoAuthService: DemoAuthService) {
    super(tokenAuthService);
    this.switchTo(idpService.getActiveIdp());
    this.tokenAuthService.logout$.subscribe((result: { token: string }) => {
      this.logout({
        token: result.token
      });
    });
  }

  getIdp(): IdpCode {
    return this.currentIdp;
  }

  reset(): void {
    this.idpService.reset();
    this.switchTo(this.idpService.getActiveIdp());
  }

  switchTo(idp: IdpCode): void {
    this.currentIdp = idp;
    switch (idp) {
      case IdpCode.TARA:
        this.currentAuthService = this.taraAuthService;
        break;
      case IdpCode.NEM_ID:
      case IdpCode.NEM_LOG_IN:
        this.currentAuthService = this.nemLogInAuthService;
        break;
      case IdpCode.DEMO:
        this.currentAuthService = this.demoAuthService;
        break;
    }
  }


  authenticationCallback(params?: any): void {
    this.currentAuthService.authenticationCallback(params);
  }

  doLogout(params?: any): Promise<boolean> {
    return this.currentAuthService.doLogout(params);
  }

  requestAuthentication(params?: any): void {
    this.currentAuthService.requestAuthentication(params);
  }

}
