import {UntypedFormGroup} from '@angular/forms';
import {BehaviorSubject} from 'rxjs/internal/BehaviorSubject';
import {ManagedObject} from './managed-object';
import {ObjectFlatNode} from './object-flat-node';
import {NodeType} from './node-type';

/** Nested node */
export class ObjectNode {

  childrenChange = new BehaviorSubject<ObjectNode[]>([]);
  control: UntypedFormGroup;

  get children(): ObjectNode[] {
    return this.childrenChange.value;
  }

  constructor(
    public id: string,
    public type: NodeType,
    public name: string,
    public managedObject: ManagedObject | null,
    public sourcePath: string | null,
    public hasChildren = false,
    public parentItem: string | null = null,
    public disabled: boolean = false,
    public source: ObjectFlatNode | null = null) {
  }
}
