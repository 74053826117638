import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {TenantService} from '../../../core/http/tenant.service';
import {Tenant, TenantPage} from '../../../core/model/tenant';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import { BsModalService } from 'ngx-bootstrap/modal';
import { FeedbackModalComponent } from '../feedback-modal/feedback-modal.component';

const selector = 'mydata-series-bulk-edit-modal';
let nextId = 0;

@Component({
  selector: 'app-tenant-setup-component',
  templateUrl: './tenant-setup.component.html'
})
export class TenantSetupComponent implements OnInit {
  id = `${selector}-${nextId++}`;

  public tenantConnectError = false;
  public tenantsPage: TenantPage;
  public tenant: Tenant = null;
  public tenantForm: UntypedFormGroup;

  public pageFilter: any = {
    page: 1,
    size: 2000
  };

  public tenantsLoaded = false;

  @Output()
  public continue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    private tenantService: TenantService,
    private modalService: BsModalService
  ) {
  }

  ngOnInit() {
    this.tenantForm = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      url: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('', Validators.required)
    });

    this.loadPage();
  }

  loadPage() {
    this.tenantService.getTenantsPage(this.pageFilter).subscribe(
      (data: TenantPage) => {
        this.tenantsPage = data;
        this.tenant = data.content[0];
        if (this.tenant) {
          this.reset(this.tenant);
          this.tenantForm.disable();
        }
        this.tenantsLoaded = true;
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  reset(tenant: Tenant): void {
    this.tenant = tenant;
    this.tenantForm.reset(tenant);
  }

  next() {
    if (!this.tenant) {
      const value = this.tenantForm.value;
      if (this.tenant) {
        value.id = this.tenant.id;
      }
      this.tenantService.saveTenant(value).subscribe(
        (tenant: any) => {
          this.continue.next(tenant);
        },
        (err: any) => {
          this.tenantConnectError = true;
        }
      );
    } else {
      this.continue.next(this.tenant);
    }
  }

  openFeedbackModal() {
    this.modalService.show(FeedbackModalComponent, {
      ignoreBackdropClick: true
    });
  }
}
