import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from '@angular/router';
import {PersonService} from '../../../../core/services/person.service';
import {ToastrService} from 'ngx-toastr';
import {Person, Token} from '../../../../core/model/person';
import {TokenAuthService} from '../../../../core/auth/token-auth.service';
import {Profile} from '../../../../core/model/profile';

@Component({
  selector: 'app-token-accept',
  templateUrl: './token-accept.component.html'
})
export class TokenAcceptComponent implements OnInit {

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private personService: PersonService,
    public toastr: ToastrService,
    private authService: TokenAuthService
  ) { }

  ngOnInit(): void {
    this.route.paramMap.subscribe((params: ParamMap) => {
      const acceptTokenUuid = params.get("acceptTokenUuid");
      if (acceptTokenUuid != null) {
        const profile: Profile = this.authService.profileData;
        let person: Person = null;
        if (profile.availablePersons.length > 0) {
          person = profile.availablePersons[0];
        }

        if (person) {
          this.authService.switchToRole(person).then(() => {
            this.acceptToken(acceptTokenUuid);
          }, (err) => {
            console.error(err);
            this.toastr.error($localize`Error occurred when accepting API key`, null, {
              disableTimeOut: 'timeOut'
            });
            this.router.navigate(['/mapping/setup']);
          });
        } else {
          this.acceptToken(acceptTokenUuid);
        }
      }

    });
  }

  private acceptToken(acceptTokenUuid: string): void {
    this.personService.acceptToken(acceptTokenUuid).subscribe((token: Token) => {
      this.toastr.success($localize`API key ${token.value} successfully accepted`);
      this.router.navigate(['/authorizations/token']);
    }, (err) => {
      console.error(err);
      if (err.error?.errorCode === 'TOKEN_ALREADY_ACCEPTED') {
        this.toastr.error($localize`API key already accepted`, null, {
          disableTimeOut: 'timeOut'
        });
      } else if (err.error?.errorCode === 'TOKEN_NOT_EXISTS') {
        this.toastr.error($localize`API key does not exist`, null, {
          disableTimeOut: 'timeOut'
        });
      } else {
        this.toastr.error($localize`Error occurred when accepting API key`, null, {
          disableTimeOut: 'timeOut'
        });
      }

      this.router.navigate(['/mapping/setup']);
    });
  }

}
