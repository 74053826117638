import { Injectable } from '@angular/core';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {LoadmoreDatabase} from '../components/tenant/browser/loadmore-database';
import {Tenant} from '../../core/model/tenant';
import {SourceAsset} from '../models/series';
import {TreeControlUtil} from '../utils/tree-control-util';
import {ObjectFlatNode} from '../models/tenant-mapping/object-flat-node';
import {ObjectNode} from '../models/tenant-mapping/object-node';
import {NodeType} from '../models/tenant-mapping/node-type';
import {SearchParams} from '../models/tenant-mapping/search-params';
import {SelectedSourcesService} from '../components/tenant/browser/selected-sources.service';

@Injectable()
export class TenantBrowserService {

  flatNodeMap: Map<string, ObjectFlatNode>;

  treeControl: FlatTreeControl<ObjectFlatNode>;

  treeFlattener: MatTreeFlattener<ObjectNode, ObjectFlatNode>;

  dataSource: MatTreeFlatDataSource<ObjectNode, ObjectFlatNode>;

  checklistSelection = new SelectionModel<ObjectFlatNode>(true /* multiple */);

  selectedTable = false;

  treeControlUtil: TreeControlUtil;

  constructor(private database: LoadmoreDatabase,
              private selectedSourcesService: SelectedSourcesService) {
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.flatNodeMap = new Map<string, ObjectFlatNode>();
    this.treeControl = new FlatTreeControl<ObjectFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.database.dataChange.subscribe(data => {
      this.dataSource.data = data;
    });
    this.treeControlUtil = new TreeControlUtil(this.treeControl);
  }

  getFlatNodeMap(): Map<string, ObjectFlatNode> {
    return this.flatNodeMap;
  }

  getData(): ObjectNode[] {
    return this.database.data;
  }

  getChildren = (node: ObjectNode): ObjectNode[] => {
    return node.children;
  };

  transformer = (node: ObjectNode, level: number) => {

    const existingNode = this.flatNodeMap.get(node.id);

    if (existingNode) {
      return existingNode;
    }

    const newNode = new ObjectFlatNode(
      node.id,
      node.type,
      node.name,
      node.managedObject,
      node.sourcePath,
      level,
      node.hasChildren,
      node.parentItem,
      node.disabled);

    this.flatNodeMap.set(node.id, newNode);

    if (this.selectedSourcesService.selectedSeriesCache.has(node.id)) {
      this.checklistSelection.select(newNode);
    }

    return newNode;
  };

  getLevel = (node: ObjectFlatNode) => node.level;

  isExpandable = (node: ObjectFlatNode) => {
    return node.expandable;
  };

  hasChild = (_: number, _nodeData: ObjectFlatNode) => {
    return _nodeData.expandable;
  };

  isSeries = (_: number, _nodeData: ObjectFlatNode) => {
    return _nodeData.type == NodeType.TENANT_MEASUREMENT;
  };

  isEvent = (_: number, _nodeData: ObjectFlatNode) => {
    return _nodeData.type == NodeType.TENANT_EVENT;
  };

  isLoadMore = (_: number, _nodeData: ObjectFlatNode) => {
    return _nodeData.name === 'LOAD_MORE';
  };

  loadMore(node: ObjectFlatNode, onlyWithMeasurements = false) {
    node.isLoading = true;
    this.database.loadMore({
      itemId: node.id,
      onlyWithMeasurements: onlyWithMeasurements,
      onlyFirstTime: false
    }, () => {
      console.log('FALSE 2');
      node.isLoading = false
    });
  }

  loadChildren(node: ObjectFlatNode, onlyWithMeasurements = false) {
    if (!this.treeControl.isExpanded(node)) {
      node.isLoading = false;
      return;
    }
    node.isLoading = true;
    this.database.loadMore({
      itemId: node.id,
      onlyWithMeasurements: onlyWithMeasurements,
      onlyFirstTime: false
    }, () => {
      node.isLoading = false;
    });
  }

  public deselectSourceNodeById(id: string): void {
    const flatNode = this.flatNodeMap.get(id);
    if (flatNode) {
      const parentNode = this.treeControlUtil.getParent(flatNode);
      const result = parentNode ? parentNode : flatNode;
      this.deselectAllDescendents(result);
    }
  }

  public selectNodeById(id: string): void {
    const flatNode = this.flatNodeMap.get(id);
    if (flatNode) {
      this.checklistSelection.select(flatNode);
    }
  }

  public deselectNodeById(id: string): void {
    const flatNode = this.flatNodeMap.get(id);
    if (flatNode) {
      this.checklistSelection.deselect(flatNode);
    }
  }

  public isSelected(node: ObjectFlatNode): boolean {

    return this.checklistSelection.isSelected(node);
  }

  public isChecked(node: ObjectFlatNode): boolean {

    return this.checklistSelection.isSelected(node) || node.disabled;
  }

  public toggleSelectedNode(node: ObjectFlatNode): void {
    this.checklistSelection.toggle(node);
  }

  searchManagedObject(tenant: Tenant, params: SearchParams, cb): void {
    this.flatNodeMap = new Map<string, ObjectFlatNode>();
    this.database.initialize(tenant.id, {
      text: params.text,
      query: params.query
    }, cb);
  }

  getAssetsHierarchy(node: ObjectFlatNode): SourceAsset[] {
    return this.treeControlUtil.getAssetsHierarchy(node).sort((a: SourceAsset, b: SourceAsset) => {
      if (a.level < b.level) {
        return -1;
      }
      if (a.level > b.level) {
        return 1;
      }
      return 0;
    });
  }

  private deselectAllDescendents(parentNode: ObjectFlatNode): void {
    const descendants = this.treeControl.getDescendants(parentNode);
    if (descendants.length == 0) {
      return;
    }

    descendants.forEach((childNode) => {
      this.checklistSelection.deselect(childNode);
      this.deselectAllDescendents(childNode);
    });
  }

}
