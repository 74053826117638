<ng-container [formGroup]="form">
  <td colspan="2">
<!--    <ul class="nav nav-links float-left">-->
<!--      <li class="nav-item">-->
<!--        <a (click)="selectAll()" class="nav-link" i18n>Select all</a>-->
<!--      </li>-->
<!--      <li class="nav-item separator">-->
<!--        <span></span>-->
<!--      </li>-->
<!--      <li class="nav-item">-->
<!--        <a (click)="deselectAll()" class="nav-link" i18n>Deselect all</a>-->
<!--      </li>-->
<!--    </ul>-->


  </td>
  <td class="text-right">Valitud andmed</td>
  <td class="editable">
    <input formControlName="name" type="text" class="form-control" placeholder="Add value" i18n-placeholder>
  </td>
  <td class="editable">
    <input type="text" class="form-control" [class.is-invalid]="invalid('groupingTag')"
           formControlName="groupingTag" autocomplete="off"
           [typeahead]="groupingTags" [typeaheadMinLength]="0" core-uppercase
    >
  </td>
  <td class="editable">
    <select formControlName="seriesType" class="browser-default custom-select form-control">
      <option [ngValue]="null" i18n>-Select-</option>
      <option *ngFor="let seriesType of seriesTypes" [ngValue]="seriesType">{{seriesType}}</option>
    </select>
  </td>
  <td class="editable">
    <ng-template #personTempalte let-model="item" let-index="index">
      {{model.registrationNumber}} ({{model.displayName}})
    </ng-template>
    <input formControlName="personRegistrationNumber" type="text" class="form-control"
           placeholder="Add value" i18n-placeholder
           [typeahead]="personTypeahead$"
           [typeaheadAsync]="true"
           typeaheadWaitMs="250"
           [typeaheadMinLength]="4"
           [typeaheadItemTemplate]="personTempalte"
           typeaheadOptionField="registrationNumber"
           (typeaheadOnSelect)="typeaheadOnSelect($event)"
           [attr.disabled]="mappedStatus ? true : null"
    >
  </td>
  <td class="editable position-relative">
    <div class="location text-nowrap">
      <div *ngFor="let type of locationTypes" class="custom-control custom-radio custom-control-inline">
        <input formControlName="locationType"
               type="radio" [value]="type"
               class="custom-control-input"
               id="lt_{{type}}">
        <label class="custom-control-label" for="lt_{{type}}">{{type}}</label>
      </div>
    </div>
    <div class="location position-relative">
      <ng-container *ngIf="form.get('locationType').value == 'UUID'">
        <input formControlName="uuid"
               type="text"
               class="form-control mt-1"
               placeholder="UUID" i18n-placeholder>
      </ng-container>
      <ng-container *ngIf="form.get('locationType').value == 'COORDS'">
        <input formControlName="lat"
               type="text"
               class="form-control mt-1"
               placeholder="Lat" i18n-placeholder>
        <input formControlName="lng"
               type="text"
               class="form-control mt-1"
               placeholder="Lng" i18n-placeholder>
      </ng-container>
      <ng-container *ngIf="form.get('locationType').value == 'ADDRESS'" [ngSwitch]="addressControlType">
        <app-address-ads *ngSwitchCase="'inads'" [form]="form" [submitted]="false"></app-address-ads>
        <app-address-dawa *ngSwitchCase="'dawa'" [form]="form" [submitted]="false"></app-address-dawa>
        <app-address-input *ngSwitchDefault [form]="form" [submitted]="false"></app-address-input>
      </ng-container>
    </div>
  </td>
  <td class="text-center"></td>
</ng-container>
