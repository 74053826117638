<div class="modal-header">
  <button type="button" class="close pull-right" aria-label="Close" (click)="close()">
    <span aria-hidden="true">×</span>
  </button>
  <h4 class="modal-title w-100" i18n>Confirm data</h4>
</div>
<div class="modal-body">
  <p class="text-center text-primary px-5 pb-5 border-bottom" i18n>These data connections are forwarded to the designated owner for approval. Please check the correctness of the data and enter a comment about the content of the data connection.</p>
  <ng-container *ngFor="let personMapping of mappedMappings">
    <h3 class="mt-5"><ng-container i18n>Owner registry code</ng-container>: {{personMapping.personRegistrationNumber}}</h3>
    <ul>
      <li *ngFor="let source of personMapping.values">
        {{source.name}},
        <ng-container *ngIf="source.locationType == 'UUID'">
          {{source.uuid}}
        </ng-container>
        <ng-container *ngIf="source.locationType == 'COORDS'">
          {{source.lat}}&nbsp;&nbsp;{{source.lng}}
        </ng-container>
        <ng-container *ngIf="source.locationType == 'ADDRESS'">
          {{source.address}} {{source.apartment}} {{source.room}}
        </ng-container>
      </li>
    </ul>
  </ng-container>

  <div [formGroup]="formGroup">

    <div class="form-group">
      <div class="checkbox">
        <input type="checkbox" formControlName="startAtUnmapped">
        <label i18n>Include historical unmaped data</label>
      </div>
    </div>

    <div *ngIf="submitted &&  formGroup.get('comment').invalid" class="alert alert-danger mt-5" role="alert" i18n>Comment is mandatory</div>

    <div [formGroup]="formGroup" class="form-group">
      <label><b i18n>Comment</b></label>
      <div class="form-group">
        <textarea formControlName="comment"
                  class="form-control rounded-0"
                  rows="3"
                  placeholder="Comment on the content of the data connection." i18n-placeholder></textarea>
      </div>
    </div>

    </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-primary btn-sm" aria-label="Close" i18n-aria-label
          (click)="close()" i18n>Cancel</button>
  <button (click)="confirm()" type="button" class="btn btn-primary btn-sm" [ladda]="isLoading" i18n>Send for approval</button>
</div>
