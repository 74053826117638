import {ObjectFlatNode} from './tenant-mapping/object-flat-node';

export class SeriesSourceTenantImportRequest {
  mappings: TenantMappingDto[] = [];
}

export class TenantMappingDto {
  metaFields: MetaFieldsDto;
  source: TenantSourceDto;

  constructor(metaFields: MetaFieldsDto, source: TenantSourceDto) {
    this.metaFields = metaFields;
    this.source = source;
  }
}

export class TenantSourceDto {
  tenantId: string;
  objectCreationData: Date;
  objectId: string;
  sourceType: string;
  sourcePath: string;

  constructor(tenantId: string, objectId: string, objectCreationDate: Date, sourceType: string, sourcePath: string) {
    this.tenantId = tenantId;
    this.objectId = objectId;
    this.objectCreationData = objectCreationDate;
    this.sourceType = sourceType;
    this.sourcePath = sourcePath;
  }
}

export class MetaFieldsDto {
  name: string;
  lat: number;
  lng: number;
  sourceAssetsTree: SourceAssetsFlatTree;

  constructor(props?: any) {
    if (!props) {
      return;
    }
    if (props.name) {
      this.name = props.name;
    }
    if (props.lat) {
      this.lat = props.lat;
    }
    if (props.lng) {
      this.lng = props.lng;
    }
    if (props.sourceAssetsTree) {
      this.sourceAssetsTree = props.sourceAssetsTree;
    }
  }
}

export class SourceAssetsFlatTree {
  assets: SourceAsset[] = [];
  constructor(props?: any) {
    if (!props) {
      return;
    }
    if (props.assets && props.assets.length > 0) {
      this.assets = props.assets;
    }
  }
}

export class SourceAsset {
  id: number;
  type: string;
  name: string;
  level: number;
  constructor(props?: any) {
    if (!props) {
      return;
    }

    this.id = props.id;
    this.type = props.type;
    this.name = props.name;
    this.level = props.level;
  }
}

export class SourceAssetMapper {

  public static objectFlatNodeToSourceAsset(node: ObjectFlatNode): SourceAsset {
    let assetType: string = 'Unknown';
    if (node.managedObject.c8y_IsDeviceGroup) {
      assetType = 'c8y_IsDeviceGroup';
    }
    if (node.managedObject.c8y_IsDevice) {
      assetType = 'c8y_IsDevice';
    }

    return new SourceAsset({
      id: node.managedObject.id,
      name: node.name,
      type: assetType,
      level: node.level
    });
  }

}


export class SeriesType {
  public static types = [];
}

export class GroupingTags {
  public static tags = [
    'STREET_LIGHTS',
    'BUSES',
    'BUILDINGS',
    'TRAFFIC_LIGHTS',
    'CELL_TOWERS',
    'SOLAR_PANELS',
    'WIND_TURBINES',
    'WEV_CHARGERS',
    'BICYCLES',
    'WEATHER',
    'PEOPLE',
    'DATA'
  ];
}

export class LocationTypes {
  public static types = [
    'ADDRESS',
    'UUID',
    'COORDS'
  ];
}

export class StatusTypes {
  public static types = [];
}
