<div style="position: relative">
  <input [formControl]="adsAddress"
         type="text"
         class="form-control mt-1"
         placeholder="Address"
         i18n-placeholder
         [ngbTypeahead]="addressSearch"
         [inputFormatter]="inadsInputFormatter"
         [resultFormatter]="inadsResultFormatter"
  >
  <mat-spinner [diameter]="24" style="position: absolute; right: 4px;top: 4px;" *ngIf="searching"></mat-spinner>
</div>
<b *ngIf="submitted && form.get('address').invalid" class="text-danger">*</b>
<input [formControl]="adsApartment"
       type="text" class="form-control mt-1"
       placeholder="Apartment" i18n-placeholder
       [ngbTypeahead]="apartmentSearch"
>
<input [formControl]="form.get('room')"
       type="text" class="form-control mt-1"
       placeholder="Room" i18n-placeholder>
