import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {SourceMapping, SourcePage} from '../models/source';
import {SeriesSourceTenantImportRequest} from '../models/series';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class SourceService {
  constructor(private http: HttpClient) {}

  private mappings: Map<number, SourceMapping> = new Map<number, SourceMapping>();

  getSources(filter: any): Observable<SourcePage> {

    return this.http.get<SourcePage>(environment.apiUrl + '/source', {
      params: this.prepareFilterParams(filter)
    });
  }

  countPendingSources(filter: any): Observable<any> {

    return this.http.get<SourcePage>(environment.apiUrl + '/source/pending/count', {
      params: this.prepareFilterParams(filter)
    });
  }

  deleteSource(id: number): Observable<any> {
    return this.http.delete(environment.apiUrl + '/source/' + id, {params: {}});
  }

  bulkDeleteSources(filter: any): Observable<any> {

    return this.http.delete(environment.apiUrl + '/source', {
      params: this.prepareFilterParams(filter)
    });
  }

  createSourceSeries(mappings: SourceMapping[], startAtUnmapped: boolean, comment: string): Observable<any> {
    for (const mapping of mappings) {
      if (startAtUnmapped) {
        mapping.periodStartType = 'UNMAPPED_TIME';
      } else {
        mapping.periodStartType = 'ACCEPT_TIME';
      }
      mapping.assignerComment = comment;
    }

    const data: any = {
      mappings: this.mapToPostData(mappings)
    };

    return this.http.post(environment.apiUrl + '/person-series/source-mapping', data);
  }

  bulkConfirmMappings(filter: any, mapped: boolean, startAtUnmapped: boolean, comment: string): Observable<any> {
    const request = {
      periodStartType: (startAtUnmapped ? 'UNMAPPED_TIME' : 'ACCEPT_TIME'),
      assignerComment: comment,
      mapped: mapped
    }

    return this.http.patch(environment.apiUrl + '/person-series/source-mapping', request, {
      params: this.prepareFilterParams(filter)
    });
  }

  cancelConfirmedMappings(mappings: SourceMapping[], comment: string): Observable<any> {
    for (const mapping of mappings) {
      mapping.assignerComment = comment;
    }

    const data: any = {
      sources: this.mapToCancelConfirmationData(mappings),
      cancelComment: comment
    };

    return this.http.post(environment.apiUrl + '/person-series/person-series-cancel', data);
  }

  cancelSourceSeries(mappings: SourceMapping[], comment: string): Observable<any> {
    for (const mapping of mappings) {
      mapping.assignerComment = comment;
    }

    const data: any = {
      mappings: this.mapToPutCancellationData(mappings)
    };

    return this.http.put(environment.apiUrl + '/person-series/source-mapping/cancel', data);
  }

  bulkCancelPendingMappings(filter: any, comment: string): Observable<any> {
    return this.http.patch(environment.apiUrl + '/person-series/source-mapping/cancel', {
      assignerComment: comment
    }, {
      params: this.prepareFilterParams(filter)
    });
  }

  importSourcesFromTenant(data: SeriesSourceTenantImportRequest): Observable<any> {
    return this.http.post(environment.apiUrl + '/source/meta/tenant', data);
  }

  saveSource(mapping: SourceMapping) {
    this.mappings.set(mapping.id, mapping);
    return this.saveMappings();
  }

  saveSources(mappings: SourceMapping[]) {
    for (const mapping of mappings) {
      this.mappings.set(mapping.id, mapping);
    }
    return this.saveMappings();
  }

  saveMappings() {
    const mappings = [...this.mappings.values()];
    this.mappings.clear();
    return this.updateSourcesMappingsBulk(mappings);
  }

  patchUpdateSourcesMappings(filter: any, data: any) {
    return this.http.patch(environment.apiUrl + '/source/meta', data, {
      params: this.prepareFilterParams(filter)
    });
  }

  updateSourcesMappingsBulk(mappings: SourceMapping[]) {
    const data = {
      mappings: this.mapToPostData(mappings)
    };
    return this.http.put(environment.apiUrl + '/source/meta', data);
  }

  private mapToPostData(mappings: SourceMapping[]): any[] {
    return mappings.map((value: SourceMapping) => ({
      assignerComment: value.assignerComment,
      id: value.id,
      metaFields: {
        adsOid: value.adsOid,
        address: value.address ? value.address.trim() : value.address,
        apartment: value.apartment ? value.apartment.trim() : value.apartment,
        groupingTag: value.groupingTag ? value.groupingTag.trim().toUpperCase() : value.groupingTag,
        lat: value.lat,
        lng: value.lng,
        locationType: value.locationType ? value.locationType.trim() : value.locationType,
        name: value.name ? value.name.trim() : value.name,
        personCountryCode: value.personCountryCode ? value.personCountryCode : environment.defaultCountryCode,
        personRegistrationNumber: value.personRegistrationNumber ? value.personRegistrationNumber.trim() : value.personRegistrationNumber,
        room: value.room ? value.room.trim() : value.room,
        seriesType: value.seriesType ? value.seriesType.trim() : value.seriesType,
        uuid: value.uuid ? value.uuid.trim() : value.uuid
      },
      periodStartType: value.periodStartType
    }));
  }

  private mapToPutCancellationData(mappings: SourceMapping[]): any[] {
    return mappings.map((value: SourceMapping) => ({
      assignerComment: value.assignerComment,
      id: value.id,
      personRegistrationNumber: value.personRegistrationNumber ? value.personRegistrationNumber.trim() : value.personRegistrationNumber,
    }));
  }

  private mapToCancelConfirmationData(mappings: SourceMapping[]): any[] {
    return mappings.map((value: SourceMapping) => ({
      id: value.id,
      personRegistrationNumber: value.personRegistrationNumber ? value.personRegistrationNumber.trim() : value.personRegistrationNumber,
      personCountryCode: value.personCountryCode ? value.personCountryCode : environment.defaultCountryCode,
      name: value.name ? value.name.trim() : value.name,
    }));
  }

  private prepareFilterParams(filter: any): any {
    const params: any = {};

    // No pagination, requests all rows
    // const params: any = {
    //   page: filter.page - 1,
    //   size: filter.size
    // };

    if (filter.notMapped) {
      params.notMapped = filter.notMapped;
    }
    if (filter.mapped) {
      params.mapped = filter.mapped;
    }
    if (filter.source) {
      params.source = filter.source;
    }
    if (filter.tokenIds && filter.tokenIds.length) {
      params.tokenIds = filter.tokenIds;
    }
    if (filter.name) {
      params.name = filter.name;
    }
    if (filter.groupingTag) {
      params.groupingTag = filter.groupingTag;
    }
    if (filter.seriesType) {
      params.seriesType = filter.seriesType;
    }
    if (filter.personCountryCode) {
      params.personCountryCode = filter.personCountryCode;
    }
    if (filter.personRegistrationNumber) {
      params.personRegistrationNumber = filter.personRegistrationNumber;
    }
    if (filter.locationType) {
      params.locationType = filter.locationType;
    }
    if (filter.confirmationStatus) {
      params.confirmationStatus = filter.confirmationStatus;
    }
    if (filter.location) {
      params.location = filter.location;
    }
    /*if (filter.sort && filter.order) {
      params.sort = filter.sort + ',' + filter.order;
    }*/

    if (filter.sort) {
      params.sort = filter.sort;
    }

    return params;
  }

}
