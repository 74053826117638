import {Router, ActivatedRoute} from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormGroup, FormBuilder, ValidationErrors, UntypedFormControl, Validators, UntypedFormArray} from '@angular/forms';
import {UserService} from '../../../../core/http/user.service';
import {Mandate} from '../../../../core/model/profile';
import {MandateUser} from '../../../../core/model/profile';
import {EventEmitter} from '@angular/core';
import {debounceTime, filter, switchMap, takeUntil} from 'rxjs/operators';
import {ChangeDetectorRef} from '@angular/core';
import {MandatePermission} from '../../../../core/model/profile';
import {ToastrService} from 'ngx-toastr';
import {environment} from '../../../../../environments/environment';
import {EnumsService} from '../../../../core/services/enums.service';
import {Subject} from 'rxjs/internal/Subject';

const selector = 'app-mandate-edit';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './mandate-edit.component.html'
})
export class MandateEditComponent implements OnInit, OnDestroy {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public config = environment;
  public progress = false;

  public mandateForm: UntypedFormGroup;
  public showErrors = false;

  public mandate;
  public users = [];
  public userTypeahead = new EventEmitter<string>();
  public permissionsList: any[] = [];

  public errorCodes: any = {
    'Select user': 'Fill selections',
    'Target user is the owner': 'Not allowed selection'
  };
  public errorCode: string = null;

  public createUser = (personalId: string) => {
    return {
      countryCode: environment.defaultCountryCode,
      personalId: personalId,
      displayName: null
    };
  };

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private userService: UserService,
    public toastr: ToastrService,
    private enumService: EnumsService,
    private cd: ChangeDetectorRef
  ) {
    this.mandateForm = new UntypedFormGroup({
      user: new UntypedFormControl(null, Validators.required),
      permissions: new UntypedFormArray([])
    });

    this.initUserTypeahead();
    this.permissionsList = this.enumService.getOptionsList(['EDIT_MANDATES']);

    this.listenRouteParams();
  }

  ngOnInit() { }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  control(name?: string) {
    if (!name) {
      return this.mandateForm;
    }
    return this.mandateForm.get(name);
  }

  invalid(name: string, formGroup?) {
    if (!formGroup) {
      formGroup = this.mandateForm;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this.mandateForm.get(name);
    return control ? control.errors : null;
  }

  listenRouteParams(): void {
    this.route.params.subscribe(
      (params: any) => {
        if (params.countryCode && params.personalId) {
          this.userService.getMandate(params.countryCode, params.personalId).subscribe(
            (mandate: Mandate) => {
              this.reset(mandate);
            },
            (sErr: any) => {
              this.toastr.error($localize`Error on receiving mandate!`);
            }
          );
        } else {
          const mandate = new Mandate();
          mandate.person = null;
          mandate.user = null;
          mandate.permissions = [];
          this.reset(mandate);
        }
      });
  }

  private reset(mandate: Mandate) {
    this.mandate = mandate;

    const formMandate: any = {};
    formMandate.user = mandate.user;
    this.mandateForm.reset(formMandate);

    const controls = this.permissionsList.map(permission => {
      let value = false;
      for (let i = 0; i < mandate.permissions.length; i++) {
        if (mandate.permissions[i].key == permission.value) {
          value = mandate.permissions[i].value;
        }
      }
      return value;
    });
    const permissionsArray = new UntypedFormArray(controls.map(value => new UntypedFormControl(value)));
    this.mandateForm.setControl('permissions', permissionsArray);
  }

  saveMandate () {
    this.mandateForm.markAllAsTouched();
    this.mandateForm.updateValueAndValidity();

    if (this.mandateForm.invalid) {
      this.showErrors = true;
      return;
    }

    this.progress = true;

    const mandate = new Mandate();
    const formValue = this.mandateForm.value;
    const user = formValue.user;
    if (!user) {
      this.errorCode = 'Fill selections';
      return;
    }
    mandate.user = new MandateUser();
    mandate.user.personalId = user.personalId;
    mandate.user.countryCode = user.countryCode;
    mandate.permissions = [];
    for (let i = 0; i < formValue.permissions.length; i++) {
      mandate.permissions.push(new MandatePermission(this.permissionsList[i].value, formValue.permissions[i]));
    }

    this.userService.saveMandate(mandate).pipe(takeUntil(this.ngDestroy)).subscribe(
      (mandate: any) => {
        this.progress = false;
        this.mandate = mandate;
        this.toastr.success($localize`Saved!`);
        this.router.navigate(['/mandate']);
      },
      (err: any) => {
        this.errorCode = err.error.message;
        this.progress = false;
      },
      () => {
        this.progress = false;
      }
    );
  }

  initUserTypeahead () {
    this.userTypeahead
      .pipe(
        filter((term) => term && term.length >= 4),
        debounceTime(200),
        switchMap(term => this.userService.searchUsers(term))
      ).pipe(takeUntil(this.ngDestroy))
      .subscribe(users => {
        this.users = users;
        this.cd.markForCheck();
      }, (err) => {
        this.users = [];
        this.cd.markForCheck();
      });
  }
}
