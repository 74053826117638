import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {SourceService} from '../../../http/source.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-cancel-mappings-modal',
  templateUrl: './cancel-mappings.component.html'
})
export class CancelMappingsComponent implements OnInit {

  public commentForm: UntypedFormGroup;
  public isLoading = false;
  public submitted = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public mappings: any[];
  public mappedMappings: any[] = [];
  public errorsMap: any = {};

  public bulkUpdate = false;
  public filter: any = {};

  constructor(
    public toastr: ToastrService,
    public sourceService: SourceService,
    private modalRef: BsModalRef
  ) {
    this.commentForm = new UntypedFormGroup({
      comment: new UntypedFormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.creatUiMap();
  }

  close() {
    this.modalRef.hide();
  }

  confirm() {
    this.submitted = true;
    if (!this.commentForm.valid) {
      return;
    }
    const formValue = this.commentForm.value;

    this.isLoading = true;

    if (!this.bulkUpdate) {
      this.sourceService.cancelSourceSeries(this.mappings, formValue.comment).subscribe(
        (data: any) => {
          this.toastr.success($localize`Sent`);
          this.isLoading = false;
          this.savedEmitter.next(true);
          this.close();
        },
        (err: any) => {
          this.toastr.error(err.error.message);
          this.isLoading = false;
        }
      );
    } else {
      this.sourceService.bulkCancelPendingMappings(this.filter, formValue.comment).subscribe(() => {
          this.toastr.success($localize`Sent`);
          this.isLoading = false;
          this.savedEmitter.next(true);
          this.close();
        },
          (err: any) => {
            this.toastr.error(err.error.message);
            this.isLoading = false;
          }
      );
    }

  }

  creatUiMap() {
    if (this.bulkUpdate) {
      return;
    }
    const map: any = {};
    this.mappings.forEach(value => {
      if (!map[value.personCountryCode + '-' + value.personRegistrationNumber]) {
        map[value.personCountryCode + '-' + value.personRegistrationNumber] = {
          countryCode: value.personCountryCode,
          personRegistrationNumber: value.personRegistrationNumber,
          values: []
        };
      }
      map[value.personCountryCode + '-' + value.personRegistrationNumber].values.push(value);
    });

    this.mappedMappings.length = 0;
    for (const i of Object.keys(map)) {
      this.mappedMappings.push({
        countryCode: map[i].personCountryCode,
        personRegistrationNumber: map[i].personRegistrationNumber,
        values: map[i].values
      });
    }
  }
}
