import {UriBuilder} from '../../utils/uri-builder';
import {UrlBuilder} from '../../utils/url-builder';

export abstract class GenericUriBuilder implements UriBuilder {

  private baseUrl: string;

  private contextPath: string;

  private params: any;

  constructor(baseUrl: string, contextPath?: string) {
    this.baseUrl = baseUrl;
    this.contextPath = contextPath;
  }

  abstract processBuildParams(params?: any): void;

  build(params?: any): string {
    this.processBuildParams(params);

    return this.buildUrl();
  }

  setUrlParams(params: any): void {
    this.params = params;
  }

  protected buildUrl() {

    return UrlBuilder.build(this.baseUrl, this.contextPath, this.params);
  }

}
