import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Token } from '../model/person';
import {Observable} from 'rxjs/index';

@Injectable()
export class ApiService {

  constructor (private http: HttpClient) {
  }

  postData (data: any, token: Token) {
    return this.http.post(environment.apiUrl + '/data', data, {
      headers: {'X-Auth-token': token.value},
      withCredentials: true
    });
  }

  postMetadata(data: any, token: Token) {
    return this.http.post(environment.apiUrl + '/data/meta', data, {
      headers: {'X-Auth-token': token.value},
      withCredentials: true
    });
  }

}
