<div *ngIf="token" class="row justify-content-center d-flex mt-2">
  <div class="col-md-12 col-lg-10">
    <div class="form-row justify-content-center d-flex mb-5">
      <div class="green lighten-3 p-3 text-center">
        <h3 i18n>Your API Key value is:</h3>
        <p class="lead m-0"><strong>{{token.value}}</strong></p>
      </div>
    </div>
    <div class="form-row">
      <div class="col-12">
        <p i18n>You can start using this key to make Web service requests. Take a look at the <a href="{{documentationUrl}}" target="_blank">{{documentationLabel}}</a></p>

        <h5 i18n>Authentication</h5>
        <p i18n>The API uses header authentication with API key. The format of the header should be:</p>
        <div class="grey lighten-3 p-3 mb-3">
          <code>X-Auth-Token: {{token.value}}</code>
        </div>

        <h5 i18n>API key sharing</h5>
        <div class="grey lighten-3">
          <p>In order to share API key, it must be marked as "Shared".</p>
          API key can be assigned to:

          <ul>
            <li> existing system user;</li>
            <li> non-existing system user via person identification or registry code or e-mail.</li>
          </ul>

         <p>
           Shared API key can be used only for subset of API queries (versus regular API key):
         </p>
          <p>
            Data (public-api):
            <code>
              <pre>POST /data
PUT /data/meta</pre>
          </code>
          </p>
          <p>
            Sources (application):
            <code>
          <pre>GET /source
DELETE /source/{{'{id}'}}</pre>
          </code>
          </p>
          <p>See more details about these queries from the <a href="{{documentationUrl}}" target="_blank">{{documentationLabel}}</a>.</p>
          <p>Once API key is shared and linked with existing system user, then all the sources added with this API key are also available to that person (including API key owner).</p>
        </div>

        <h5 i18n>Sending measurement data</h5>
        <p i18n>Sample HTTP POST /data request for sending new data.</p>
        <div class="grey lighten-3" i18n>Note that the type of value (measurement or event) is distinguished by the existence and the type of the "value" property.</div>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>{{sampleData | json }}</pre>
          </code>
        </div>

        <h5 i18n>Sending event data</h5>
        <p i18n>Sample HTTP POST /data request for sending new event.</p>
        <div class="grey lighten-3 p-3 mb-3" i18n>Note that the type of value (measurement or event) is distinguished by the existence and the type of the "value" property.</div>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>{{sampleEventData | json }}</pre>
          </code>
        </div>

        <h5 i18n>Mapping datasource metadata</h5>
        <p i18n>Sample HTTP POST /data/meta request for mapping metadata for datasource</p>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>{{sampleMetadata | json }}</pre>
          </code>
        </div>

        <h5 i18n>API rate limits</h5>
        <p i18n>Request rates are limited and enforced <strong>per API key</strong>. Maximum API request rate is <strong>{{rateLimitRpm}} requests per minute</strong>. Rate limits are defined in terms of costs in request tokens per call. Currently all API endpoints have a cost of {{rateLimitCost}} tokens per request. Each API key is limited to using {{rateLimitTokens}} request tokens per minute.</p>
        <h6 i18n>Rate limits response headers</h6>
        <div class="grey lighten-3 p-3 mb-3">
          <code>
            <pre>X-Rate-Limit-Limit	The defined maximum number of request tokens per minute available to the consumer for this API.	integer
X-Rate-Limit-Remaining	The number of request tokens remaining before the limit is enforced and requests are bounced.</pre>
          </code>
        </div>

      </div>
      <div class="col-12 text-center">
        <button type="button" class="btn btn-primary" (click)="next()" i18n>Next</button>
      </div>
    </div>
  </div>
</div>
