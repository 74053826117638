<div class="row">
  <div class="col-12">
    <!-- SELECTED ROWS -->
    <selected-source-rows *ngIf="selectedRowsCount > 0" [mappedStatus]="mappedStatus"></selected-source-rows>

<!--    <div *ngIf="(pageResponse$|async) as page;">-->
<!--      <div *ngIf="allRowsSelectedOnPage" class="d-flex justify-content-center bg-light mb-4" #selectAllRows>-->
<!--        <span class="text-muted p-4">-->
<!--          <span i18n>All {{selectedRowsCount}} source(s) were selected on this page.</span>&nbsp;-->
<!--          <a [routerLink]="" (click)="selectAll()">Select all {{(pageResponse$|async)?.totalElements}} sources.</a>-->
<!--        </span>-->
<!--      </div>-->
<!--    </div>-->

    <!-- ALL ROWS -->
    <table class="table table-responsive-md btn-table table-mapping">
      <thead>
      <tr class="col-12">
        <th colspan="9">
          <div class="d-flex flex-row justify-content-end align-items-right mt-1">
            <span class="results-title" *ngIf="filteredTotal >= total" i18n>
              <span>Total: {{filteredTotal}}</span>
            </span>
            <span class="results-title" *ngIf="filteredTotal < total" i18n>
              <span>Total: {{filteredTotal}} out of {{total}} <i class="fa fa-info-circle btn-tooltip float-right mt-1 pl-2"
                                                                 [popover]="'Showing first results. Please specify search criteria to see concrete results.'" placement="top" triggers="mouseenter:mouseleave"></i></span>
            </span>
          </div>
        </th>
      </tr>

      <tr>
        <ng-template #sortIcon>
          <i class="fa fa-sort text-muted"></i>
        </ng-template>
        <th></th>
        <th colspan="2">
          <span (click)="sort('seriesPath')" class="mr-1" i18n>Source information</span>
          <i *ngIf="activeSort?.prop == 'seriesPath'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        </th>
        <th>
          <span (click)="sort('metaFields.name')" class="mr-1" i18n>Name</span>
          <i *ngIf="activeSort?.prop == 'metaFields.name'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
            <i class="fa-regular fa-circle-info btn-tooltip float-right mt-1" [popover]="'Human readable source name, e.g. Kitchen heat sensor'" placement="right" triggers="mouseenter:mouseleave" ></i>
        </th>
        <th><span (click)="sort('metaFields.groupingTag')" class="mr-1" i18n>Grouping tag</span>
          <i *ngIf="activeSort?.prop == 'metaFields.groupingTag'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
            <i class="fa-regular fa-circle-info btn-tooltip float-right mt-1" [popover]="'Grouping tag will be used in the City Portal for data presentation'" placement="top" triggers="mouseenter:mouseleave" ></i>
        </th>
        <th><span (click)="sort('metaFields.seriesType')" class="mr-1" i18n>Series type</span>
          <i *ngIf="activeSort?.prop == 'metaFields.seriesType'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
            <i class="fa-regular fa-circle-info btn-tooltip float-right mt-1"
               [popover]="'Series type will be used in the City Portal for data presentation'" placement="top" triggers="mouseenter:mouseleave" ></i>
        </th>
        <th><span (click)="sort('metaFields.personRegistrationNumber')" class="mr-1" i18n>Owner reg no</span>
          <i *ngIf="activeSort?.prop == 'metaFields.personRegistrationNumber'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
            <i class="fa-regular fa-circle-info btn-tooltip float-right mt-1"
               [popover]="'Owner registration number or personal code who will be datapoint owner. After source mapping datapoint owner will get request for accepting data.'"
               placement="top"
               triggers="mouseenter:mouseleave" ></i>
        </th>
        <th><span (click)="sort('metaFields.location')" class="mr-1" i18n>Location</span>

          <i *ngIf="activeSort?.prop == 'metaFields.location'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
            <i class="fa-regular fa-circle-info btn-tooltip float-right mt-1"
               [popover]="'Datapoint physical location. For buildings should be used aaddress, e.g. Pepleri street 12, 3, Kitchen. Also aaddress will be used in the City Portal for data presentation.'"
               placement="top"
               triggers="mouseenter:mouseleave"></i>
        </th>
        <th><a (click)="sort('metaFields.confirmationStatus')" i18n>Status</a>
          <i *ngIf="activeSort?.prop == 'metaFields.confirmationStatus'; else sortIcon"
             [class]="activeSort.dir == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        </th>
      </tr>
      <tr [formGroup]="searchForm">
        <th class="align-middle">
          <div class="checkbox">
            <input type="checkbox" [(ngModel)]="allRowsSelectedOnPage" [ngModelOptions]="{standalone: true}"
                   #allRowsChecked (click)="toggleSelectAll();$event.preventDefault();" i18n-matTooltip matTooltip="Add all on page">
          </div>
        </th>
        <th colspan="2">
          <div class="form-row">
            <div class="col-12">
              <div class="input-group input-group-lg form-search">
                <input type="text" class="form-control form-control-lg" placeholder="Type to search..." i18n-placeholder formControlName="source">
                <div class="input-group-append">
                  <span class="clear-search">
                    <i class="fal fa-times"></i>
                  </span>
                  <div class="custom-dropdown filter-dropdown" dropdown [autoClose]="true" (click)="$event.stopImmediatePropagation();">
                    <button type="button" class="btn dropdown-toggle" dropdownToggle i18n>Filter</button>
                    <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                      <div class="accordion-group"  [ngClass]="'show'">
                        <div class="accordion-header">
                          <i class="fal fa-angle-down"></i>
                          <ng-container i18n>Source API</ng-container>
                        </div>
                        <div class="accordion-content">
                          <div class="checkbox" *ngFor="let token of tokens">
                            <value-checkbox [value]="token.id" [label]="token.name" (valueChange)="toggleTokenId(token.id, $event)"></value-checkbox>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <span class="input-group-text">
                    <i class="fal fa-search"></i>
                  </span>
                </div>
              </div>
            </div>

          </div>
        </th>
        <th><input formControlName="name" type="text" class="form-control" i18n-placeholder placeholder="Search"></th>
        <th><input formControlName="groupingTag" type="text" class="form-control" i18n-placeholder placeholder="Search" core-uppercase></th>
        <th>
          <select formControlName="seriesType" class="browser-default custom-select">
            <option [ngValue]="null" i18n>-Select-</option>
            <option *ngFor="let seriesType of seriesTypes" [ngValue]="seriesType">{{seriesType}}</option>
          </select>
        </th>
        <th><input formControlName="personRegistrationNumber" type="text" class="form-control" i18n-placeholder placeholder="Search"></th>
        <th><input formControlName="location" type="text" class="form-control" i18n-placeholder placeholder="Search"></th>
        <th>
          <select formControlName="confirmationStatus" class="browser-default custom-select">
            <option [ngValue]="null" i18n>-Select-</option>
            <option *ngFor="let confirmationStatus of confirmationStatuses" [ngValue]="confirmationStatus">{{confirmationStatus}}</option>
          </select>
        </th>
      </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let row of rows">
          <app-source-item-row [row]="row" [mappedStatus]="mappedStatus"></app-source-item-row>
        </ng-container>
      </tbody>
    </table>
    <div class="col-12 d-flex floating-footer mt-2">
      <pagination
        #listPaginationComponent
        class="flex-grow-1"
        *ngIf="(filteredPageResponse$ | async)?.totalPages > 1"
        [totalItems]="(filteredPageResponse$ | async)?.totalElements"
        [itemsPerPage]="(filteredPageResponse$ | async)?.size"
        [maxSize]="10"
        [rotate]="true"
        (pageChanged)="pageChanged($event)"
        previousText="&lsaquo;"
        nextText="&rsaquo;"
        firstText="&laquo;"
        lastText="&raquo;"
      >
      </pagination>
    </div>
<!--    <div *ngIf="(pageResponse$|async)?.totalElements === 0" class="text-center">-->
<!--      <i i18n>No results</i>-->
<!--    </div>-->

<!--        <virtual-scroller #listScroll [items]="items" [parentScroll]="listScroll.window" [scrollDebounceTime]="0"-->
<!--                          [bufferAmount]="bufferRows * 2" (vsChange)="chartVsChange($event)" (vsUpdate)="viewPortItems = $event">-->
<!--          <div class="sources-list" #container>-->
<!--                <app-source-item-row [row]="item" [mappedStatus]="mappedStatus"-->
<!--                                     *ngFor="let item of viewPortItems"></app-source-item-row>-->
<!--          </div>-->
<!--        </virtual-scroller>-->

    <div *ngIf="(filteredPageResponse$|async)?.totalElements === 0" class="text-center">
      <i i18n>No results</i>
    </div>

    <div *ngIf="vsStartIndex > 0" style="position: fixed; right: 50%; bottom: 20px;">
      <button style="font-size: 2rem;" class="btn btn-scroll-top" tooltip="Scroll to top" i18n-tooltip (click)="scrollToTop()"><i class="fas fa-arrow-to-top"></i></button>
    </div>

  </div>
</div>


