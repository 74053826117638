import {ChangeDetectorRef, Component, HostListener, Input, OnDestroy, OnInit} from '@angular/core';
import {SourceFormService} from './source-form.service';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {ConfirmModalComponent} from '../confirm-modal/confirm-modal.component';
import {SourceMapping, SourcePage} from '../../models/source';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfirmMappingsComponent} from './confirm/confirm-mappings.component';
import {SourceService} from '../../http/source.service';
import {ToastrService} from 'ngx-toastr';
import {CancelMappingsComponent} from './cancel/cancel-mappings.component';
import {SourceGridService} from './source-grid.service';
import {UntypedFormControl} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import { ConfirmCancellationComponent } from './confirm-cancellation/confirm-cancellation.component';

@Component({
  selector: 'selected-source-rows',
  templateUrl: './selected-source-rows.component.html'
})
export class SelectedSourceRowsComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  public bulkUpdate = false;

  private bulkActive = false;
  public bulkActive$ = this.sourceGrid.bulk$;
  public selectedRows$ = this.sourceGrid.selectedRows$;
  public pageResponse$ = this.sourceGrid.filteredPageResponse$;
  public selectedRowsCount = 0;
  public total = 0;
  public filteredTotal = 0;
  public affectedPendingRowsCount = 0;
  public selectedPendingRowsCount = 0;
  public allPendingRowsCount = 0;
  public affectedRowsCount = 0;
  public pageFilter: any = {
    sort: 'metaFields.name',
    order: 'asc',
    notMapped: true,
    mapped: false
  };

  public allRowsSelectedOnPage = false;
  public allRowsSelected = false;

  @Input()
  public mappedStatus = false;

  public toggleAllRowsSelectedControl = new UntypedFormControl(true);

  constructor(private sourceFormService: SourceFormService,
              private sourceGrid: SourceGridService,
              private modalService: BsModalService,
              private sourceService: SourceService,
              private cdRef: ChangeDetectorRef,
              private toastr: ToastrService) { }



  ngOnInit(): void {

    combineLatest([this.sourceGrid.selectedRows$, this.sourceGrid.filteredPageResponse$]).pipe(takeUntil(this.ngDestroy)).subscribe(([selectedRowSet, filteredPage]) => {
      this.filteredTotal = selectedRowSet.size + filteredPage.totalElements;
      this.selectedRowsCount = selectedRowSet.size;
      this.selectedPendingRowsCount = Array.from(selectedRowSet).filter(e => e.item.confirmationStatus === 'PENDING').length;
      if (this.bulkActive && this.selectedRowsCount < 1) {
        this.stopBulkEdit();
      }
      if (!this.bulkUpdate) {
        this.affectedRowsCount = this.selectedRowsCount;
        this.affectedPendingRowsCount = this.selectedPendingRowsCount;
      }

      if (this.selectedRowsCount < 1) {
        this.allRowsSelected = false;
      }
    });
    this.sourceGrid.selectedRows$.pipe(takeUntil(this.ngDestroy)).subscribe(selectedRowSet => {
      this.selectedRowsCount = selectedRowSet.size;
      this.selectedPendingRowsCount = Array.from(selectedRowSet).filter(e => e.item.confirmationStatus === 'PENDING').length;
      if (this.bulkActive && this.selectedRowsCount < 1) {
        this.stopBulkEdit();
      }
      if (!this.bulkUpdate) {
        this.affectedRowsCount = this.selectedRowsCount;
        this.affectedPendingRowsCount = this.selectedPendingRowsCount;
      }

      if (this.selectedRowsCount < 1) {
        this.allRowsSelected = false;
      }
    });

    this.sourceGrid.allRowsOnPageSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((allRowsSelected: boolean) => {
      this.allRowsSelectedOnPage = allRowsSelected;
    });

    combineLatest([
      this.sourceGrid.pageResponse$,
      this.sourceService.countPendingSources(this.sourceGrid.queryParams)
    ]).subscribe(([page, result]: [SourcePage, any]) => {
      this.total = page.totalElements;
      this.allPendingRowsCount = result.count;
    }, (err) => {
      console.error(err);
      this.toastr.error("Error occurred!");
    });

    this.toggleAllRowsSelectedControl.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe((selected: boolean) => {
      if (!selected) {
        this.sourceGrid.deselectAll();
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  sort(property) {
    if (this.pageFilter.sort === property) {
      this.pageFilter.order = this.pageFilter.order === 'asc' ? 'desc' : 'asc';
    } else {
      this.pageFilter.sort = property;
      this.pageFilter.order = 'asc';
    }


    this.sourceGrid.sortSelectedRowSet('name',  this.pageFilter.order === 'asc');
  }

  startAction(action: string): void {
    if (this.bulkUpdate) {
      this.confirmBulkUpdateAction(action).subscribe((result) => {
        if (result) {
          this.doBulkAction(action);
        }
      });
    } else {
      this.doAction(action);
    }
  }

  private doAction(action: string): void {
    switch (action) {
      case 'confirm':
        this.confirm();
        break;
      case 'update':
        this.update();
        break;
      case 'delete':
        this.deleteSelected();
        break;
      case 'cancel-pending':
        this.cancelPending();
        break;
      case 'cancel-confirmation':
        this.cancelConfirmation();
        break;
    }
  }

  private update(): void {
    this.sourceFormService.bulkEditSelectedRows();
  }

  private doBulkAction(action: string) {
    switch (action) {
      case 'confirm':
        this.doBulkConfirm();
        break;
      case 'update':
        this.doBulkUpdate();
        break;
      case 'delete':
        this.doBulkDelete();
        break;
      case 'cancel-pending':
        this.doBulkCancelPending();
        break;
    }
  }

  private doBulkConfirm(): void {
    this.modalService.show(ConfirmMappingsComponent, {class: "modal-lg", initialState: {
        bulkUpdate: true,
        filter: this.sourceGrid.queryParams
      }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.loadPage();
    });
  }

  private doBulkUpdate(): void {
    this.sourceFormService.bulkEditSelectedRows();

    const formData = this.sourceFormService.getBulkEditFormData();
    const toastrRef = this.toastr.info('Loading...', null, { disableTimeOut: true, closeButton: false });
    this.sourceService.patchUpdateSourcesMappings(this.sourceGrid.queryParams, formData).subscribe(() => {
      this.toastr.clear(toastrRef.toastId);
    }, (error) => {
      console.error(error);
      this.toastr.clear(toastrRef.toastId);
      this.toastr.error("Error occurred");
    });
  }

  private doBulkDelete(): void {

    this.sourceService.bulkDeleteSources(this.sourceGrid.queryParams).subscribe(() => {
      this.loadPage();
    });
  }

  private doBulkCancelPending(): void {

    this.modalService.show(CancelMappingsComponent, {initialState: {
        bulkUpdate: true,
        filter: this.sourceGrid.queryParams
      }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.loadPage();
    });
  }


  private confirmBulkUpdateAction(action): Observable<boolean> {

    return new Observable<boolean>((observer) => {

      let affectedRowsCount = (action === 'cancel-pending' ? this.affectedPendingRowsCount : this.affectedRowsCount);

      this.modalService.show(ConfirmModalComponent, {
        initialState: {
          title: $localize`Confirm bulk update`,
          description: $localize`This action will affect all ${affectedRowsCount} row(s). Are you sure you want to continue?`,
          callback: (result: boolean) => {
            observer.next(result);
            observer.complete();
          }
        }
      });
    });

  }

  deleteSelected() {
    this.modalService.show(ConfirmModalComponent, {
      initialState: {
        title: $localize`Are you sure?`,
        description: $localize`Are you sure you want to delete mapping row(s)?`,
        callback: (result: boolean) => {
          if (result !== false) {
            const selectedItems: SourceMapping[] = this.sourceFormService.getMappingData();
            const toForkJoin = [];
            for (const item of selectedItems) {
              toForkJoin.push(this.sourceService.deleteSource(item.id).pipe(map(() => item.id)));
            }

            (toForkJoin.length ? forkJoin(toForkJoin) : of([])).pipe(takeUntil(this.ngDestroy)).subscribe((idList: number[]) => {
              for (const id of idList) {
                this.sourceGrid.removeRow(id);
              }
            });
          }
        }
      }
    });
  }

  private cancelPending() {

    this.modalService.show(CancelMappingsComponent, {initialState: {
        mappings: this.sourceFormService.getPendingMappingData()
      }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.loadPage();
    });
  }

  startBulkEdit() {
    this.sourceGrid.bulkSource.next(true);
  }

  stopBulkEdit() {
    this.sourceGrid.bulkSource.next(false);
  }

  private confirm() {
    this.sourceFormService.markAllAsTouched();
    this.sourceFormService.updateValueAndValidity();
    this.sourceFormService.submittedSource.next(true);

    this.cdRef.markForCheck();

    this.cdRef.detectChanges();
    if (!this.sourceFormService.isFormValid()) {
      this.toastr.error($localize`Form is not valid`);
      return;
    }

    const mappingData = this.sourceFormService.getMappingData();
    const nameSet = new Set<string>();
    for (const row of mappingData) {
      nameSet.add(row.name);
    }
    if (nameSet.size < mappingData.length) {
      this.modalService.show(ConfirmModalComponent, {
        initialState: {
          title: $localize`Are you sure?`,
          description: $localize`Are you sure you want to map multiple datapoints with the same name? It is easier to view the data later if the names are more accurate / different`,
          cancelLabel: $localize`Change names`,
          okLabel: $localize`Send anyway`,
          callback: (result: boolean) => {
            if (result !== false) {
              this.modalService.show(ConfirmMappingsComponent, {class: "modal-lg", initialState: {
                  mappings: mappingData
                }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
                this.loadPage();
              });
            }
          }
        }
      });
    } else {
      this.modalService.show(ConfirmMappingsComponent, {class: "modal-lg", initialState: {
          mappings: mappingData
        }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
        this.loadPage();
      });
    }
  }

  public cancelConfirmation() {
    this.sourceFormService.markAllAsTouched();
    this.sourceFormService.updateValueAndValidity();
    this.sourceFormService.submittedSource.next(true);

    this.cdRef.markForCheck();

    this.cdRef.detectChanges();
    if (!this.sourceFormService.isFormValid()) {
      this.toastr.error($localize`Form is not valid`);
      return;
    }

    const mappingData = this.sourceFormService.getMappingData();
    const nameSet = new Set<string>();
    for (const row of mappingData) {
      nameSet.add(row.name);
    }

    this.modalService.show(ConfirmCancellationComponent, {class: "modal-lg", initialState: {
        mappings: mappingData
      }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.loadPage();
    });

    //error handling
  }

  public loadPage() {
    this.sourceGrid.resetSelected();
    this.bulkUpdate = false;
    this.sourceGrid.bulkSource.next(false);
  }

  public toggleBulkUpdate() {
    this.bulkUpdate = !this.bulkUpdate;
    if (this.bulkUpdate) {
      this.affectedRowsCount = this.filteredTotal;
      this.affectedPendingRowsCount = this.allPendingRowsCount;
    } else {
      this.affectedRowsCount = this.selectedRowsCount;
      this.affectedPendingRowsCount = this.selectedPendingRowsCount;
    }
  }

  public selectAll() {
    if (!this.sourceGrid.canAddMore(-1)) {
      this.sourceGrid.bulkEditLimitExceeded.next();
      return;
    }
    this.sourceGrid.selectAll();
    this.allRowsSelectedOnPage = false;
    this.allRowsSelected = true;

  }
}
