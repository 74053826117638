import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {environment} from '../../../environments/environment';
import {Token, TokenPage, TokenPersonHistory} from '../model/person';
import {Observable} from 'rxjs/internal/Observable';

@Injectable()
export class PersonService {

  constructor(private http: HttpClient) {
  }


  getTokens(filter: any): Observable<TokenPage> {
    const params: any = {
      page: filter.page - 1,
      size: filter.size
    };
    if (filter.myTokens) {
      params.myTokens = true;
    }
    if (filter.mySharedTokens) {
      params.mySharedTokens = true;
    }
    if (filter.tokensShared) {
      params.tokensShared = true;
    }
    return this.http.get<TokenPage>(environment.apiUrl + '/person/token', {params: params});
  }

  getToken(id: number) {
    return this.http.get(environment.apiUrl + '/person/token/' + id);
  }

  saveToken({token, siteUrl}: { token: Token, siteUrl: string }): Observable<Token> {
    return this.http.post<Token>(environment.apiUrl + '/person/token', {
      ...token,
      siteUrl: siteUrl
    });
  }

  updateToken(id: number, {token, siteUrl}: { token: Token, siteUrl: string }): Observable<Token> {
    return this.http.put<Token>(environment.apiUrl + '/person/token/' + id, {
      ...token,
      siteUrl: siteUrl
    });
  }

  deleteToken(id: number) {
    return this.http.delete(environment.apiUrl + '/person/token/' + id);
  }

  declineToken(id: number) {
    return this.http.get(environment.apiUrl + '/person/token/' + id + '/decline');
  }

  acceptToken(uuid: string): Observable<Token> {
    return this.http.get<Token>(environment.apiUrl + '/person/token/accept-uuid/' + uuid);
  }

  getTokenPersonHistory(token: Token) {
    return this.http.get<TokenPersonHistory>(environment.apiUrl + '/person/token/' + token.id + '/history');
  }

}
