<div class="row mt-3">
  <div class="col text-center" i18n>See <a href="{{cumulocityDocsUrl}}" target="_blank">Cumulocity documentation</a> for more information on querying inventory.</div>
</div>
<div [formGroup]="searchForm">
  <div class="form-row justify-content-center align-items-end d-flex mt-3 mb-5">
    <div class="col-md-3 col-sm-6 text-left">
      <label i18n [attr.for]="id + '-keyword'">Keyword</label>
      <input formControlName="keyword" type="text" class="form-control" [id]="id + '-keyword'" (keyup)="searchKeyup($event)">
    </div>
    <div class="col-auto d-flex align-items-end flex-column mt-2 mt-md-0">
      <button [disabled]="isLoading" (click)="searchManagedObject()" type="button" class="btn btn-primary mt-auto">
        <i class="fa fa-search"></i>&nbsp;<ng-container i18n>Search</ng-container>
      </button>
    </div>

    <div style="flex-basis: 100%; width: 0;"></div>

    <div class="col-auto d-flex align-items-end flex-column mt-10 mt-md-10">
      <div class="custom-control custom-switch">
        <input id="onlyWithMeasurements" class="custom-control-input" type="checkbox" formControlName="onlyWithMeasurements" />
        <label for="onlyWithMeasurements" class="custom-control-label">
          Show only with measurements
        </label>
      </div>
    </div>
  </div>


</div>
<div class="container">
  <div class="row no-gutters my-3" >
      <div class="w-100" *ngIf="dataSource.data.length === 0" [class.col-md-6]="selectedTable">
        <div class="text-left mr-md-3" style="display: block">
          <i class="mat-tree p-4 " i18n>No data to show</i>
        </div>
      </div>

    <div *ngIf="dataSource.data.length > 0" [class.col-md-6]="selectedTable" class="w-100">
      <div class="bg-white mr-md-3 pb-4">
        <h4 *ngIf="dataSource.data.length != 0" class="pl-4 pt-4 text-left" i18n>Vali andmed</h4>
        <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mx-5">
          <!-- Leaf node -->
          <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>

            <div class="mat-tree-node-toggle">
              <button type="button" mat-icon-button
                      [attr.aria-label]="'toggle ' + node.name"
                      (click)="loadChildren(node)"
                      matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              {{node.name}}
            </div>

          </mat-tree-node>

          <mat-tree-node *matTreeNodeDef="let node; when: isSeries" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button type="button" disabled></button>
            <mat-checkbox class="checklist-leaf-node"
                          [disabled]="node.disabled"
                          [checked]="isChecked(node)"
                          (change)="todoLeafItemSelectionToggle(node)">
              {{node.name}}
            </mat-checkbox>
          </mat-tree-node>

          <mat-tree-node *matTreeNodeDef="let node; when: isEvent" matTreeNodeToggle matTreeNodePadding>
            <button mat-icon-button type="button" disabled></button>
            <mat-checkbox class="checklist-leaf-node"
                          [disabled]="node.disabled"
                          [checked]="isChecked(node)"
                          (change)="todoLeafItemSelectionToggle(node)" i18n>Event</mat-checkbox>
            <input *ngIf="node.sourcePath" [disabled]="true" [(ngModel)]="node.sourcePath" type="text" class="form-control form-control-sm w-150p ml-2"
                   [required]="checklistSelection.isSelected(node)"
                   name="sourcePath-{{node.id}}"
                   placeholder="Event type"
                   i18n-placeholder>
          </mat-tree-node>

          <!-- expandable node -->
          <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
            <div class="mat-tree-node-toggle">
              <button type="button" mat-icon-button
                      [attr.aria-label]="'toggle ' + node.name"
                      (click)="loadChildren(node)"
                      matTreeNodeToggle>
                <mat-icon class="mat-icon-rtl-mirror">
                  {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
                </mat-icon>
              </button>
              <span *ngIf="isDevice(node)">[device]</span> {{node.name}}
              <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
            </div>
          </mat-tree-node>
          <mat-tree-node *matTreeNodeDef="let node; when: isLoadMore" matTreeNodePadding>
            <button mat-button type="button" [disabled]="node.isLoading" (click)="node.isLoading = true; loadMore(node)" i18n [ladda]="node.isLoading">Load more...</button>
          </mat-tree-node>
        </mat-tree>
      </div>
    </div>
    <div *ngIf="selectedTable" class="order-first order-md-last col-md-6 w-100 mb-3 mb-md-0">
      <tenant-selected-sources [tenant]="tenant"></tenant-selected-sources>
    </div>
  </div>

</div>



<app-scroll-top></app-scroll-top>
