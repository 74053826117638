import {Pipe, PipeTransform} from '@angular/core';
import {Person} from '../model/person';
import moment from 'moment';
import {environment} from '../../../environments/environment';

@Pipe({name: 'regNo'})
export class RegNoPipe implements PipeTransform {
  private config = environment;

  constructor() {}

  transform(value: Person): string {
    if (!value) {
      return '';
    }
    if (value.idPrivacyType === 'PRIVATE' && value.dob) {
      return moment(value.dob, 'YYYY-MM-DD').format('DD.MM.YYYY');
    }
    // Fallback for public ids that should be private
    if (value.juridical) {
      return value.registrationNumber;
    }
    // return value.registrationNumber.substring(0, 6) + '****';
    return this.cprRegNoFilter(value.countryCode, value.registrationNumber);
  }

  cprRegNoFilter(countryCode: string, registrationNumber: string): string {
    if (!countryCode || !registrationNumber) {
      return '';
    }
    if (countryCode !== 'DK' || registrationNumber.length !== 10) {
      return registrationNumber;
    }

    const day = parseInt(registrationNumber.substring(0, 2), 10);
    const month = parseInt(registrationNumber.substring(2, 4), 10);
    const age = parseInt(registrationNumber.substring(4, 6), 10);
    let year = 0;
    const sequence = parseInt(registrationNumber.substring(6, 10), 10);
    if (sequence >= 4000 && sequence <= 4999 && age >= 0 && age <= 36) {
      year = 2000;
    } else if (sequence >= 4000 && sequence <= 4999 && age >= 37 && age <= 99) {
      year = 1900;
    } else if (sequence >= 5000 && sequence <= 5999 && age >= 0 && age <= 57) {
      year = 1900;
    } else if (sequence >= 1 && sequence <= 999 && age >= 1 && age <= 150) {
      year = 1900;
    } else if (sequence >= 1000 && sequence <= 3999 && age >= 1 && age <= 150) {
      year = 1900;
    } else if (sequence >= 5000 && sequence <= 8999 && age >= 0 && age <= 57) {
      year = 2000;
    }

    const dob = moment.utc();
    dob.set('year', year + age);
    dob.set('month', month - 1);
    dob.set('date', day);
    dob.startOf('day');

    return dob.format('DD.MM.YYYY');
  }
}
