import {UntypedFormGroup} from '@angular/forms';
import {ManagedObject} from './managed-object';
import {NodeType} from './node-type';

/** Flat node with expandable and level information */
export class ObjectFlatNode {

  isLoading = false;
  control: UntypedFormGroup;

  constructor(public id: string,
              public type: NodeType,
              public name: string,
              public managedObject: ManagedObject | null,
              public sourcePath: string | null,
              public level: number,
              public expandable = false,
              public loadMoreParentItem: string | null = null,
              public disabled: boolean = false,
              public source: ObjectFlatNode | null = null) {}
}
