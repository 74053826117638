import {Component, Input, OnInit} from '@angular/core';
import {TokenPerson} from '../../../../core/model/person';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-token-persons-modal',
  templateUrl: './token-persons-modal.component.html'
})
export class TokenPersonsModalComponent implements OnInit {

  @Input()
  tokenPersons: TokenPerson[] = [];

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }

}
