import {RedirectStrategy} from './redirect-strategy';
import {UriBuilder} from '../../utils/uri-builder';

export abstract class AbstractRedirectStrategy implements RedirectStrategy {

  protected redirectUriBuilder: UriBuilder;

  setRedirectUriBuilder(redirectUriBuilder: UriBuilder): void {
    this.redirectUriBuilder = redirectUriBuilder;
  }

  getRedirectUriBuilder(): UriBuilder {
    return this.redirectUriBuilder;
  }

  redirect(params?: any): Promise<boolean> {
    return this.redirectToUrl(this.redirectUriBuilder.build(params));
  }

  redirectToUrl(url: string):  Promise<boolean> {
    window.location.href = url;
    return Promise.resolve(true);
  }

}
