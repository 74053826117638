import {Component, Input, OnInit} from '@angular/core';
import {TokenPersonHistory} from '../../../../core/model/person';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-token-person-history-modal',
  templateUrl: './token-person-history-modal.component.html'
})
export class TokenPersonHistoryModalComponent implements OnInit {


  @Input()
  public history: TokenPersonHistory;

  constructor(private modalRef: BsModalRef) { }

  ngOnInit(): void {
  }

  close(): void {
    this.modalRef.hide();
  }
}
