import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {IdpAuthService} from '../../../core/auth/idp-auth.service';
import {TokenAuthService} from '../../../core/auth/token-auth.service';

@Component({
  selector: 'app-auth-redirect',
  templateUrl: './auth-redirect.component.html',
})
export class AuthRedirectComponent implements OnInit {
  private ngDestroy = new Subject<void>();

  constructor(private route: ActivatedRoute,
              private authService: TokenAuthService,
              private idpAuthService: IdpAuthService,
              private router: Router) { }

  ngOnInit(): void {
    this.route.queryParams.subscribe((params: any) => {
      this.idpAuthService.authenticationCallback(params);
    });

  }

}
