import {ErrorHandler, Injectable} from '@angular/core';
import {LoggerService} from './logger.service';
import {TokenAuthService} from '../auth/token-auth.service';

@Injectable()
export class ErrorHandlerService implements ErrorHandler {
  constructor(private logger: LoggerService, private tokenAuthService: TokenAuthService) { }

  handleError(error: Error) {
    console.error(error);
    let profileData = this.tokenAuthService.profileData;
    let personInfo = (profileData ? this.getPersonInfo(profileData) : 'ANONYMOUS');
    this.logger.error(personInfo, error.message, error.stack);
  }

  private getPersonInfo(profileData: any): string {
    return "[" + profileData.user.countryCode + profileData.user.personalId + "," + profileData.mandate.person.countryCode + profileData.mandate.person.registrationNumber + "]";
  }

}
