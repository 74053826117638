<ng-template #fieldError let-errors="errors">
  <span>Errors: {{errors|json}}</span>
</ng-template>

<ng-template #noRootTemplate>
  <tr *ngIf="_row" [ngClass]="addedToEdit ? 'bg-white alert-warning' : 'bg-white'" [@simpleFadeAnimation]="hide">
    <td class="text-center">
      <div class="checkbox">
        <input type="checkbox" [checked]="checked" (click)="toggleSelect($event);">
      </div>
    </td>

    <td colspan="2">
      <ng-template #fieldErrorMessages let-errors="errors">
        <b *ngFor="let error of errors" class="text-danger">{{error}}</b>
      </ng-template>

      <ng-template #lastValuePopover>
        <div class="sourceAssetTree-wrapper">
          <span *ngIf="_source?.lastValue.unit" class="d-flex pl-3"><span class="fw-bold" i18n>Unit</span>: {{_source?.lastValue.unit}}</span>
          <span *ngIf="_source?.lastValue.time"class="d-flex pl-3"><span class="fw-bold" i18n>Time</span>: {{_source?.lastValue.time}}</span>
          <span *ngIf="_source?.lastValue.value" class="d-flex pl-3"><span class="fw-bold" i18n>Value</span>: <pre class="ml-2">{{_source?.lastValue.value | json}}</pre></span>
        </div>
      </ng-template>
      <ng-template #sourceAssetsTreePopover>
        <div class="sourceAssetTree-wrapper">
            <ul class="sourceAssetTree-item" *ngFor="let sourceTreeAssets of _source?.sourceAssetsTree.assets">
              <li>
                <span class="d-flex"><span class="fw-bold" i18n>ID</span>: {{sourceTreeAssets.id}}</span>
                <span class="d-flex"><span class="fw-bold" i18n>Name</span>: {{sourceTreeAssets.name}}</span>
                <span class="d-flex"><span class="fw-bold" i18n>Type</span>: {{sourceTreeAssets.type}}</span>
              </li>
            </ul>
            <i class="fa fa-arrow-turn-down-right"></i>
          </div>
      </ng-template>

      <ng-container *ngIf="_source.sourceType == 'TENANT_MEASUREMENT'">
        <ng-container i18n>Name</ng-container>: <strong>{{_source.sourceName}}</strong><br>
        <ng-container i18n>Id</ng-container>: <strong>{{_source.objectId}}</strong> <a *ngIf="_source?.lastValue && _source?.lastValue.value != null" [routerLink]="" [ngbPopover]="lastValuePopover" popoverTitle="Last value" class="position-relative" i18n-popoverTitle><i class="icon-sm icon-info-outline-primary" triggers="mouseenter:mouseleave" ></i></a><br>
        <ng-container i18n>Series</ng-container>: <strong>{{_source.seriesPath}}</strong><br>
        <ng-container i18n>Source</ng-container>: <strong i18n>Tenant</strong> <a *ngIf="_source?.sourceAssetsTree" [routerLink]="" [ngbPopover]="sourceAssetsTreePopover" popoverTitle="Source asset tree" class="position-relative" i18n-popoverTitle><i class="icon-sm icon-info-outline-primary" triggers="mouseenter:mouseleave" ></i></a><br>
      </ng-container>

      <ng-container *ngIf="_source.sourceType == 'TENANT_EVENT'">
        <ng-container i18n>Name</ng-container>: <strong>{{_source.sourceName}}</strong><br>
        <ng-container i18n>Id</ng-container>: <strong>{{_source.objectId}}</strong> <a *ngIf="_source?.lastValue && _source?.lastValue.value != null" [routerLink]="" [ngbPopover]="lastValuePopover" popoverTitle="Last value" class="position-relative" i18n-popoverTitle><i class="icon-sm icon-info-outline-primary" triggers="mouseenter:mouseleave" ></i></a><br>
        <ng-container i18n>Type</ng-container>: <strong>{{_source.seriesPath}}</strong><br>
        <ng-container i18n>Source</ng-container>: <strong i18n>Tenant</strong> <a *ngIf="_source?.sourceAssetsTree" [routerLink]="" [ngbPopover]="sourceAssetsTreePopover" popoverTitle="Source asset tree" class="position-relative" i18n-popoverTitle><i class="icon-sm icon-info-outline-primary" triggers="mouseenter:mouseleave" ></i></a><br>
      </ng-container>

      <ng-container *ngIf="_source.sourceType == 'API_DATA'">
        <ng-container i18n>Id</ng-container>: {{_source.sourceUuid}} <a *ngIf="_source?.lastValue && _source?.lastValue.value != null" [routerLink]="" [ngbPopover]="lastValuePopover" popoverTitle="Last value" class="position-relative" i18n-popoverTitle><i class="icon-sm icon-info-outline-primary" triggers="mouseenter:mouseleave" ></i></a><br>
        <ng-container i18n>Series</ng-container>: <strong>{{_source.seriesPath}}</strong><br>
        <ng-container i18n>Source</ng-container>: <strong>{{_source?.token?.name}}</strong>
      </ng-container>

      <ng-container *ngIf="_source.sourceType == 'PERSON_DATA'">
        <a *ngIf="_source?.lastValue && _source?.lastValue.value != null" [routerLink]="" [ngbPopover]="lastValuePopover" popoverTitle="Last value" class="position-relative" i18n-popoverTitle><i class="icon-sm icon-info-outline-primary" triggers="mouseenter:mouseleave" ></i><br></a>
        <ng-container i18n>Source</ng-container>: <strong i18n>Person data</strong>
      </ng-container>
    </td>

    <ng-container *ngIf="_edit && _group" [formGroup]="_group">
      <td class="editable align-top">
        <input formControlName="name" type="text"
               class="form-control"
               [class.is-invalid]="invalid('name')"
               placeholder="Human readable name"
               i18n-placeholder>
        <form-field-errors [control]="_group.get('name')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
      </td>
      <td class="editable align-top">
        <input type="text" class="form-control" [class.is-invalid]="invalid('groupingTag')"
               formControlName="groupingTag" autocomplete="off"
               [typeahead]="groupingTags" [typeaheadMinLength]="0" core-uppercase>
      </td>
      <td class="editable align-top">
        <ng-select placeholder="Series type" i18n-placeholder [class.is-invalid]="invalid('seriesType')" [items]="seriesTypes" bindValue="value" bindLabel="label" formControlName="seriesType"></ng-select>
        <form-field-errors [control]="_group.get('seriesType')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
      </td>
      <td class="editable align-top">
        <ng-template #personTempalte let-model="item" let-index="index">
          {{model.registrationNumber}} ({{model.displayName}})
        </ng-template>
        <input formControlName="personRegistrationNumber"
               placeholder="Owner reg no" i18n-placeholder
               type="text"
               class="form-control"
               [ngClass]="{ 'is-invalid': invalid('personRegistrationNumber'), 'disabled': _mappedStatus }"
               [typeahead]="personTypeahead$"
               [typeaheadAsync]="true"
               typeaheadWaitMs="250"
               [typeaheadMinLength]="4"
               [typeaheadItemTemplate]="personTempalte"
               typeaheadOptionField="registrationNumber"
               (typeaheadOnSelect)="typeaheadOnSelect($event)"
               [attr.disabled]="_mappedStatus ? true : null">
        <form-field-errors [control]="_group.get('personRegistrationNumber')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
      </td>
      <td class="editable align-top">
        <div class="location text-nowrap">
          <div *ngFor="let type of locationTypes" class="custom-control custom-radio custom-control-inline">
            <input formControlName="locationType"
                   type="radio" [value]="type"
                   class="custom-control-input"
                   id="lt_{{type}}_{{ _group.get('id').value }}">
            <label class="custom-control-label" for="lt_{{type}}_{{ _group.get('id').value }}">{{type}}</label>
          </div>
        </div>
        <form-field-errors [control]="_group.get('locationType')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
        <div class="location">
          <ng-container *ngIf="_group.get('locationType').value == 'UUID'">
            <input formControlName="uuid"
                   type="text"
                   class="form-control mt-1"
                   [class.is-invalid]="invalid('uuid')"
                   placeholder="UUID" i18n-placeholder>
            <form-field-errors [control]="_group.get('uuid')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
          </ng-container>
          <ng-container *ngIf="_group.get('locationType').value == 'COORDS'">
            <input formControlName="lat"
                   type="text"
                   class="form-control mt-1"
                   [class.is-invalid]="invalid('lat')"
                   placeholder="Lat" i18n-placeholder>
            <form-field-errors [control]="_group.get('lat')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
            <input formControlName="lng"
                   type="text"
                   class="form-control mt-1"
                   [class.is-invalid]="invalid('lng')"
                   placeholder="Lng" i18n-placeholder>
            <form-field-errors [control]="_group.get('lng')" [errorsTemplate]="fieldErrorMessages"></form-field-errors>
          </ng-container>

          <ng-container *ngIf="_group.get('locationType').value == 'ADDRESS'" [ngSwitch]="addressControlType">
            <app-address-ads *ngSwitchCase="'inads'" [form]="_group"></app-address-ads>
            <app-address-dawa *ngSwitchCase="'dawa'" [form]="_group"></app-address-dawa>
            <app-address-input *ngSwitchDefault [form]="_group"></app-address-input>
          </ng-container>
        </div>
      </td>
      <td class="editable">
        <div class="d-flex mapped-status">
          <span *ngIf="_source.confirmationStatus == 'PENDING'" class="badge badge-pill badge-info" i18n>Pending</span>
          <span *ngIf="_source.confirmationStatus == 'ACCEPTED'" class="badge badge-pill badge-success" i18n>Accepted</span>
          <span *ngIf="_source.confirmationStatus == 'DECLINED'" class="badge badge-pill badge-danger" i18n>Declined</span>
          <span *ngIf="_source.confirmationStatus == 'CANCELLED'" class="badge badge-pill badge-warning" i18n>Cancelled</span>

          <div class="d-flex flex-column ml-auto">
            <button *ngIf="!_mappedStatus" (click)="save()" class="btn btn-sm btn-primary mb-1" type="button" i18n>
              <i class="fa fa-check"></i> Save
            </button>
            <button *ngIf="!_mappedStatus" (click)="cancel()" class="btn btn-sm btn-outline-secondary" type="button" i18n>
              <i class="fa fa-xmark"></i> Cancel
            </button>
          </div>
        </div>

      </td>
    </ng-container>

    <ng-container *ngIf="!_edit || !_group">
      <td class="editable">
        {{_source.name}}
      </td>
      <td class="editable">
        {{_source.groupingTag}}
      </td>
      <td class="editable">
        {{_source.seriesType}}
      </td>
      <td class="editable">
        {{_source.personRegistrationNumber}}
      </td>
      <td class="editable position-relative">

        <div class="location">
          <ng-container *ngIf="_source.locationType == 'UUID'">
            {{_source.uuid}}
          </ng-container>
          <ng-container *ngIf="_source.locationType == 'COORDS'">
            {{_source.lat}}
            {{_source.lng}}
          </ng-container>
          <ng-container *ngIf="_source.locationType == 'ADDRESS'">
            {{_source.address}}
            {{_source.apartment}}
            {{_source.room}}
          </ng-container>
        </div>
      </td>
      <td>
        <div class="d-flex align-items-center mapped-status">
          <span *ngIf="_source.confirmationStatus == 'PENDING'" i18n><span class="badge badge-pill badge-info">Pending</span></span>
          <span *ngIf="_source.confirmationStatus == 'ACCEPTED'" class="badge badge-pill badge-success" i18n>Accepted</span>
          <span *ngIf="_source.confirmationStatus == 'DECLINED'" class="badge badge-pill badge-danger" i18n>Declined</span>
          <span *ngIf="_source.confirmationStatus == 'CANCELLED'" class="badge badge-pill badge-warning" i18n>Cancelled</span>
          <button *ngIf="!_mappedStatus" class="btn btn-icon btn-mapped-status" type="button" (click)="editRow()"><i class="icon-xs icon-edit"></i></button>
        </div>
      </td>
    </ng-container>
  </tr>
</ng-template>
