<form (ngSubmit)="startMapping()" [formGroup]="mappingForm" class="sticky-top">
  <ng-container formArrayName="selectedSources">
    <div class="bg-white mr-md-3 pb-4">
      <h4 class="pl-4 pt-4 text-left" i18n>Valitud andmed</h4>
      <mat-tree [dataSource]="dataSource" [treeControl]="treeControl" class="mx-5">
        <!-- Leaf node -->
        <mat-tree-node *matTreeNodeDef="let node" matTreeNodePadding>
          <button mat-icon-button type="button" disabled></button>
          {{node.name}}
        </mat-tree-node>
        <mat-tree-node *matTreeNodeDef="let node; when: isSeries" matTreeNodeToggle matTreeNodePadding>
          <button mat-icon-button type="button" disabled></button>

          <mat-checkbox class="checklist-leaf-node"
                        [disabled]="node.disabled"
                        [checked]="isChecked(node)"
                        (change)="todoLeafItemSelectionToggle(node)">
            {{node.name}}
          </mat-checkbox>
        </mat-tree-node>

        <mat-tree-node *matTreeNodeDef="let node; when: isEvent" matTreeNodeToggle matTreeNodePadding >
          <ng-container [formGroup]="node.control">
            <button mat-icon-button type="button" disabled></button>
            <mat-checkbox class="checklist-leaf-node" formControlName="checked"
                          [disabled]="node.disabled"
                          (change)="todoLeafItemSelectionToggle(node)" i18n>Event</mat-checkbox>&nbsp;
            <input formControlName="sourcePath" type="text" class="form-control form-control-sm w-150p ml-2"
                   [required]="checklistSelection.isSelected(node)"
                   name="sourcePath-{{node.id}}"
                   placeholder="Event type"
                   i18n-placeholder>
            <span>
              <small *ngIf="checklistSelection.isSelected(node) && node.control.invalid" class="invalid-feedback d-block ml-2" i18n>Required</small>
            </span>
          </ng-container>

        </mat-tree-node>


        <mat-tree-node *matTreeNodeDef="let node; when: hasChild" matTreeNodePadding>
          <div class="mat-tree-node-toggle">
            <button type="button" mat-icon-button
                    [attr.aria-label]="'toggle ' + node.name"
                    (click)="loadChildren(node)"
                    matTreeNodeToggle>
              <mat-icon class="mat-icon-rtl-mirror">
                {{treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right'}}
              </mat-icon>
            </button>
            {{node.name}}

            <button type="button" mat-icon-button (click)="removeNode(node)"><mat-icon>close</mat-icon></button>
            <mat-progress-bar *ngIf="node.isLoading" mode="indeterminate" class="example-tree-progress-bar"></mat-progress-bar>
          </div>

        </mat-tree-node>
      </mat-tree>


    </div>

  </ng-container>
  <button type="submit" class="btn btn-primary mt-3 mb-4" [disabled]="this.mappingForm.invalid || checklistSelection.selected.length == 0">
    <ng-container i18n>Start mapping</ng-container><ng-container> ({{checklistSelection.selected.length}})</ng-container>
  </button>
</form>
