<div class="page-title-bar p-4">
  <div class="d-flex">
    <div class="col-2 pl-0">
      <a routerLink=".."><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1" i18n *ngIf="token">Edit token</h2>
      <h2 class="mb-1" i18n *ngIf="!token">Add token</h2>
    </div>
    <div class="col-2 pr-0 d-flex justify-content-end">
    </div>
  </div>
</div>

<form [formGroup]="tokenForm" (ngSubmit)="save()">
  <div class="container-fluid px-0">
    <div class="form-content">

      <div class="col-md-12 col-xl-8 col-lg-10 mx-auto">
        <div *ngIf="token">
          <div class="form-row justify-content-center d-flex mb-5">
            <div class="p-3 text-center">
              <p class="lead m-0"><strong>{{token.value}}</strong></p>
            </div>
          </div>
          <div class="form-group row">
            <div class="col-6">
              <div class="form-group">
                <div class="form-row">
                  <div class="col">
                    <label for="{{id + '-name'}}" i18n>Token name</label>
                    <input type="text" class="form-control" [id]="id + '-name'" formControlName="name" placeholder="Token name" i18n-placeholder>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="!token.primary">
                <div class="form-row">
                  <div class="col">
                    <div class="custom-control custom-switch mt-2">
                      <input class="custom-control-input" type="checkbox" id="shared-checkbox" formControlName="assigned" (click)="check($event);" />
                      <label class="custom-control-label mr-4" for="shared-checkbox" i18n>Shared</label>
                    </div>
                  </div>
                </div>
              </div>

              <div class="form-group" *ngIf="tokenForm.get('assigned').value">
                <token-person-select (onTokenPersonSelect)="selectTokenPerson($event)"></token-person-select>
              </div>
            </div>

            <div class="col-6" *ngIf="this.tokenForm.get('assigned').value">

              <div class="form-group row mb-0">
                <div class="col">
                  <h4 i18n>Persons shared with</h4>
                </div>
              </div>

              <div class="filter-bar">
                <div class="custom-list">

                  <div class="list-item" *ngFor="let tokenPerson of token.tokenPersons; let i = index;">

                    <div class="row">
                     <span class="col my-auto">
                      <div class="title">
                        <!-- TODO: personId pipe -->
                        <span *ngIf="tokenPerson.assignType == 'EMAIL'">{{tokenPerson.email}}</span>
                        <span *ngIf="tokenPerson.assignType == 'REG_NO'">{{tokenPerson.registrationNumber}}</span>
                        <span *ngIf="tokenPerson.assignType == 'PERSON'">{{tokenPerson.person.displayName}} ({{tokenPerson.person.registrationNumber}})</span>
                      </div>
                       <span class="text-light" *ngIf="tokenPerson.assignedAt"><span i18n>Assigned at:</span> {{tokenPerson.assignedAt|date:'dd.MM.yyyy'}}</span>
                    </span>
                      <div class="col text-right m-auto">
                        <div class="btn-group btn-more" dropdown>
                          <button type="button" class="btn btn-icon dropdown-toggle" dropdownToggle><i class="far fa-ellipsis-v"></i></button>
                          <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                            <a class="dropdown-item" (click)="removeTokenPerson(tokenPerson)"><i class="fal fa-trash"></i> <ng-container i18n>Remove</ng-container></a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
    <footer class="form-footer">
      <button type="submit" class="btn btn-primary mb-1 mb-md-0" i18n [ladda]="progress">Save</button>
    </footer>
  </div>
</form>
