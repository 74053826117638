import {IdpAuthService} from './idp-auth.service';
import {LocationService} from '../services/location.service';
import {TaraAuthService} from './tara/tara-auth.service';
import {NemLogInAuthService} from './nem-log-in/nem-log-in-auth.service';
import {IdpService} from './idp.service';
import {HttpClient} from '@angular/common/http';
import {TokenAuthService} from './token-auth.service';
import {DemoAuthService} from './demo/demo-auth.service';
import {IdpAuthSwitchService} from './idp-auth-switch.service';

let authServiceFactory = (http: HttpClient,
                          tokenAuthService: TokenAuthService,
                          idpService: IdpService,
                          locationService: LocationService,
                          taraAuthService: TaraAuthService,
                          nemLogInService: NemLogInAuthService,
                          demoAuthService: DemoAuthService) => {
  return new IdpAuthSwitchService(tokenAuthService, idpService, taraAuthService, nemLogInService, demoAuthService);
};

export let idpAuthServiceProvider = {
  provide: IdpAuthService,
  useFactory: authServiceFactory,
  deps: [
    HttpClient,
    TokenAuthService,
    IdpService,
    LocationService,
    TaraAuthService,
    NemLogInAuthService,
    DemoAuthService
  ]
}
