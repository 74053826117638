import {AfterViewChecked, ChangeDetectorRef, Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Tenant} from '../../../../core/model/tenant';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {LoadmoreDatabase} from './loadmore-database';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {Router} from '@angular/router';
import {Subject} from 'rxjs/internal/Subject';
import {ToastrService} from 'ngx-toastr';
import {SelectedSourcesService} from './selected-sources.service';
import {SelectedSourcesDatabase} from './selected-sources-database';
import {SelectedSourcesComponent} from './selected-sources.component';
import {TenantBrowserService} from '../../../services/tenant-browser.service';
import {TreeControlUtil} from '../../../utils/tree-control-util';
import {takeUntil} from 'rxjs/operators';
import {ObjectFlatNode} from '../../../models/tenant-mapping/object-flat-node';
import {ObjectNode} from '../../../models/tenant-mapping/object-node';

const selector = 'mydata-series-bulk-edit-modal';
let nextId = 0;

@Component({
  selector: 'app-tenant-browser',
  templateUrl: './tenant-browser.component.html'
})
export class TenantBrowserComponent implements OnInit, OnDestroy, AfterViewChecked {
  id = `${selector}-${nextId++}`;
  private ngDestroy = new Subject<void>();

  public cumulocityDocsUrl = 'https://cumulocity.com/guides/reference/inventory/';

  @Input()
  public tenant: Tenant;

  public searchForm: UntypedFormGroup;

  public isLoading = false;

  treeControl: FlatTreeControl<ObjectFlatNode>;

  dataSource: MatTreeFlatDataSource<ObjectNode, ObjectFlatNode>;

  checklistSelection = new SelectionModel<ObjectFlatNode>(true /* multiple */);

  selectedTable = false;

  @ViewChild(SelectedSourcesComponent) selectedSourcesComponent: SelectedSourcesComponent;

  private treeControlUtil: TreeControlUtil;

  constructor(
    public toastr: ToastrService,
    private fb: UntypedFormBuilder,
    private database: LoadmoreDatabase,
    public router: Router,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private selectedSourcesService: SelectedSourcesService,
    private selectedSourcesDatabase: SelectedSourcesDatabase,
    private tenantBrowser: TenantBrowserService
  ) {

  }

  ngOnInit(): void {
    this.treeControl = this.tenantBrowser.treeControl;
    this.dataSource = this.tenantBrowser.dataSource;
    this.treeControlUtil = new TreeControlUtil(this.treeControl);

    this.selectedSourcesService.sourceDeselect$.subscribe((source: ObjectFlatNode) => {
      this.tenantBrowser.deselectSourceNodeById(source.id);
    });

    this.selectedSourcesService.seriesDeselect$.subscribe((seriesNode: ObjectFlatNode) => {
      this.tenantBrowser.deselectNodeById(seriesNode.id);
    });

    this.selectedSourcesService.seriesSelect$.pipe(takeUntil(this.ngDestroy)).subscribe((seriesNode: ObjectFlatNode) => {
      this.tenantBrowser.selectNodeById(seriesNode.id);
    });

    this.buildForm();
    this.searchManagedObject();
  }


  searchManagedObject() {
    const formValue = this.searchForm.value;

    this.isLoading = true;
    this.tenantBrowser.searchManagedObject(this.tenant, {
      text: formValue.keyword,
      query: formValue.query
    }, () => {
      this.isLoading = false;
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  transformer = this.tenantBrowser.transformer;

  getLevel = this.tenantBrowser.getLevel;

  isExpandable = this.tenantBrowser.isExpandable;

  hasChild = this.tenantBrowser.hasChild;

  isSeries = this.tenantBrowser.isSeries;

  isEvent =  this.tenantBrowser.isEvent;

  isLoadMore =  this.tenantBrowser.isLoadMore

  isDevice = (_nodeData: ObjectFlatNode) => {
    return _nodeData.managedObject && (_nodeData.managedObject.c8y_IsDevice || !_nodeData.managedObject.c8y_IsDeviceGroup);
  };

  loadMore(node: ObjectFlatNode) {
    this.tenantBrowser.loadMore(node, this.searchForm.value.onlyWithMeasurements);
  }

  loadChildren(node: ObjectFlatNode) {
    this.tenantBrowser.loadChildren(node, this.searchForm.value.onlyWithMeasurements);
  }

  todoLeafItemSelectionToggle(node: ObjectFlatNode): void {
    this.selectedTable = true;
    this.tenantBrowser.toggleSelectedNode(node);
    if (this.tenantBrowser.isSelected(node)) {
      this.selectedSourcesService.selectSeries(node);
    } else {
      this.selectedSourcesService.deselectSeries(node);
    }
  }

  public searchKeyup(event: any) {
    if (event.key === 'Enter') {
      this.searchManagedObject();
    }
  }

  isChecked(node: any): boolean {
    return this.tenantBrowser.isChecked(node);
  }

  private buildForm(): void {
    this.searchForm = this.fb.group({
      keyword: [''],
      query: [''],
      onlyWithMeasurements: this.fb.control(false)
    });


    this.searchForm.get('onlyWithMeasurements').valueChanges.subscribe((val) => {
      this.searchManagedObject();
    });
  }

  ngAfterViewChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

}


