import {AbstractControl, UntypedFormControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';

export function RequireCoords(control: AbstractControl): ValidationErrors|null  {
  const input = <UntypedFormControl>control;
  const parent = <UntypedFormGroup>input.parent;

  if (!parent) {
    return null;
  }

  if (parent.get('locationType').value !== 'COORDS') {
    return null;
  }

  if (!input.value) {
    return {
      required: true
    };
  }
}
