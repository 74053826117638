import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component, ElementRef,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild
} from '@angular/core';
import {SeriesType, StatusTypes} from '../../models/series';
import {UntypedFormGroup} from '@angular/forms';
import {SourceService} from '../../http/source.service';
import {SourceFormService} from './source-form.service';
import {Token, TokenPage} from '../../../core/model/person';
import {PersonService} from '../../../core/services/person.service';
import {
  debounceTime,
  switchMap,
  takeUntil
} from 'rxjs/operators';
import {CancelMappingsComponent} from './cancel/cancel-mappings.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {Subject} from 'rxjs/internal/Subject';
import {ToastrService} from 'ngx-toastr';
import {NavbarService} from '../navbar/navbar.service';
import {VirtualScrollerComponent} from 'ngx-virtual-scroller';
import {SourceItemRow} from './row/source-item-row.model';
import {SourceGridService} from './source-grid.service';
import {PaginationComponent} from 'ngx-bootstrap/pagination';
import {SourceGridConstants} from '../../models/source-grid-constants';
import {SourcePage} from '../../models/source';


@Component({
  selector: 'app-source-grid',
  templateUrl: './source-grid.component.html',
  styleUrls: ['source-grid.component.css'],
  providers: [SourceGridService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SourceGridComponent implements OnInit, OnDestroy, AfterViewInit {

  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public actionsTemplate: TemplateRef<any>;

  public activeSort: any;

  public searchForm: UntypedFormGroup = this.sourceGrid.searchForm;

  @Input()
  public mappedStatus: boolean;

  public rows = [];

  public isLoading = false;
  public affectedPendingRowsCount = 0;
  public selectedPendingRowsCount = 0;
  public allPendingRowsCount = 0;
  public selectedRowsCount = 0;
  public allRowsSelectedOnPage = false;
  public filteredTotal = 0;
  public total = 0;
  public tokens: Token[] = [];
  public itemsPerPage = SourceGridConstants.ITEMS_PER_PAGE;

  @ViewChild('cancelMappingsModal', { static: true })
  cancelMappingsModal: CancelMappingsComponent;

  @ViewChild('selectAllRows') selectAllRowsEl: ElementRef;

  public seriesTypes = SeriesType.types;
  public confirmationStatuses = StatusTypes.types;

  public filteredPageResponse$ = this.sourceGrid.filteredPageResponse$;
  public pageResponse$ = this.sourceGrid.pageResponse$;
  // Virtual scroller

  @ViewChild('listScroll')
  private listScroll: VirtualScrollerComponent;

  @ViewChild('listPaginationComponent')
  private listPaginationComponent: PaginationComponent;

  private fetchedPages = new Set<number>();
  public vsStartIndex = 0;
  pagesPerVsPage = 5;
  viewPortItems: any[] = [];
  bufferRows = SourceGridConstants.VS_BUFFER_ROWS;
  items: SourceItemRow[] = [];

  constructor(
    private cdRef: ChangeDetectorRef,
    private sourceService: SourceService,
    public personService: PersonService,
    public sourceFormService: SourceFormService,
    public toastr: ToastrService,
    public modalService: BsModalService,
    private navbarService: NavbarService,
    private sourceGrid: SourceGridService
  ) {
    this.sourceFormService.selectedControlArray$.pipe(
      switchMap(controlArray => controlArray.valueChanges),
      takeUntil(this.ngDestroy),
      debounceTime(500)
    ).subscribe((value) => {
      if (value.length) {
        this.sourceFormService.updateRowSourceItems(value);

        // FIXME: me ei taha iga trükkimise peale salvestada, sest kasutaja ei ole teadlik, mida salvcestatakse
        // Lisaks, kui on muutmiseks valitud 500 rida, siis tehakse 500 salvestust?
        /*
        if(!this.mappedStatus) {
          this.sourceService.saveSources(value).subscribe(() => {
              this.sourceGrid.bulkSource.next(false);
          });
        }*/
      }
    });

    // this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((page: SourcePage) => {
    //   // this.sourceFormService.setRows(page);
    //   // this.isLoading = false;
    //   this.items = Array.from({length:page.totalElements});
    //   let rows = this.sourceFormService.mapSourcesToRows(page.content);
    //   this.items.splice(0, page.size, ...rows);
    //   this.fetchedPages = new Set<number>();
    //   this.fetchedPages.add(0);
    //   this.total = page.totalElements;
    // }, (error) => {
    //   console.error(error);
    //   this.isLoading = false;
    // });

    this.sourceGrid.rows$.pipe(takeUntil(this.ngDestroy)).subscribe((rows: SourceItemRow[]) => {
      this.rows = rows;
    });

    this.sourceGrid.allRowsOnPageSelected$.pipe(takeUntil(this.ngDestroy)).subscribe((selected) => {
      this.allRowsSelectedOnPage = selected;
    });

    this.sourceGrid.bulkEditLimitExceeded.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.toastr.warning(`Bulk edit limit (${SourceGridConstants.BULK_EDIT_LIMIT}) exceeded. Try to limit search results or edit in batches.`);
    });

    this.filteredPageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((page: SourcePage) => {
      this.filteredTotal = page.totalElements;
    });

    this.pageResponse$.pipe(takeUntil(this.ngDestroy)).subscribe((page: SourcePage) => {
      this.total = page.totalElements;
    });
  }

  ngOnInit() {
    this.navbarService.setActionsTemplate(this.actionsTemplate);

    this.sourceGrid.bulkSource.next(false);

    this.sourceGrid.searchForm.valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.sourceGrid.allRowsOnPageSelectedSource.next(false);
      this.allRowsSelectedOnPage = false;
    });

    this.personService.getTokens({page: 1, size: 200}).pipe(takeUntil(this.ngDestroy)).subscribe((page: TokenPage) => {
      this.tokens.length = 0;
      page.content.forEach(value => this.tokens.push(value));
    });

    this.sourceGrid.selectedRows$.pipe(takeUntil(this.ngDestroy)).subscribe((selectedRowSet) => {
      this.selectedRowsCount = selectedRowSet.size;

      this.selectedPendingRowsCount = Array.from(selectedRowSet).filter(e => e.item.confirmationStatus === 'PENDING').length;

      this.sourceFormService.updateSelectedControls(Array.from(selectedRowSet));
      this.sourceGrid.refreshPageResults();
    });

    this.sourceGrid.loadPage(this.mappedStatus);
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.sourceGrid.ngOnDestroy();
    this.sourceFormService.ngOnDestroy();
  }

  ngAfterViewInit() {}

  sort(property) {
    let sorts = [];
    if (!this.activeSort || this.activeSort.prop !== property) {
      this.activeSort = {prop: property, dir: 'asc'};
    } else {
      if (this.activeSort.dir === 'asc') {
        this.activeSort.dir = 'desc';
      } else {
        //reset
        this.activeSort = null;
      }
    }

    if (this.activeSort) {
      sorts = [this.activeSort];
    }

    this.sourceGrid.sorts.next(sorts);
  }

  toggleTokenId(tokenId: number, value: any): void {
    let tokenIds = this.searchForm.get('tokenIds').value;
    if (!tokenIds) {
      tokenIds = [];
    }
    let idx = tokenIds.indexOf(tokenId);
    if (idx > -1) {
      tokenIds.splice(idx, 1);
    } else {
      tokenIds.push(tokenId);
    }
    this.searchForm.get('tokenIds').setValue(tokenIds);
  }

  toggleSelectAll(): void {
    if (!this.sourceGrid.canAddMore(this.rows.length)) {
      this.sourceGrid.bulkEditLimitExceeded.next();
      return;
    }

    if (this.rows.length === 0) {
      return;
    }

    this.toastr.info("Added " + this.rows.length + " source(s) for editing");
    this.sourceGrid.selectRows(this.rows);

    this.allRowsSelectedOnPage = this.rows.length == SourceGridConstants.ITEMS_PER_PAGE;

    if (this.allRowsSelectedOnPage) {
      setTimeout(() => {
        if (this.selectAllRowsEl) {
          this.selectAllRowsEl.nativeElement.scrollIntoView({behavior: 'smooth'});
        }
      });
    }


  }

  selectAll(): void {
    if (!this.sourceGrid.canAddMore(-1)) {
      this.sourceGrid.bulkEditLimitExceeded.next();
      return;
    }

    this.sourceGrid.selectAll();
  }

  cancel() {
    this.modalService.show(CancelMappingsComponent, {initialState: {
        mappings: this.sourceFormService.getPendingMappingData()
      }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
      this.sourceGrid.refreshPageResults();
    });
  }

  // public chartVsChange(event): void {
  //   if (event.startIndex < 0 || event.endIndex < 0) {
  //     return;
  //   }
  //
  //   this.vsStartIndex = event.startIndex;
  //
  //   console.log(event);
  //   // if(event.startIndex <= 1){
  //   //   if(this.chartPaginationComponent){
  //   //     this.chartPaginationComponent.selectPage(0);
  //   //   }
  //   //   if(this.listPaginationComponent){
  //   //     this.listPaginationComponent.selectPage(0);
  //   //   }
  //   // }
  //
  //
  //   /**
  //
  //    var arr = [0, ..., i, i+1, ..., n]
  //
  //    */
  //
  //   let startPage = Math.floor(event.startIndexWithBuffer / SourceGridConstants.ITEMS_PER_PAGE);
  //   let endPage = Math.floor((event.endIndexWithBuffer) / SourceGridConstants.ITEMS_PER_PAGE);
  //   if (startPage < 0) {
  //     startPage = 0;
  //   }
  //   if (endPage < 0) {
  //     endPage = 0;
  //   }
  //
  //   console.log("start " + startPage + "; end " + endPage);
  //
  //
  //   for (let i = startPage; i <= endPage; i++) {
  //     if (this.fetchedPages.has(i)) {
  //       continue;
  //     }
  //     this.fetchedPages.add(i);
  //     this.sourceGrid.loadMore(i).subscribe(([response, limit]) => {
  //
  //       const rows = this.sourceFormService.mapSourcesToRows(response.content).filter(r => !this.sourceGrid.isSelected(r));
  //       this.items.splice(i * limit, response.size, ...rows);
  //       this.fetchedPages.add(i);
  //
  //     });
  //   }
  // }

  pageChanged(event: any): void {
    this.sourceGrid.page.next({
      page: event.page - 1,
      limit: event.itemsPerPage
    });

    this.allRowsSelectedOnPage = false;
    // if (event.page == 1) {
    //   return;
    // }
    //
    // let indexToScroll = (event.page - 1) * this.sourceGrid.defaultLimit;
    // if (this.listScroll) {
    //   this.listScroll.scrollToIndex(indexToScroll);
    // }
  }

  scrollToTop(): void {
    window.scrollTo({left:0,top:0});
  }

}


