import {IdpAuthService} from '../idp-auth.service';
import {Injectable} from '@angular/core';
import {TokenAuthService} from '../token-auth.service';
import {fromPromise} from 'rxjs/internal-compatibility';
import {IdpCode} from '../idp-code';
import {LocationService} from '../../services/location.service';

@Injectable({
  providedIn: 'root'
})
export class DemoAuthService extends IdpAuthService {

  constructor(protected tokenAuthService: TokenAuthService,
              protected locationService: LocationService) {
    super(tokenAuthService);
  }

  authenticationCallback(params?: any): void {
  }

  doLogout(): Promise<boolean> {
    window.location.href = window.location.protocol + '://' + window.location.host;
    return Promise.resolve(true);
  }

  requestAuthentication(params?: any): void {
    fromPromise(this.tokenAuthService.ssoApiLogin({
      token: 'DEMO'
    })).subscribe((serverData: any) => {
      this.onAuthentication({redirectTo: this.buildForwardTo(this.locationService.getSiteUrl(), params?.targetUrlPath), result: true, reason: null});
    }, (err: any) => console.log(err));
  }

  getIdp(): IdpCode {
    return IdpCode.DEMO;
  }

  switchTo(idp: IdpCode) {
  }

  reset(): void {
  }

  private buildForwardTo(hostUrl: string, urlPath?: string): string {
    let url = hostUrl;
    if (urlPath) {
      if (urlPath.startsWith(url)) {
        url = urlPath;
      } else {
        url = url.indexOf(urlPath) == -1 ? url + (urlPath.startsWith("/") ? urlPath : "") : url;
      }
    }

    return url;
  }

}
