import {Component, EventEmitter, OnInit, Output, ViewEncapsulation} from '@angular/core';
import {Token, TokenPage} from '../../../core/model/person';
import {PersonService} from '../../../core/services/person.service';
import {environment} from '../../../../environments/environment';
import {LocationService} from '../../../core/services/location.service';

@Component({
  selector: 'app-token-setup-component',
  templateUrl: './token-setup.component.html',
  encapsulation: ViewEncapsulation.None
})
export class TokenSetupComponent implements OnInit {
  public documentationUrl = environment.apiUrl + '/swagger-ui.html';
  public documentationLabel = $localize`API Documentation`;
  public tokensPage: TokenPage;
  public token: Token = null;
  public rateLimitTokens = 1200;
  public rateLimitCost = 10;
  public rateLimitRpm = this.rateLimitTokens / this.rateLimitCost;
  public pageFilter: any = {
    page: 1,
    size: 2000
  };

  public dataSourceId = 'device-sensor-property-' + Math.round(Math.random() * 100);

  public sampleData = {
    data: [
      {
        dataSourceId: this.dataSourceId,
        deviceId: 'some-device-unique-id',
        values: [
          {
            time: '',
            unit: '%',
            value: 59
          }
        ]
      }
    ]
  };

  public sampleEventData = {
    data: [
      {
        dataSourceId: this.dataSourceId,
        deviceId: 'some-device-unique-id',
        values: [
          {
            time: '',
            value: 'some-string',
            someProperty: {
              someProperty: 'some-value'
            },
          }
        ]
      }
    ]
  };

  public sampleMetadata = {
    mappings: [
      {
        metaFields: {
          name: 'Demo datasource',
          groupingTag: 'BUILDINGS',
          seriesType: 'HEAT',
          locationType: 'ADDRESS',
          address: '123 Main St',
          apartment: '5',
          room: 'Living room',
          lat: 58.3749329,
          lng: 26.6625058,
          uuid: 'some-identifier-123',
          personCountryCode: environment.defaultCountryCode,
          personRegistrationNumber: '50001010006'
        },
        source: {
          deviceId: 'some-device-unique-id',
          dataSourceId: this.dataSourceId,
        }
      }
    ]
  };

  @Output()
  public continue: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    public personService: PersonService,
    private locationService: LocationService
  ) {
    const time = new Date();
    this.sampleData.data[0].values[0].time = time.toISOString();
    this.sampleData.data[0].values[0].value = Math.round(Math.random() * 10000) / 100;
  }

  ngOnInit() {
    this.loadPage();
  }

  loadPage() {
    this.personService.getTokens(this.pageFilter).subscribe(
      (data: TokenPage) => {
        this.tokensPage = data;

        if (data.content != null && data.content.length > 0) {
          this.token = data.content[0];
        }

        if (this.token == null) {
          this.createToken();
        }
      },
      (err: any) => {
        console.error(err);
      }
    );
  }

  createToken() {
    this.token = new Token();
    this.token.name = 'API key';
    this.personService.saveToken({
      token: this.token,
      siteUrl: this.locationService.getSiteUrl()
    }).subscribe(value => {
      this.token = value;
    });
  }

  next() {
    this.continue.next(this.token);
  }
}
