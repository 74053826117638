<div class="page-title-bar p-4">
  <div class="d-flex">
    <div class="col-2 pl-0">
      <a routerLink=".."><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1" *ngIf="mandate?.user && mandate?.person" i18n>Edit mandate</h2>
      <h2 class="mb-1" *ngIf="!mandate?.user || !mandate?.person" i18n>Create mandate</h2>
    </div>
    <div class="col-2 pr-0 d-flex justify-content-end">
    </div>
  </div>
</div>
<div class="container-fluid p-5">
  <div *ngIf="errorCode" class="alert alert-danger" role="alert">
    {{errorCodes[errorCode] ? errorCodes[errorCode] : errorCode}}
  </div>
  <div class="row mt-2" *ngIf="mandate" [formGroup]="mandateForm">
    <div class="col-lg-4">
      <div class="form-row">
        <div class="col">
          <label i18n [attr.for]="id + '-user'">Person</label>
          <ng-select
            [id]="id + '-user'"
            [class.is-invalid]="invalid('user')"
            [clearable]="true"
            [searchable]="true"
            appendTo="body"
            bindLabel="displayName"
            placeholder="Personal identification or registry code"
            i18n-placeholder
            addTagText="New person with registration number"
            i18n-addTagText
            [typeahead]="userTypeahead"
            [items]="users"
            [addTag]="createUser"
            [selectOnTab]="true"
            formControlName="user"
            id="personal-identification"
            class="custom"
            autofocus
          >
            <ng-template ng-label-tmp let-item="item">
              <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | personalId) + ')'}}</span>
            </ng-template>
            <ng-template ng-option-tmp let-item="item" let-search="searchTerm" let-index="index">
              <span>{{(item.displayName ? '' + item.displayName + ' ' : '') + '(' + (item | personalId) + ')'}}</span>
            </ng-template>
          </ng-select>

          <small *ngIf="invalid('user') && errors('user')?.required" class="invalid-feedback d-block" i18n>Required</small>
        </div>
      </div>
      <div class="form-group mt-3">
        <div class="form-row">
          <div class="col clearfix" *ngFor="let permission of mandateForm.get('permissions').controls; let i=index">
            <div class="checkbox">
              <input id="permissions" type="checkbox" [formControl]="permission" [value]="permissionsList[i].key">
              <label for="permissions">{{permissionsList[i].label}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="form-group">
        <div class="form-row">
          <div class="col-sm-6">
            <button type="button" (click)="saveMandate()" [ladda]="progress" class="btn btn-primary m-auto float-right" i18n>Save</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
