import {Inject, Injectable, OnDestroy} from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
  takeUntil,
  tap,
  withLatestFrom
} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {merge} from 'rxjs/internal/observable/merge';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {SourceService} from '../../http/source.service';
import {Observable} from 'rxjs/internal/Observable';
import {SourceFormService} from './source-form.service';
import {SourceItemRow} from './row/source-item-row.model';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {SourcePage} from '../../models/source';
import {SourceGridConstants} from '../../models/source-grid-constants';

@Injectable()
export class SourceGridService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  private mappedStatus = false;

  public defaultSorts = [{prop: 'metaFields.name', dir: 'asc'}];
  private refreshPageResultsPage = new Subject<void>();
  public page = new Subject<any>();
  public currentPage = 0;
  public queryParams: any = {};
  public page$ = this.page.asObservable().pipe(startWith({page: 0, limit: SourceGridConstants.ITEMS_PER_PAGE}));
  public sorts: Subject<any> = new Subject<any>();
  public limit: Subject<any> = new Subject<any>();
  private rows: SourceItemRow[] = [];
  private sourcePage: SourcePage;
  private filteredSourcePage: SourcePage;

  public selectedRowsSet: Set<SourceItemRow> = new Set<SourceItemRow>();

  public selectedRowsSource: Subject<Set<SourceItemRow>> = new ReplaySubject();
  public selectedRows$ = this.selectedRowsSource.asObservable().pipe(startWith(this.selectedRowsSet));

  public allRowsOnPageSelectedSource: Subject<boolean> = new ReplaySubject();
  public allRowsOnPageSelected$ = this.allRowsOnPageSelectedSource.asObservable();

  private allRowsSelected = false;

  public bulkSource = new ReplaySubject<boolean>();
  public bulk$ = this.bulkSource.asObservable();

  public bulkEditLimitExceeded: Subject<void> = new Subject<void>();

  public searchForm: UntypedFormGroup = SourceGridService.defaultSearchForm();

  private static defaultSearchForm(): UntypedFormGroup {

    return new UntypedFormGroup({
      source: new UntypedFormControl(''),
      tokenIds: new UntypedFormControl([]),
      name: new UntypedFormControl(''),
      groupingTag: new UntypedFormControl(''),
      seriesType: new UntypedFormControl(null),
      personCountryCode: new UntypedFormControl(''),
      personRegistrationNumber: new UntypedFormControl(''),
      location: new UntypedFormControl(''),
      confirmationStatus: new UntypedFormControl('')
    });
  }

  public searchFilter$ = combineLatest([
    this.searchForm.valueChanges.pipe(distinctUntilChanged(), debounceTime(250))
  ]).pipe(map(([searchFilter]: [any]) => {

    return {
      source: searchFilter.source ? searchFilter.source.trim() : searchFilter.source,
      tokenIds:  searchFilter.tokenIds,
      name:  searchFilter.name ? searchFilter.name.trim() : searchFilter.name,
      groupingTag:  searchFilter.groupingTag ? searchFilter.groupingTag.trim() : searchFilter.groupingTag,
      seriesType: searchFilter.seriesType ? searchFilter.seriesType.trim() : searchFilter.seriesType,
      personCountryCode:  searchFilter.personCountryCode ? searchFilter.personCountryCode.trim() : searchFilter.personCountryCode,
      personRegistrationNumber:  searchFilter.personRegistrationNumber ? searchFilter.personRegistrationNumber.trim() : searchFilter.personRegistrationNumber,
      location:  searchFilter.location,
      confirmationStatus:  searchFilter.confirmationStatus
    };
  }));

  public filterSortsLimit$ = combineLatest([
    this.searchFilter$,
    this.sorts.pipe(startWith(this.defaultSorts)),
    this.limit.pipe(startWith(SourceGridConstants.ITEMS_PER_PAGE))
  ]).pipe(shareReplay(1));

  public queryParams$ = merge(
    this.filterSortsLimit$.pipe(map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {page: 0, limit: limit}])),
    this.page$.pipe(
      withLatestFrom(this.filterSortsLimit$),
      map(([page, [filter, sorts, limit]]: [any, [any, any[], number]]) => [filter, sorts, limit, page])
    )
  ).pipe(
    map(([filter, sorts, limit, page]) => {
      this.currentPage = page.page;
      this.queryParams = this.mapQueryParams(filter, sorts, limit, page);
      return this.queryParams;
    }),
    shareReplay(1)
  );


  public pageResponse$ = combineLatest([
    this.queryParams$,
    this.refreshPageResultsPage.pipe(startWith(null as void))
  ])
    .pipe(
      switchMap(([queryParams,]: [any, any]) => {
        return this.fetchPage(queryParams)
      }),
      shareReplay(1),
      takeUntil(this.ngDestroy)
    );


  public filteredPageResponse$ = combineLatest([
    this.pageResponse$
  ]).pipe(
    map(([page]: [SourcePage]) => {
      this.sourcePage = page;
      const result = this.createFilteredPageResponse(page);
      this.filteredSourcePage = result;
      return result;
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  public rows$ = this.filteredPageResponse$.pipe(
    map((page: SourcePage) => {
      const rowsOnPage = page.content.splice(page.page * page.size, page.size);
      this.rows = this.sourceFormService.mapSourcesToRows(rowsOnPage);
      return this.rows;
    }),
    shareReplay(1),
    takeUntil(this.ngDestroy)
  );

  private createFilteredPageResponse(page: SourcePage): SourcePage {
    const sourceRows = this.sourceFormService.mapSourcesToRows(page.content);
    const filteredRows = sourceRows.filter((row: SourceItemRow) => {
      return !this.selectedRowsSet.has(row);
    }).map((sourceItemRow) => sourceItemRow.item);
    if (filteredRows.length <= this.currentPage * SourceGridConstants.ITEMS_PER_PAGE) {
      this.currentPage = 0;
    }
    let totalPages = Math.ceil(filteredRows.length / SourceGridConstants.ITEMS_PER_PAGE);
    let newSourcePage: SourcePage = new SourcePage();
    newSourcePage.page = this.currentPage;
    newSourcePage.content = filteredRows;
    newSourcePage.totalElements = filteredRows.length;
    newSourcePage.size = SourceGridConstants.ITEMS_PER_PAGE;
    newSourcePage.totalPages = totalPages;

    return newSourcePage;
  }

  constructor(private sourceService: SourceService, private sourceFormService: SourceFormService) { }

  ngOnDestroy() {
    this.selectedRowsSet.clear();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  loadPage(mappedStatus: boolean): void {
    this.mappedStatus = mappedStatus;
    this.searchForm.setValue(this.searchForm.getRawValue());
  }

  resetSelected(): void {
    this.currentPage = 0;
    this.deselectAll();
    this.refreshPageResults();
  }

  refreshPageResults(): void {
    this.refreshPageResultsPage.next();
  }

  private fetchPage(queryParams: any): Observable<SourcePage> {
    return this.sourceService.getSources(queryParams);
  }

  private mapQueryParams(filter: any, sorts: any[], limit: number, page: any) {
    if (filter.limit) {
      delete filter.limit;
    }
    const queryParams: any = Object.assign({
      notMapped: !this.mappedStatus,
      mapped: this.mappedStatus
    }, filter);

    // if (page) {
    //   queryParams.page = page.page;
    //   queryParams.size = page.limit;
    // }
    // if (limit) {
    //   queryParams.size = limit;
    // }

    if (sorts && sorts.length > 0) {
      queryParams.sort = sorts.map(s => s.prop + "," + s.dir)
        .join(",");
    }

    return queryParams;
  }

  loadMore(page: number): Observable<any> {

    return this.filterSortsLimit$.pipe(
      map(([filter, sorts, limit]: [any, any[], number]) => [filter, sorts, limit, {page: page, limit: limit}]),
      takeUntil(this.ngDestroy),
      take(1),
      map(([filter, sorts, limit, page]) => [this.mapQueryParams(filter, sorts, limit, page), limit]),
      switchMap(([queryParams, limit]) => this.fetchPage(queryParams).pipe(map(r => [r, limit])))
    );

  }

  isSelected(row: SourceItemRow): boolean {
    return this.selectedRowsSet.has(row);
  }

  selectRow(row: SourceItemRow): void {
    if (!this.selectedRowsSet.has(row)) {
      this.selectedRowsSet.add(row);
      this.selectedRowsSource.next(this.selectedRowsSet);
      this.allRowsOnPageSelectedSource.next(false);
    }
  }

  selectAll(): void {
    const allRows = this.sourceFormService.mapSourcesToRows(this.sourcePage.content);
    this.selectRows(allRows);
  }

  deselectAll(): void {
    this.deselectRows(Array.from(this.selectedRowsSet.values()));
  }

  selectRows(rows: SourceItemRow[]): void {
    for (let row of rows) {
      if (!this.selectedRowsSet.has(row)) {
        this.selectedRowsSet.add(row);
      }
    }

    this.selectedRowsSource.next(this.selectedRowsSet);
    this.allRowsSelected = true;
    this.allRowsOnPageSelectedSource.next(rows.length >= SourceGridConstants.ITEMS_PER_PAGE);
  }

  deselectRows(rows: SourceItemRow[]): void {
    for (let row of rows) {
      this.selectedRowsSet.delete(row);
      this.sourceFormService.rowsById.delete(row.item.id);
    }
    this.selectedRowsSource.next(this.selectedRowsSet);
    this.allRowsOnPageSelectedSource.next(false);
  }

  deselectRow(row: SourceItemRow): void {
    this.deselectRows([row]);
  }


  canAddMore(addCount: number): boolean {
    if (addCount < 0) {
      addCount = this.filteredSourcePage.totalElements;
    }
    return this.selectedRowsSet.size + addCount <= SourceGridConstants.BULK_EDIT_LIMIT;
  }

  sortSelectedRowSet(field, asc) {
    let selectedRows = Array.from(this.selectedRowsSet);
    selectedRows.sort((a: SourceItemRow, b: SourceItemRow) => {
      let result = b.item[field] - a.item[field];
      if (isNaN(result)) {

        return (asc ? a.item[field].localeCompare(b.item[field]) : b.item[field].localeCompare(a.item[field]))
      }

      return asc ? result : -result;
    });

    this.selectedRowsSet = new Set<SourceItemRow>(selectedRows);
    this.selectedRowsSource.next(this.selectedRowsSet);
  }

  removeRow(rowId: number): void {
    const row = this.sourceFormService.rowsById.get(rowId);
    if (!row) {
      return;
    }

    this.selectedRowsSet.delete(row);
    this.selectedRowsSource.next(this.selectedRowsSet);
  }

}
