import {Component, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {NavbarService} from '../navbar/navbar.service';

@Component({
  selector: 'app-mandate-page',
  templateUrl: './mandate-page.component.html'
})
export class MandatePageComponent implements OnInit {

  @ViewChild('navActions', {static: true})
  public actionsTemplate: TemplateRef<any>;


  constructor(private navbarService: NavbarService) { }

  ngOnInit(): void {
    this.navbarService.setActionsTemplate(this.actionsTemplate);
  }

}
