import {NgModule} from '@angular/core';
import {Routes, RouterModule} from '@angular/router';
import {TenantViewComponent} from './private/components/tenant/view/tenant-view.component';
import {MandateGridComponent} from './private/components/mandate/grid/mandate-grid.component';
import {MandateEditComponent} from './private/components/mandate/edit/mandate-edit.component';
import {LoginComponent} from './public/components/login/login.component';
import {SetupWizardComponent} from './private/components/setup-wizard/setup-wizard.component';
import {TokenViewComponent} from './private/components/token/view/token-view.component';
import {TokenGridComponent} from './private/components/token/grid/token-grid.component';
import {UnmappedSourcesComponent} from './private/components/unmapped-sources.component';
import {MappedSourcesComponent} from './private/components/mapped-sources.component';
import {TokenEditComponent} from './private/components/token/edit/token-edit.component';
import {AuthGuard} from './core/guards/auth.guard';
import {SourceMappingPageComponent} from './private/components/source-mapping-page/source-mapping-page.component';
import {Breadcrumb} from './private/components/navbar/breadcrumb';
import {AuthorizationsPageComponent} from './private/components/authorizations-page/authorizations-page.component';
import {AuthRedirectComponent} from './public/components/auth-redirect/auth-redirect.component';
import {AuthenticatedGuard} from './core/guards/authenticated.guard';
import {TokenAcceptComponent} from './private/components/token/accept/token-accept.component';
import {DashboardResolver} from './private/resolvers/dashboard.resolver';
import { SelectedSourcesUnsavedGuard } from './private/guards/selected-sources-unsaved-guard';
import {MappingLayoutComponent} from './private/components/layout/mapping-layout/mapping-layout.component';
import {FullLayoutComponent} from './private/components/layout/full-layout/full-layout.component';
import {MandatePageComponent} from './private/components/mandate-page/mandate-page.component';

const routes: Routes = [
  {path: '', redirectTo: '/mapping/setup', pathMatch: 'full'},
  {
    path: '',
    children: [
      {
        component: SourceMappingPageComponent,
        data: {breadcrumb: new Breadcrumb($localize`Source mapping`)},
        path: 'mapping',
        children: [
          { path: '', redirectTo: 'setup', pathMatch: 'full'},
          {
            path: '', component: MappingLayoutComponent,
            children: [
              {path: 'setup', component: SetupWizardComponent},
            ]
          },
          {
            path: '', component: FullLayoutComponent,
            children: [
              {path: 'source-grid', component: UnmappedSourcesComponent, resolve: {sources: DashboardResolver}},
              {path: 'mapped-source-grid', component: MappedSourcesComponent, canDeactivate: [SelectedSourcesUnsavedGuard]},
            ]
          },
        ]
      },
      {
        path: '',
        component: FullLayoutComponent,
        children: [
          {
            path: 'mandate',
            component: MandatePageComponent,
            data: {breadcrumb: new Breadcrumb($localize`Mandates`)},
            children: [
              {path: '', redirectTo: 'mandate-grid', pathMatch: 'full'},
              {path: 'mandate-grid', component: MandateGridComponent},
              {path: 'edit', component: MandateEditComponent},
            ]
          },
          {
            path: 'authorizations',
            component: AuthorizationsPageComponent,
            data: {breadcrumb: new Breadcrumb($localize`Authorizations`)},
            children: [
              {path: '', redirectTo: 'tenant', pathMatch: 'full'},
              {path: 'token', component: TokenGridComponent},
              {path: 'token/add', component: TokenEditComponent},
              {path: 'token/edit', component: TokenEditComponent},
              {path: 'token/view', component: TokenViewComponent},
              {path: 'token/accept/:acceptTokenUuid', component: TokenAcceptComponent},
              {path: 'tenant', component: TenantViewComponent}
            ]
          }
        ]
      }
    ],
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    redirectTo: 'login/login',
    pathMatch: 'full',
  },

  {
    path: 'login',
    children: [
      {path: 'login', component: LoginComponent}
    ],
    canActivate: [AuthenticatedGuard]
  },
  {
    path: 'auth',
    component: AuthRedirectComponent
  },
  {path: '**', redirectTo: '/mapping/setup'}
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: []
})
export class AppRoutingModule {
}
