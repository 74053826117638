function IsValidPrivatePersonRegistrationNumber (value: string) {
  const nin = (value || '0').split('').map(Number);
  const mod = (nin, weights) => nin.slice(0, 10).reduce((sum, num, i) => sum + num * weights[i]) % 11;
  return nin[10] === [mod(nin, [1,2,3,4,5,6,7,8,9,1]), mod(nin, [3,4,5,6,7,8,9,1,2,3]), 0].find(modulus => modulus !== 10)
}

export class PersonCodeValidator {

  private static allowedPersonCodes: string[] = ['DEMO_USER'];

  private static ALL_NUMBERS = /^\d+$/;

  public static validate(code: any, countryCode: string): boolean {
    if (code === null || code === '' || PersonCodeValidator.allowedPersonCodes.find((allowedCode) => code === allowedCode)) {
      return true;
    }

    if (!this.ALL_NUMBERS.test(code)) {
      return false;
    }

    if (countryCode === 'EE') {

      if (code.length === 8 && !isNaN(code)) {
        return true;
      }

      if (code.length === 11 && IsValidPrivatePersonRegistrationNumber(code)) {
        return true;
      }

      if (code === 'DEMO_USER') {
        return true;
      }

    }

    if (countryCode === 'DK') {
      // Private person and non-private person
      if ((code.length === 10 || code.length === 8) && !isNaN(code)) {
        return true;
      }

      // Hash
      if (code.length === 28) {
        return true;
      }

      if (code === 'DEMO_USER') {
        return true;
      }
    }

    return false;
  }
}
