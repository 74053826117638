import {Injectable, OnDestroy} from '@angular/core';
import { Router, Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';
import {PersonService} from '../../core/services/person.service';
import {TenantService} from '../../core/http/tenant.service';
import {TokenPage} from '../../core/model/person';
import {TenantPage} from '../../core/model/tenant';
import {map, takeUntil} from 'rxjs/operators';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {fromPromise} from 'rxjs/internal-compatibility';

@Injectable()
export class DashboardResolver implements OnDestroy, Resolve<any> {
  private ngDestroy = new Subject<void>();

  constructor(
    private personService: PersonService,
    private tenantService: TenantService,
    private router: Router
  ) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    return forkJoin([
      this.tenantService.getTenantsPage({page: 1, size: 1}),
      this.personService.getTokens({page: 1, size: 1})
    ]).pipe(
      takeUntil(this.ngDestroy),
      map(([tenants, tokens]: [TenantPage, TokenPage]) => {
        if (tenants.totalElements === 0 && tokens.totalElements === 0) {
          return fromPromise(this.router.navigate(['/', 'source']));
        } else {
          return null;
        }
      })
    );
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
