
  <div *ngIf="allRowsSelectedOnPage && !allRowsSelected" class="d-flex justify-content-center bg-light mb-4">
  <span class="text-muted p-4">
    <span>
      <span i18n>All sources ({{selectedRowsCount}}) are selected on this page.</span>&nbsp;
      <a [routerLink]="" (click)="selectAll()">Select all {{filteredTotal}} sources.</a>
    </span>
  </span>
  </div>

  <table class="table table-responsive-md btn-table table-mapping">
    <thead>
    <tr class="col-12">
      <th colspan="9">
        <div class="d-flex flex-row justify-content-between align-items-center mt-1">
          <h4 class="text-primary pl-2" i18n>Selected sources ({{selectedRowsCount}})</h4>
          <div class="d-flex flex-row">
            <ng-container>
              <button type="button" class="btn btn-danger btn-sm text-nowrap mr-3" (click)="startAction('delete')" *ngIf="!mappedStatus && selectedRowsCount > 0">
                <i class="fa fa-trash"></i>&nbsp;
                <ng-container i18n>Delete selected </ng-container> ({{affectedRowsCount}})
              </button>
              <button type="button" class="btn btn-outline-primary btn-sm mr-3" (click)="startBulkEdit()" *ngIf="!(bulkActive$ | async)">
                <i class="fa fa-edit"></i>&nbsp;
                <ng-container i18n>Bulk edit</ng-container>
              </button>
              <button type="button" class="btn btn-outline-primary btn-sm mr-3"  (click)="startAction('update')" *ngIf="(bulkActive$ | async)">
                <i class="fa fa-edit"></i>&nbsp;
                <ng-container *ngIf="mappedStatus" i18n>Fill</ng-container>
                <ng-container *ngIf="!mappedStatus" i18n>Save</ng-container>
              </button>
              <button (click)="startAction('cancel-pending')" *ngIf="mappedStatus"
                      class="btn btn-sm btn-danger text-nowrap mr-3"
                      type="button"
                      [disabled]="affectedPendingRowsCount == 0"
              >
                <ng-container i18n>Cancel pending mappings ({{affectedPendingRowsCount}})</ng-container>
              </button>
              <button (click)="startAction('confirm')"
                      class="btn btn-sm btn-primary text-nowrap"
                      type="button"
                      [disabled]="affectedRowsCount === 0"
              >
                <ng-container *ngIf="mappedStatus" i18n>Save and send for confirmation </ng-container>
                <ng-container *ngIf="!mappedStatus" i18n>Send for confirmation </ng-container>
                ({{affectedRowsCount}})
              </button>

              <button *ngIf="mappedStatus && selectedRowsCount > 0" class="btn btn-danger btn-sm ml-3" (click)="startAction('cancel-confirmation')" type="button" i18n>Send for cancellation ({{affectedRowsCount}})</button>
            </ng-container>
          </div>
        </div>
      </th>
    </tr>
    <tr>
      <ng-template #sortIcon>
        <i class="fa fa-sort text-muted"></i>
      </ng-template>
      <th class="align-middle">
        <div class="checkbox">
          <input type="checkbox" [formControl]="toggleAllRowsSelectedControl" i18n-matTooltip matTooltip="Remove all selected">
        </div>
      </th>
      <th colspan="2">
        <span (click)="sort('seriesPath')" class="mr-1" i18n>Source information</span>
        <i *ngIf="pageFilter.sort == 'seriesPath'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
      </th>
      <th>
        <span (click)="sort('metaFields.name')" class="mr-1" i18n>Name</span>
        <i *ngIf="pageFilter.sort == 'metaFields.name'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        <i class="fa fa-info-circle btn-tooltip float-right mt-1" [popover]="'Human readable source name, e.g. Kitchen heat sensor'" placement="right" triggers="mouseenter:mouseleave" ></i>
      </th>
      <th><span (click)="sort('metaFields.groupingTag')" class="mr-1" i18n>Grouping tag</span>
        <i *ngIf="pageFilter.sort == 'metaFields.groupingTag'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        <i class="fa fa-info-circle btn-tooltip float-right mt-1" [popover]="'Grouping tag will be used in the City Portal for data presentation'" placement="top" triggers="mouseenter:mouseleave" ></i>
      </th>
      <th><span (click)="sort('metaFields.seriesType')" class="mr-1" i18n>Series type</span>
        <i *ngIf="pageFilter.sort == 'metaFields.seriesType'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        <i class="fa fa-info-circle btn-tooltip float-right mt-1" [popover]="'Series type will be used in the City Portal for data presentation'" placement="top" triggers="mouseenter:mouseleave" ></i>
        [popover]
      <th><span (click)="sort('metaFields.personRegistrationNumber')" class="mr-1" i18n>Owner reg no</span>
        <i *ngIf="pageFilter.sort == 'metaFields.personRegistrationNumber'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        <i class="fa fa-info-circle btn-tooltip float-right mt-1" [popover]="'Owner registration number or personal code who will be datapoint owner. After source mapping datapoint owner will get request for accepting data.'" placement="top" triggers="mouseenter:mouseleave" ></i>
      </th>
      <th><span (click)="sort('metaFields.location')" class="mr-1" i18n>Location</span>
        <i *ngIf="pageFilter.sort == 'metaFields.location'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
        <i class="fa fa-info-circle btn-tooltip float-right mt-1" [popover]="'Datapoint physical location. For buildings should be used aaddress, e.g. Pepleri street 12, 3, Kitchen. Also aaddress will be used in the City Portal for data presentation.'" placement="top" triggers="mouseenter:mouseleave" ></i>
      </th>
      <th><a (click)="sort('metaFields.confirmationStatus')" i18n>Status</a>
        <i *ngIf="pageFilter.sort == 'metaFields.confirmationStatus'; else sortIcon"
           [class]="pageFilter.order == 'asc' ? 'fa fa-sort-down': 'fa fa-sort-up'"></i>
      </th>
    </tr>
    </thead>
    <tbody [class]="(bulkActive$ | async) ? 'table-bulk-active': ''">
      <tr *ngIf="(bulkActive$ | async)" app-sources-bulk-edit class="bulk-edit-active" [mappedStatus]="mappedStatus"></tr>
      <ng-container *ngFor="let row of (selectedRows$ | async)">
        <selected-source-item-row [row]="row" [mappedStatus]="mappedStatus"></selected-source-item-row>
      </ng-container>
    </tbody>
  </table>

