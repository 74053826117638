<ng-container [formGroup]="form">
  <input formControlName="address"
         type="text"
         class="form-control mt-1"
         placeholder="Address" i18n-placeholder
  >
  <b *ngIf="submitted && form.get('address').invalid" class="text-danger">*</b>
  <input formControlName="apartment"
         type="text" class="form-control mt-1"
         placeholder="Apartment" i18n-placeholder
  >
  <input formControlName="room"
         type="text" class="form-control mt-1"
         placeholder="Room" i18n-placeholder>
</ng-container>
