import {Executor} from '../../../../../core/executors/executor';
import {ManagedObjectSearch} from '../../../../models/tenant-mapping/managed-object-search';
import {CumuService} from '../../../../http/cumu.service';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {ManagedObject} from '../../../../models/tenant-mapping/managed-object';
import {ManagedObjectsResponse} from '../../../../models/tenant-mapping/managed-objects-response';
import {AbstractSourceDatabase, CreateSearchParams, LoadManagedObjectsResult} from '../abstract-source-database';
import {EMPTY, Observable} from 'rxjs/index';
import {expand, toArray} from 'rxjs/operators';
import {TenantBrowserConstants} from '../../../../models/tenant-browser-constants';
import {ObjectSearchParams} from '../../../../models/tenant-mapping/object-search-params';
import {ObjectNode} from '../../../../models/tenant-mapping/object-node';
import {NodeType} from '../../../../models/tenant-mapping/node-type';
import {FilterParams} from '../../../../models/tenant-mapping/filter-params';
import {ManagedObjectNodeStore} from '../store/managed-object-node-store';
import {SearchParams} from '../../../../models/tenant-mapping/search-params';

export class LoadManagedObjectsExecutor implements Executor<Function, void> {

  private database: AbstractSourceDatabase;

  private readonly  cumuService: CumuService;

  private readonly tenantId: number;

  protected filterParams: FilterParams;

  protected overallSearch: ManagedObjectSearch;

  protected textSearch: ManagedObjectSearch;

  protected wildcardSearch: ManagedObjectSearch;

  protected manageObjectNodeStore: ManagedObjectNodeStore;

  private namedQuery = true;

  private deviceGroupQuery = true;

  constructor(database: AbstractSourceDatabase,
              tenantId: number,
              manageObjectNodeStore: ManagedObjectNodeStore,
              cumuService: CumuService,
              searchParams: SearchParams) {
    this.database = database;
    this.tenantId = tenantId;
    this.cumuService = cumuService;
    this.manageObjectNodeStore = manageObjectNodeStore;
    this.namedQuery = true;
    this.deviceGroupQuery = true;

    this.filterParams = new FilterParams();
    this.filterParams.text = searchParams.text;
    this.filterParams.query = searchParams.query;
  }

  execute(cb?: (children: ObjectNode[], completed: boolean) => void): void {
    let children = this.database.data;
    let showMoreNodeId = null;
    if (children && children.length > 0) {
      const lastChild = children[children.length - 1];
      if (lastChild.name === 'LOAD_MORE') {
        showMoreNodeId = lastChild.id;
      }
    }

    this.doLoadManagedObjects({
      keyword: this.filterParams.text
    }).subscribe((result: LoadManagedObjectsResult) => {

      for (let managedObject of result.items) {
        if (managedObject.type != ManagedObject.TYPE_DAL_AGGREGATION &&
          !this.manageObjectNodeStore.has(managedObject.id)) {
          children.push(this.manageObjectNodeStore.createFromManagedObject(managedObject));
        }
      }

      if (showMoreNodeId != null) {
        children = children.filter(c => c.id !== showMoreNodeId);
        this.manageObjectNodeStore.delete(showMoreNodeId);
      }

      if (result.showLoadMore) {
        const loadMoreNode = new ObjectNode(
          '-1',
          NodeType.NODE,
          'LOAD_MORE',
          null,
          null,
          false,
          null
        );
        this.manageObjectNodeStore.set('-1', loadMoreNode);
        children.push(loadMoreNode);
      }

      cb(children, !result.showLoadMore);
    });
  }

  private getManagedObjects(search: ManagedObjectSearch): any {
    return this.cumuService.searchManagedObjects(this.tenantId, search.params);
  }

  private doLoadManagedObjects(params: { keyword: string }): Observable<LoadManagedObjectsResult> {

    return new Observable<LoadManagedObjectsResult>(observer => {

      if (params.keyword) {
        if (!this.textSearch) {
          this.textSearch = this.createSearch({
            text: params.keyword,
            wildcardSearch: false,
            deviceGroupQuery: false,
            namedQuery: false
          });
        } else {
          this.textSearch.params.currentPage++;
        }

        if (!this.wildcardSearch) {
          this.wildcardSearch = this.createSearch({
            text: params.keyword,
            wildcardSearch: true,
            deviceGroupQuery: false,
            namedQuery: false
          });
        } else {
          this.wildcardSearch.params.currentPage++;
        }

        forkJoin([
          this.doRecursiveSearch(this.textSearch),
          this.doRecursiveSearch(this.wildcardSearch)
        ]).subscribe(([textSearchResponses, wildCardSearchResponses]) => {
          let loadMore = false;
          let managedObjects: ManagedObject[] = [];
          for (let response of textSearchResponses) {
            managedObjects.push(...response.managedObjects);
            if (response.statistics.currentPage < response.statistics.totalPages) {
              loadMore = true;
            }
          }
          for (let response of wildCardSearchResponses) {
            managedObjects.push(...response.managedObjects);
            if (response.statistics.currentPage < response.statistics.totalPages) {
              loadMore = true;
            }
          }

          observer.next({
            showLoadMore: loadMore,
            items: managedObjects
          });
        });

      } else {
        if (!this.overallSearch) {
          this.overallSearch = this.createSearch({
            wildcardSearch: false,
            deviceGroupQuery: true,
            namedQuery: true
          });
        } else {
          this.overallSearch.params.currentPage++;
        }

        this.doRecursiveSearch(this.overallSearch).subscribe((responses: ManagedObjectsResponse[]) => {
          let loadMore = false;
          let managedObjects: ManagedObject[] = [];
          for (let response of responses) {
            managedObjects.push(...response.managedObjects);
            if (response.statistics.currentPage < response.statistics.totalPages) {
              loadMore = true;
            }
          }

          observer.next({
            showLoadMore: loadMore,
            items: managedObjects
          });

        });
      }

    });
  }

  public doRecursiveSearch(search: ManagedObjectSearch): Observable<ManagedObjectsResponse[]> {

    if (!search.next) {
      //TODO: kui ei ole enam eelmisest otsingust kirjeid, siis ei ole mõtet järgmine iteratsioon uusi päringuid teha
      //response.statistics.currentPage >= response.statistics.totalPages
    }

    return this.getManagedObjects(search).pipe(expand((response: ManagedObjectsResponse) => {
        let next = false;

        let params: CreateSearchParams = {
          wildcardSearch: search.wildCardSearch,
          text: search.text,
          namedQuery: search.namedQuery,
          deviceGroupQuery: search.deviceGroupQuery
        };

        if (response.statistics.currentPage >= response.statistics.totalPages) {
          if (params.namedQuery) {
            params.namedQuery = false;
            next = true;
          } else if (params.deviceGroupQuery) {
            params.deviceGroupQuery = false;
            next = true;
          }
        }

        search.next = next;

        if (next) {
          return this.getManagedObjects(this.extendSearch(search, params));
        }

        return EMPTY;
      }),
      toArray());

  }

  public extendSearch(currentSearch: ManagedObjectSearch, params: CreateSearchParams) {
    let deviceGroupQuery = params.deviceGroupQuery;
    let queryParts: string[] = [];
    let searchParams = currentSearch.params;

    if (!params.text) {
      if (deviceGroupQuery) {
        queryParts.push(TenantBrowserConstants.IS_DEVICE_GROUP_QUERY_PART);
      } else {
        if (currentSearch && currentSearch.deviceGroupQuery) {
          currentSearch.deviceGroupQuery = false;
          searchParams.currentPage = 0;
        }
        queryParts.push(TenantBrowserConstants.IS_DEVICE_QUERY_PART);
      }

      searchParams.onlyRoots = true;
    }

    this.applyExcludeTypes(queryParts);

    if (queryParts.length > 0) {
      searchParams.query = `$filter=${queryParts.join(' and ')}`;
    }

    searchParams.currentPage++;

    currentSearch.params = searchParams;

    return currentSearch;

  }

  private applyExcludeTypes(queryParts: string[]): void {
    let excludeQueryParts = TenantBrowserConstants.EXCLUDE_DEVICE_TYPES.map(type => 'not (type eq ' + type + ')');
    if (excludeQueryParts.length > 0) {
      queryParts.push(excludeQueryParts.join(' and '));
    }
  }

  public createSearch(params: CreateSearchParams): ManagedObjectSearch {

    let searchParams = new ObjectSearchParams();

    let queryParts: string[] = [];

    let deviceGroupQuery = params.deviceGroupQuery;

    // text based search
    if (params && params.text) {
      if (!params.wildcardSearch) {
        searchParams.text = params.text;
      } else {
        queryParts.push(`name eq '*${params.text}*'`);
      }

    } else {
      queryParts.push(TenantBrowserConstants.IS_DEVICE_GROUP_QUERY_PART);

      searchParams.onlyRoots = true;
    }

    this.applyExcludeTypes(queryParts);

    if (queryParts.length > 0) {
      searchParams.query = `$filter=${queryParts.join(' and ')}`;
    }

    searchParams.currentPage++;

    let search = new ManagedObjectSearch({
      params: searchParams,
      deviceGroupQuery: deviceGroupQuery,
      wildcardSearch: params.wildcardSearch,
      text: params.text
    });

    return search;
  }

}
