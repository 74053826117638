import {Tenant} from '../../core/model/tenant';
import {SourceAssetsFlatTree} from './series';

export class SourcePage {
  content: SourceGridItemDto[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class SourceGridItemDto {
  id: number;
  sourceName: string;
  name: string;
  seriesType: string;
  sourceType: string;
  sourceUuid: string;
  measurement: SourceMeasurementDto;
  seriesPath: string;
  locationType: string;
  addressAdsoid: string;
  appartmentAdsoid: string;
  address: string;
  apartment: string;
  adsOid: string;
  room: string;
  lat: number;
  lng: number;
  uuid: string;
  groupingTag: string;
  personCountryCode: string;
  personRegistrationNumber: string;
  tenant: Tenant;
  token: Token;
  objectId: string;
  confirmationStatus: string;
  lastValue: any;
  sourceAssetsTree: SourceAssetsFlatTree;
}

export class Token {
  id: number;
  name: string;
  value: string;
}

export class SourceMeasurementDto {
  id: number;
  uuid: string;
}

export class SourceMapping {
  id: number;
  name: string;
  groupingTag: string;
  seriesType: string;
  personCountryCode: string;
  personRegistrationNumber: string;
  locationType: string;
  adsOid: string;
  address: string;
  apartment: string;
  room: string;
  lat: number;
  lng: number;
  uuid: string;
  assignerComment: string;
  periodStartType: string;
}


export class SupportedSourcesResponse {
  events: SupportedEvent[];
  series: SupportedSeries[];
}

export class SupportedEvent {
  id: number;
  eventType: string;
}

export class SupportedSeries {
  id: number;
  seriesPath: string;
}
