import {environment as config} from '../../../../environments/environment';
import {Injectable} from '@angular/core';
import {RedirectStrategy} from '../redirect/redirect-strategy';
import {TaraAuthRedirectStrategy} from './tara-auth-redirect-strategy';
import {TaraLogoutRedirectStrategy} from './tara-logout-redirect-strategy';
import {LocationService} from '../../services/location.service';
import {LogoutCallback} from '../logout-callback';
import {IdpAuthService} from '../idp-auth.service';
import {UrlBuilder} from '../../utils/url-builder';
import {TokenAuthService} from '../token-auth.service';
import {IdpCode} from '../idp-code';
import { Observable } from 'rxjs/internal/Observable';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TaraAuthService extends IdpAuthService {

  private taraLogoutRedirectStrategy: RedirectStrategy;

  private taraAuthRedirectStrategy: RedirectStrategy;

  private taraMessageHandler = null;

  constructor(protected http: HttpClient,
              protected tokenAuthService: TokenAuthService,
              protected locationService: LocationService) {
    super(tokenAuthService);
  }

  private init() {
    this.taraAuthRedirectStrategy = new TaraAuthRedirectStrategy(config, this.locationService.getSiteUrl());
    this.taraLogoutRedirectStrategy = new TaraLogoutRedirectStrategy(config);
  }

  public getTaraRedirectStrategy(): RedirectStrategy {
    if (!this.taraAuthRedirectStrategy) {
      this.init();
    }
    return this.taraAuthRedirectStrategy;
  }

  requestAuthentication(params?: any): void {
    if (!this.taraAuthRedirectStrategy) {
      this.init();
    }
    this.taraAuthRedirectStrategy.redirect(params);
  }

  authenticationCallback(urlParams?: any) {
    console.log('auth callback', urlParams);
    let params = {};
    if (urlParams) {
      params = Object.assign(params, urlParams);
    }

    window.location.href = UrlBuilder.build(config.apiUrl, '/tara/login', params);
    // this.requestToken(params).subscribe((result) => {
    //   console.log('result', result);
    //
    //   this.onTaraLogin(result);
    // });
    /**
     *     try {
     *       await this.tokenAuthService.tokenLogin(event.data.token);
     *
     *       this.onAuthentication({redirectTo: event.data.redirectTo, result: true, reason: null});
     *     } catch (e) {
     *       this.onAuthentication({redirectTo: event.data.redirectTo, result: false, reason: e});
     *     }
     */
  }

  public requestToken(params: any): Observable<any> {
    return this.http.get(UrlBuilder.build(config.apiUrl, '/tara/login', params), {
      params: params
    });
  }


  private async onTaraLogin(result: any): Promise<void> {
    if (!result.token) {
      return;
    }

    try {
      await this.tokenAuthService.tokenLogin(result.token);

      this.onAuthentication({redirectTo: result.redirectTo, result: true, reason: null});
    } catch (e) {
      this.onAuthentication({redirectTo: result.redirectTo, result: false, reason: e});
    }
  }

  doLogout(params?: any): Promise<boolean> {
    if (!this.taraLogoutRedirectStrategy) {
      this.init();
    }
    return this.taraLogoutRedirectStrategy.redirect({token: params.token});
  }

  getIdp(): IdpCode {
    return IdpCode.TARA;
  }

  switchTo(idp: IdpCode) {
  }

  reset(): void {
  }

}
