import {UriBuilder} from '../../utils/uri-builder';
import {TaraAuthLogoutUriBuilder} from './tara-auth-logout-uri-builder';
import {AbstractRedirectStrategy} from '../redirect/abstract-redirect-strategy';

export class TaraLogoutRedirectStrategy extends AbstractRedirectStrategy {

  constructor(private config: any) {
    super();
    let redirectUriBuilder = new TaraAuthLogoutUriBuilder(config.apiUrl);
    this.setRedirectUriBuilder(redirectUriBuilder);
  }

  getRedirectUriBuilder(): UriBuilder {
    return this.redirectUriBuilder;
  }

}
