import {Component, HostListener} from '@angular/core';
import { SourceFormService } from './source-grid/source-form.service';

@Component({
  selector: 'app-mapped-sources',
  templateUrl: './mapped-sources.component.html'
})
export class MappedSourcesComponent {

  @HostListener('window:beforeunload', ['$event'])
  unloadNotification($event: any) {
    if (!this.canDeactivate()) {
      $event.returnValue = true;
    }
  }

  constructor(private sourceFormService: SourceFormService) {}

  public canDeactivate() {

    return !this.sourceFormService.isFormTouched();
  }
}
