import {Component, EventEmitter, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormGroup, UntypedFormControl, Validators} from '@angular/forms';
import {SourceService} from '../../../http/source.service';
import {ToastrService} from 'ngx-toastr';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'confirm-cancellation-modal',
  templateUrl: './confirm-cancellation.component.html'
})
export class ConfirmCancellationComponent implements OnInit {
  public formGroup: UntypedFormGroup;
  public isLoading = false;
  public submitted = false;

  @Output('saved')
  savedEmitter: EventEmitter<any> = new EventEmitter<any>();

  public mappings: any[];
  public mappedMappings: any[] = [];
  public bulkUpdate = false;
  public filter: any = {};
  public mapped = false;

  constructor(
    public toastr: ToastrService,
    public sourceService: SourceService,
    private modalRef: BsModalRef
  ) {
    this.formGroup = new UntypedFormGroup({
      comment: new UntypedFormControl(null, Validators.required)
    });
  }

  ngOnInit() {
    this.creatUiMap();
  }

  close() {
    this.modalRef.hide();
  }

  confirm() {
    this.submitted = true;
    if (!this.formGroup.valid) {
      return;
    }
    const formValue = this.formGroup.value;

    this.isLoading = true;

      const toastrRef = this.toastr.info($localize`Cancellations sent`, null, {
        disableTimeOut: true
      });
      this.sourceService.cancelConfirmedMappings(this.mappings, formValue.comment).subscribe((response) => {

        if(response.results && response.results.length > 0) {
          this.toastr.success($localize`Sent`);
        }

        if (response.errors && response.errors.length > 0) {
          let failedMessage = $localize`Some of the mapping cancellations failed:`;
          failedMessage += "<br />";
          failedMessage += response.errors.map((source: any) => {
            return source.name + ": " + source.message;
          }).join("<br />");
          this.toastr.error(failedMessage, null, {
            enableHtml: true,
            disableTimeOut: true
          });
        }

        this.toastr.clear(toastrRef.toastId);

        this.isLoading = false;
        this.savedEmitter.next(true);
        this.close();
      }, (err) => {
        let errorMessage = (err.error.message ? err.error.message : $localize`Something went wrong!`);
        this.toastr.error(errorMessage);
        this.isLoading = false;
      });

  }

  creatUiMap() {
    if (this.bulkUpdate) {
      this.mappings = [];
    }
    const map: any = {};

    for (const value of this.mappings) {
      if (!map[value.personCountryCode + '-' + value.personRegistrationNumber]) {
        map[value.personCountryCode + '-' + value.personRegistrationNumber] = {
          countryCode: value.personCountryCode,
          personRegistrationNumber: value.personRegistrationNumber,
          values: []
        };
      }
      map[value.personCountryCode + '-' + value.personRegistrationNumber].values.push(value);
    }

    this.mappedMappings.length = 0;
    for (const i of Object.keys(map)) {
      this.mappedMappings.push({
        countryCode: map[i].personCountryCode,
        personRegistrationNumber: map[i].personRegistrationNumber,
        values: map[i].values
      });
    }
  }
}
