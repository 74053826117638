import {ActivatedRoute, Router} from '@angular/router';
import {ChangeDetectorRef, Component, EventEmitter, OnInit} from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
} from '@angular/forms';
import {PersonService} from '../../../../core/services/person.service';
import {Token, TokenPerson} from '../../../../core/model/person';
import {ToastrService} from 'ngx-toastr';
import {UserService} from '../../../../core/http/user.service';
import {PersonCodeControlValidator} from '../../../../core/validators/form/control/person-code-control-validator.service';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {BsModalService} from 'ngx-bootstrap/modal';
import {LocationService} from '../../../../core/services/location.service';

const selector = 'app-token-edit';
let nextId = 0;

@Component({
  selector: selector,
  templateUrl: './token-edit.component.html'
})
export class TokenEditComponent implements OnInit {
  id = `${selector}-${nextId++}`;

  public progress = false;
  public token: Token = new Token();
  public tokenForm: UntypedFormGroup;
  public persons = [];
  public personTypeahead$ = new EventEmitter<string>();
  private currentContact: TokenPerson = null;

  constructor(
    private route: ActivatedRoute,
    public router: Router,
    private personService: PersonService,
    public toastr: ToastrService,
    private cd: ChangeDetectorRef,
    private fb: UntypedFormBuilder,
    private userService: UserService,
    private personCodeControlValidator: PersonCodeControlValidator,
    private modalService: BsModalService,
    private locationService: LocationService
  ) {
  }

  public selectTokenPerson(tokenPerson: TokenPerson): void {
    this.token.tokenPersons.push(tokenPerson);
  }

  public removeTokenPerson(tokenPerson: TokenPerson): void {
    let idx = this.token.tokenPersons.indexOf(tokenPerson);
    if (idx != -1) {
      this.token.tokenPersons.splice(idx, 1);
    }
  }
  check(event): void {
    let assigned = this.tokenForm.get("assigned").value;

    if (assigned && this.currentContact) {
      let modalDescription = $localize`You are about to change shared API to regular API key. Please make sure it hasn't been shared to anyone who could potentially misuse API access with more rights.`;
      event.preventDefault();
      const modalRef = this.modalService.show(ConfirmModalComponent, {
        initialState: {
          description: modalDescription,
          callback: (result: boolean) => {
            if (result !== false) {
              this.tokenForm.get("assigned").setValue(!assigned);
            }
          }
        }
      });
    } else {
      this.tokenForm.get("assigned").setValue(!assigned);
    }
  }


  ngOnInit () {
    this.tokenForm = this.fb.group({
      name: [''],
      assigned: new UntypedFormControl(false)
    });
    this.listenUrl();
  }

  listenUrl(): void {
    this.route.params.subscribe(
      (params: any) => {
        if (params.id) {
          this.personService.getToken(params.id).subscribe(
            (token: Token) => {
              this.token = token;
              this.reset(token);
            },
            (sErr: any) => {
              this.toastr.error($localize`Error on receiving mandate!`);
            }
          );
        }
      });
  }

  private reset(token: Token) {
    let assigned = (!token.primary ? token.assigned : false);
    this.tokenForm.reset({
      name: token.name,
      assigned: assigned
    });

    if (token.assigned || token.primary) {
      this.tokenForm.get('assigned').disable();
    }
  }

  save() {
    if (this.tokenForm.invalid) {
      console.error("Form invalid");
      console.log(this.tokenForm);
      return;
    }

    const formValue = this.tokenForm.getRawValue();
    let token = this.token;
    token.name = formValue.name;

    let assigned = formValue.assigned;
    if (!token.assigned && !token.tokenPersons.length) {
      assigned = false;
    }
    token.assigned = assigned;
    this.progress = true;
    if (token.id) {
      this.saveToken(token.id, token);
    } else {
      this.createToken(token);
    }
  }

  private createToken(token: Token): void {

    this.personService.saveToken({
      token: token,
      siteUrl: this.locationService.getSiteUrl()
    }).subscribe(
      (token: any) => {
        this.toastr.success($localize`API key created`);
        this.router.navigate(['/authorizations/token']);
      },
      (err: any) => {
        this.progress = false;
        this.toastr.error($localize`Token save failed!`);
      }, () => this.progress = false
    );
  }

  private saveToken(id: number, token: Token): void {

    this.personService.updateToken(id, {
      token: token,
      siteUrl: this.locationService.getSiteUrl()
    }).subscribe(
      (token: any) => {
        this.toastr.success($localize`API key saved`);
        this.router.navigate(['/authorizations/token']);
      },
      (err: any) => {
        this.progress = false;
        this.toastr.error($localize`Token save failed!`);
      }, () => this.progress = false
    );
  }

}
