import {GenericUriBuilder} from '../redirect/generic-uri-builder';

export class NemLogInLogoutUriBuilder extends GenericUriBuilder{

  constructor(baseUrl: string) {
    super(baseUrl, "/saml/logout");
  }

  processBuildParams(params?: any): void {
  }


}
