import {ChangeDetectorRef, Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient, JsonpClientBackend} from '@angular/common/http';
import {catchError, debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {combineLatest} from 'rxjs/internal/observable/combineLatest';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Observable} from 'rxjs/internal/Observable';
import {Subject} from 'rxjs/internal/Subject';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {of} from 'rxjs/internal/observable/of';

@Component({
  selector: 'app-address-ads',
  templateUrl: './address-ads.component.html',
  styleUrls: ['./address-ads.component.css']
})
export class AddressAdsComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  private jsonpClient: HttpClient;

  @Input()
  form: UntypedFormGroup;

  @Input()
  submitted: boolean;

  public searching = false;
  public adsAddress = new UntypedFormControl();
  public adsApartment = new UntypedFormControl(null);
  private apartments$ = new ReplaySubject(1);

  addressSearch(text$: Observable<string>) {
    return text$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      tap(() => {
        this.searching = true;
        this.cd.markForCheck();
      }),
      switchMap((text: string) => this.jsonpClient.jsonp(
        'https://inaadress.maaamet.ee/inaadress/gazetteer?results=5&appartment=1&features=EHITISHOONE&address='
        + encodeURI(text), 'callback')),
      catchError(() => {
        return of(false);
      }),
      map((results: any) => {
        if (!results.addresses) {
          return [];
        }
        return results.addresses;
      }),
      tap(() =>  {
        this.searching = false;
        this.cd.markForCheck();
      })
    );
  }

  apartmentSearch(text$: Observable<string>) {
    return combineLatest([text$.pipe(distinctUntilChanged()), this.apartments$]).pipe(
      tap(() => this.searching = true),
      map(([text, apartments]: [string, any[]]) => {
        if (!apartments || apartments.length < 1) {
          return [];
        }
        return apartments
          .map((apartment) => apartment.tahis)
          .filter(tahis => tahis.toLowerCase().indexOf(text.toLowerCase()) > -1)
          .slice(0, 10);
      }),
      tap(() => this.searching = false)
    );
  }

  inadsInputFormatter = (input: any) => {
    if ( input.ipikkaadress !== undefined ) {
      return input.ipikkaadress;
    } else {
      return input;
    }
  };
  inadsResultFormatter = (result: any) => result.ipikkaadress;

  constructor(
    private http: HttpClient,
    private httpBackend: JsonpClientBackend,
    private cd: ChangeDetectorRef
  ) {
    this.jsonpClient = new HttpClient(httpBackend);
    this.addressSearch = this.addressSearch.bind(this);
    this.apartmentSearch = this.apartmentSearch.bind(this);

    this.adsAddress.valueChanges.subscribe((address) => {
      if (address && address.ads_oid) {
        this.form.get('address').setValue(address.ipikkaadress);
        this.form.get('adsOid').setValue(address.ads_oid);
        this.apartments$.next(address.appartments);
      } else {
        this.form.get('address').setValue(address);
        this.form.get('adsOid').setValue(null);
        this.apartments$.next([]);
      }
    });

    this.adsApartment.valueChanges.subscribe((apartment) => {
      this.form.get('apartment').setValue(apartment);
    });
  }

  ngOnInit(): void {
    this.form.valueChanges.pipe(startWith(this.form.getRawValue()), takeUntil(this.ngDestroy)).subscribe((value) => {
      if (value.adsOid) {
        this.adsAddress.setValue({
          ads_oid: value.adsOid,
          ipikkaadress: value.address
        }, {emitEvent: false, onlySelf: true});
      } else {
        this.adsAddress.setValue(value.address, {emitEvent: false, onlySelf: true});
        this.adsApartment.setValue(value.apartment, {emitEvent: false, onlySelf: true});
      }

      this.apartments$.next([]);
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
