import {
  AfterViewChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
  TemplateRef,
  ViewChild,
  ViewContainerRef
} from '@angular/core';
import {SourceItemRow} from './source-item-row.model';
import {SourceGridItemDto} from '../../../models/source';
import {AbstractControl, UntypedFormGroup, ValidationErrors} from '@angular/forms';
import {GroupingTags, LocationTypes, SeriesType} from '../../../models/series';
import {SourceFormService} from '../source-form.service';
import {Subject} from 'rxjs/internal/Subject';
import {SourceService} from '../../../http/source.service';
import {Observable} from 'rxjs/internal/Observable';
import {distinctUntilChanged, switchMap, takeUntil} from 'rxjs/operators';
import {UserService} from '../../../../core/http/user.service';
import {SourceGridService} from '../source-grid.service';
import {simpleFadeAnimation} from '../../../../core/animations/simple-fade-animation';
import {ToastrService} from 'ngx-toastr';
import { BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmModalComponent } from '../../confirm-modal/confirm-modal.component';

@Component({
  selector: 'selected-source-item-row',
  templateUrl: './selected-source-item-row.component.html',
  animations: [simpleFadeAnimation]
})
export class SelectedSourceItemRowComponent implements OnInit, OnDestroy, AfterViewChecked {

  private ngDestroy = new Subject<void>();

  public hide = true;
  public checked = true;

  constructor(private cdRef: ChangeDetectorRef,
              private vcRef: ViewContainerRef,
              private sourceFormService: SourceFormService,
              private sourceService: SourceService,
              private sourceGrid: SourceGridService,
              private userService: UserService,
              private toastr: ToastrService,
              private modalService: BsModalService) { }

  @Input()
  set row(value) {
    this._row = value;
    this._source = this._row?.item;
    this._group = this._row?.control;
    this.personRegistrationNumberControl = this._group?.get('personRegistrationNumber');
    this.cdRef.markForCheck();
  }

  @Input()
  set mappedStatus(value) {
    this._mappedStatus = value;
    this.cdRef.markForCheck();
  }

  @ViewChild('noRootTemplate', {static: true})
  template: TemplateRef<any>;

  public locationTypes = LocationTypes.types;
  public submitted = false;
  public _edit: boolean;
  public _row: SourceItemRow;
  public _mappedStatus: boolean;
  public _source: SourceGridItemDto;

  public _group: UntypedFormGroup;
  private personRegistrationNumberControl: AbstractControl;
  public seriesTypes = SeriesType.types;
  public addressControlType = 'input';
  public dataMissing = false;

  personTypeahead$ = new Observable((observer: any) => {
    observer.next(this.personRegistrationNumberControl?.value);
  }).pipe(
    distinctUntilChanged(),
    switchMap((term: string) => this.userService.searchPersons(term)),
  );
  public groupingTags = GroupingTags.tags;

  ngOnInit(): void {
    this.vcRef.createEmbeddedView(this.template);

    this.sourceFormService.submitted$.pipe(takeUntil(this.ngDestroy)).subscribe((value: boolean) => {
      this.submitted = value;
    });

    this.sourceGrid.bulk$.subscribe((value) => {
      if (value) {
        this.edit = true;
      }
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  control(name?: string) {
    if (!name) {
      return this._group;
    }
    return this._group.get(name);
  }

  invalid(name: string, formGroup?): boolean {
    if (!formGroup) {
      formGroup = this._group;
    }
    const control = formGroup.get(name);
    return control && control.invalid && (control.dirty || control.touched);
  }

  errors(name: string): ValidationErrors {
    const control = this._group.get(name);
    return control ? control.errors : null;
  }

  typeaheadOnSelect(event) {
    this._group?.get('personRegistrationNumber').setValue(event.item.registrationNumber);
    this._group?.get('personCountryCode').setValue(event.item.countryCode);
  }

  set edit(value) {
    this._edit = value;
    this.cdRef.markForCheck();
  }

  saveSource() {
    this._group.markAllAsTouched();
    this._group.updateValueAndValidity();

    if(this._group.invalid) {
      //TODO: kasutajasõbralik error handling maybe?
      console.error(this._group);
      return;
    }
    const data = this._group.getRawValue();
    this.sourceService.saveSource(data).subscribe(() => {
      this.sourceFormService.updateRowSourceItem(data);
      this._group.markAsUntouched();
      this.edit = false;
    });
  }

  save() {
    this.saveSource();
  }

  cancel() {
    this._edit = false;
    this.hide = true;
    this._group.reset(this._row.item);
  }

  editRow() {
    this.edit = true;
  }

  toggleSelect(event): void {
    if (!this._mappedStatus || !this._group.touched) {
      this.deselectRow();
    } else {
      this.confirmDeselect().subscribe((confirm) => {
         if (confirm) {
           this.deselectRow();
         } else {
           event.target.checked = true;
         }
      })
    }

  }

  private deselectRow(): void {
    this.hide = true;

    setTimeout(() => {
      this.sourceGrid.deselectRow(this._row);
      this.toastr.info($localize`Removed source from editing`);
    }, 200);
  }

  private confirmDeselect(): Observable<boolean> {

    return new Observable<boolean>((observer) => {
      this.modalService.show(ConfirmModalComponent, {
        initialState: {
          title: $localize`Are you sure?`,
          description: $localize`Are you sure you wish to deselect row? You will lose applied changes.`,
          cancelLabel: $localize`Cancel`,
          okLabel: $localize`Undo changes`,
          callback: (result: boolean) => {
            if (result !== false) {
              observer.next(true);
            } else {
              observer.next(false);
              return;
            }
          }
        }
      });
    });
  }

  ngAfterViewChecked(): void {
    setTimeout(() => {
      this.cdRef.detectChanges();
      this.hide = false;
    }, 0);
  }
}
