import {Injectable} from '@angular/core';
import {CacheService} from '../cache/cache.service';
import {IdpCode} from './idp-code';
import {environment as config} from '../../../environments/environment';
import {AuthConstants} from './auth-constants';
import {IdpCodeUtil} from '../utils/idp-code-util';

@Injectable({
  providedIn: 'root'
})
export class IdpService {

  private supported: IdpCode[] = [IdpCode.NEM_ID, IdpCode.TARA, IdpCode.NEM_LOG_IN, IdpCode.DEMO];

  private activeIdp: IdpCode;

  constructor(private cacheService: CacheService) { }

  public reset(): void {
    this.setActiveIdp(this.getDefaultIdp(), true);
  }

  public getActiveIdp() {
    if (!this.activeIdp) {
      this.setActiveIdp(this.getDefaultIdp());
    }

    return this.activeIdp;
  }

  public setActiveIdpString(idpString: string, override?: boolean): void {
    this.setActiveIdp(IdpCodeUtil.fromString(idpString), override);
  }

  public setActiveIdp(idp: IdpCode, override?: boolean): void {
    const session = this.cacheService.getValue(AuthConstants.PROFILE_KEY);
    const storedIdp = this.cacheService.getValue(AuthConstants.LOGIN_MODE_KEY);
    if (session && storedIdp) {
      // Do not allow to override session idp
      idp = IdpCodeUtil.fromString(storedIdp);
    }

    if (!this.supported.find((supportedIdp) => supportedIdp === idp)) {
      idp = this.getDefaultIdp();
    }

    if (!storedIdp || override) {
      this.cacheService.setValue(AuthConstants.LOGIN_MODE_KEY, idp.valueOf());
    }

    this.activeIdp = IdpCodeUtil.fromString(this.cacheService.getValue(AuthConstants.LOGIN_MODE_KEY));
  }

  private getDefaultIdp(): IdpCode {
    return IdpCodeUtil.fromString(config.loginMode);
  }

}
