<div class="page-title-bar p-4">
  <div class="d-flex">
    <div class="col-2 pl-0">
      <a routerLink=".."><i class="fal fa-angle-left"></i>&nbsp;<ng-container i18n>Back</ng-container></a>
    </div>
    <div class="col d-flex flex-column justify-content-center align-items-center">
      <h2 class="mb-1"><ng-container i18n>API key</ng-container> - {{token?.name}}</h2>
    </div>
    <div class="col-2 pr-0 d-flex justify-content-end">
    </div>
  </div>
</div>

<div class="container-fluid">
  <div class="row justify-content-center d-flex mt-2">
    <div class="col-md-12 col-lg-6">
      <div class="form-row justify-content-center d-flex mb-5">
        <div class="green lighten-3 p-3 text-center">
          <h3 i18n>Your API Key value is:</h3>
          <p class="lead m-0"><strong>{{token?.value}}</strong></p>
        </div>
      </div>
      <div class="form-row">
        <div class="col-12">
          <p><a href="{{config.apiUrl}}/swagger-ui.html" target="_blank" i18n>API Documentation</a></p>

          <h5 i18n>Endpoints</h5>
          <div class="grey lighten-3 p-3 mb-3">
            <code>POST: {{config?.apiUrl}}/data</code>
          </div>
          <div class="grey lighten-3 p-3 mb-3">
            <code>POST: {{config?.apiUrl}}/data/meta</code>
          </div>

          <h5 i18n>Authentication</h5>
          <p i18n>The API uses header authentication with API key. The format of the header should be:</p>
          <div class="grey lighten-3 p-3 mb-3">
            <code>x-auth-token: {{token?.value}}</code>
          </div>

          <h5 i18n>Sending data</h5>
          <p i18n>Here is a simple example for sending data</p>
          <div class="grey lighten-3 p-3 mb-3">
            <code>
              <pre>{{sampledata | json }}</pre>
            </code>
          </div>

          <h5 i18n>Mapping metadata</h5>
          <p i18n>Here is a simple example for mapping metadata for datasource</p>
          <div class="grey lighten-3 p-3 mb-3">
            <code>
              <pre>{{sampleMetadata | json }}</pre>
            </code>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
