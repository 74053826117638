import {UriBuilder} from '../../utils/uri-builder';
import {TaraAuthUriBuilder} from './tara-auth-uri-builder';
import {AbstractRedirectStrategy} from '../redirect/abstract-redirect-strategy';

export class TaraAuthRedirectStrategy extends AbstractRedirectStrategy {

  constructor(private config: any, private hostUrl: string) {
    super();
    this.setRedirectUriBuilder(new TaraAuthUriBuilder(config, hostUrl));
  }

  getRedirectUriBuilder(): UriBuilder {
    return this.redirectUriBuilder;
  }

}
