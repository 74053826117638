import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SourceGridComponent } from './components/source-grid/source-grid.component';
import { SourceItemRowComponent } from './components/source-grid/row/source-item-row.component';
import { MappedSourcesComponent } from './components/mapped-sources.component';
import { UnmappedSourcesComponent } from './components/unmapped-sources.component';
import { SourcesBulkEditComponent } from './components/source-grid/sources-bulk-edit.component';
import { CancelMappingsComponent } from './components/source-grid/cancel/cancel-mappings.component';
import { ConfirmMappingsComponent } from './components/source-grid/confirm/confirm-mappings.component';
import { AddressDawaComponent } from './components/address/address-dawa/address-dawa.component';
import { AddressAdsComponent } from './components/address/address-ads/address-ads.component';
import { AddressInputComponent } from './components/address/address-input/address-input.component';
import { SourceMappingPageComponent } from './components/source-mapping-page/source-mapping-page.component';
import { SelectedSourceItemRowComponent } from './components/source-grid/row/selected-source-item-row.component';
import { SelectedSourceRowsComponent } from './components/source-grid/selected-source-rows.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CoreModule } from '../core/core.module';
import { AppRoutingModule } from '../app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatInputModule } from '@angular/material/input';
import { MatSelectModule } from '@angular/material/select';
import { MatOptionModule } from '@angular/material/core';
import { TypeaheadModule } from 'ngx-bootstrap/typeahead';
import { ModalModule } from 'ngx-bootstrap/modal';
import { PaginationModule } from 'ngx-bootstrap/pagination';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { VirtualScrollerModule } from 'ngx-virtual-scroller';
import { MatTooltipModule } from '@angular/material/tooltip';
import { LaddaModule } from 'angular2-ladda';
import { SourceService } from './http/source.service';
import { ConfirmCancellationComponent } from './components/source-grid/confirm-cancellation/confirm-cancellation.component';
import { TenantBrowserService } from './services/tenant-browser.service';
import { LoadmoreDatabase } from './components/tenant/browser/loadmore-database';
import { SelectedSourcesUnsavedGuard } from './guards/selected-sources-unsaved-guard';
import { SourceFormService } from './components/source-grid/source-form.service';
import { SelectedSourcesDatabase } from './components/tenant/browser/selected-sources-database';
import { FeedbackModalComponent } from './components/feedback-modal/feedback-modal.component';
import { SupportService } from './services/support.service';
import {TokenViewComponent} from './components/token/view/token-view.component';
import {TokenGridComponent} from './components/token/grid/token-grid.component';
import {TokenEditComponent} from './components/token/edit/token-edit.component';
import {TokenAcceptComponent} from './components/token/accept/token-accept.component';
import {ScrollTopComponent} from './components/scroll-top/scroll-top.component';
import {TenantBrowserComponent} from './components/tenant/browser/tenant-browser.component';
import {TenantSetupComponent} from './components/setup-wizard/tenant-setup.component';
import {TokenSetupComponent} from './components/setup-wizard/token-setup.component';
import {TokenTestComponent} from './components/setup-wizard/token-test.component';
import {NgxPaginationModule} from 'ngx-pagination';
import { TokenPersonSelectComponent } from './components/token/token-person-select/token-person-select.component';
import {MatIconModule} from '@angular/material/icon';
import {MatButtonModule} from '@angular/material/button';
import { TokenPersonsModalComponent } from './components/token/token-persons-modal/token-persons-modal.component';
import { SetupWizardComponent } from './components/setup-wizard/setup-wizard.component';
import {MatTreeModule} from '@angular/material/tree';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {SelectedSourcesComponent} from './components/tenant/browser/selected-sources.component';
import {TooltipModule} from 'ngx-bootstrap/tooltip';
import { TokenPersonHistoryModalComponent } from './components/token/token-person-history-modal/token-person-history-modal.component';
import { MandatePageComponent } from './components/mandate-page/mandate-page.component';

@NgModule({
    imports: [
        CommonModule,
        FormsModule,
        ReactiveFormsModule,
        CoreModule,
        AppRoutingModule,
        NgbModule,
        MatAutocompleteModule,
        MatInputModule,
        MatSelectModule,
        MatOptionModule,
        TypeaheadModule,
        ModalModule,
        PaginationModule,
        PopoverModule,
        NgSelectModule,
        MatProgressSpinnerModule,
        BsDropdownModule,
        VirtualScrollerModule,
        MatTooltipModule,
        LaddaModule,
        NgxPaginationModule,
        MatIconModule,
        MatButtonModule,
        MatTreeModule,
        MatCheckboxModule,
        TooltipModule
    ],
  declarations: [
    TokenViewComponent,
    TokenGridComponent,
    TokenEditComponent,
    TokenAcceptComponent,
    TenantBrowserComponent,
    TenantSetupComponent,
    TokenSetupComponent,
    TokenTestComponent,
    SourceGridComponent,
    SourceItemRowComponent,
    MappedSourcesComponent,
    UnmappedSourcesComponent,
    SourcesBulkEditComponent,
    CancelMappingsComponent,
    ConfirmMappingsComponent,
    AddressDawaComponent,
    AddressAdsComponent,
    AddressInputComponent,
    SourceMappingPageComponent,
    SelectedSourceItemRowComponent,
    SelectedSourceRowsComponent,
    ConfirmCancellationComponent,
    FeedbackModalComponent,
    ScrollTopComponent,
    TokenPersonSelectComponent,
    TokenPersonsModalComponent,
    SetupWizardComponent,
    SelectedSourcesComponent,
    TokenPersonHistoryModalComponent,
    MandatePageComponent
  ],
  providers: [
    LoadmoreDatabase,
    SelectedSourcesDatabase,
    SourceService,
    TenantBrowserService,
    SelectedSourcesUnsavedGuard,
    SourceFormService,
    SupportService
  ]
})
export class PrivateModule { }
