export class ObjectSearchParams {
  text: string;
  type: string;
  query: string;
  pageSize: number = 100;
  currentPage: number = 0;
  withParents: boolean = false;
  withGroups: boolean = false;
  withTotalPages: boolean = true;
  onlyRoots: boolean = false;

  constructor(props?: any) {
    if (!props) {
      return;
    }
    if (props.text) {
      this.text = props.text;
    }
    if (props.query) {
      this.query = props.query;
    }
  }
}
