import {Component, OnInit} from '@angular/core';
import {TenantService} from '../../../../core/http/tenant.service';
import {Tenant, TenantPage} from '../../../../core/model/tenant';
import {UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {ActivatedRoute} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

const selector = 'mydata-series-bulk-edit-modal';
let nextId = 0;

@Component({
  selector: 'app-tenant-view',
  templateUrl: './tenant-view.component.html'
})
export class TenantViewComponent implements OnInit {
  id = `${selector}-${nextId++}`;

  public saveTenantProgress = false;
  public loading = false;
  public ui: any = {
    isAdd: false,
    isEdit: false
  };

  public tenant: Tenant;
  public tenantForm: UntypedFormGroup;


  public pageFilter: any = {
    page: 1,
    size: 10,
    keyword: ''
  };

  constructor(
    private tenantService: TenantService,
    private route: ActivatedRoute,
    public toastr: ToastrService
  ) {

    this.tenantForm = new UntypedFormGroup({
      username: new UntypedFormControl('', Validators.required),
      url: new UntypedFormControl('', Validators.required),
      password: new UntypedFormControl('')
    });

    this.tenantForm.get('username').disable();
    this.tenantForm.get('url').disable();
  }

  ngOnInit() {
    this.listenUrl();
  }

  listenUrl(): void {
    this.route.params.subscribe(
      (params: any) => {
        if (params.id) {
          this.tenantService.getTenant(params.id).subscribe(
            (tenant: Tenant) => {
              this.reset(tenant);
            },
            (sErr: any) => {
              this.toastr.error(sErr.error ? sErr.error.message : '');
            }
          );
        } else {
          this.loadPage();
        }
      });
  }

  loadPage() {
    this.loading = true;
    this.tenantService.getTenantsPage(this.pageFilter).subscribe(
      (data: TenantPage) => {
        const tenant = data.content.length > 0 ? data.content[0] : null;
        this.reset(tenant);
        this.loading = false;
      },
      (err: any) => {
        this.toastr.error($localize`Error on isLoading page!`);
      }
    );
  }

  reset(tenant: Tenant): void {
    if (tenant == null) {
      this.ui.isAdd = true;
      this.tenant = null;
      this.tenantForm.reset({});
      this.tenantForm.get('username').enable();
      this.tenantForm.get('url').enable();
      this.tenantForm.get('password').enable();
    } else {
      this.tenant = tenant;
      this.tenantForm.reset(tenant);
    }
  }

  startEdit() {
    this.ui.isEdit = true;
    this.ui.isAdd = false;
    this.tenantForm.get('username').enable();
  }

  saveTenant() {
    const value = this.tenantForm.value;
    if (this.tenant) {
      value.id = this.tenant.id;
    }

    this.saveTenantProgress = true;
    this.tenantService.saveTenant(value).subscribe(
      (tenant: any) => {
        this.reset(tenant);
        this.toastr.success($localize`Saved!`);
      },
      (err: any) => {
        this.saveTenantProgress = false;
        this.toastr.error(err.error.message);
      },
      () => this.saveTenantProgress = false
    );
  }

}
