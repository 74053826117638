<div *ngIf="tenantsLoaded" class="row justify-content-center d-flex mt-2">
  <div [formGroup]="tenantForm" class="col-md-12 col-lg-10 text-center">
    <h3 class="mb-3" >
      <span i18n>Tenant Information</span>
      <a class="info-modal-circle" (click)="openFeedbackModal()">
        <i class="fa fa-info-circle ml-2 mt-2 btn-tooltip" [popover]="'Connect Telia to get the contract'" i18n-popover
        placement="top"
        triggers="hover"></i>
      </a>
    </h3>

    <div *ngIf="tenantConnectError" class="alert alert-danger mt-5" role="alert">
      <ng-container i18n>Unable to connect to tenant. Please contact <a class="customer-support-link btn-tooltip" (click)="openFeedbackModal()">customer support</a> for further assistance.</ng-container>
    </div>

    <div class="form-row">
      <div class="col ">
        <div class="form-group text-left">
          <label [attr.for]="id + '-username'" i18n>Tenant admin username</label>
          <input type="text" formControlName="username" class="form-control" [id]="id + '-username'">
        </div>
      </div>
    </div>
    <div class="form-row">
      <div class="col">
        <div class="form-group text-left">
          <label [attr.for]="id + '-url'" i18n>Url</label>
          <input type="text" formControlName="url" class="form-control" [id]="id + '-url'">
        </div>
      </div>
    </div>
    <div *ngIf="tenant == null" class="form-row">
      <div class="col">
        <div class="form-group text-left">
          <label [attr.for]="id + '-password'" i18n>Password</label>
          <input type="password" formControlName="password" class="form-control" [id]="id + '-password'">
        </div>
      </div>
    </div>
    <button type="button" class="btn btn-primary" (click)="next()" i18n>Next</button>
  </div>
</div>
