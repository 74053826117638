import {TokenAuthService} from './token-auth.service';
import {IdpCode} from './idp-code';

export class AuthResult {
  result: boolean;
  reason: any;
  skipRedirect? = false;
  redirectTo?: string;
  forceLogout? = false;
  token?: string;
}

export class AuthParams {
  targetUrlPath?: string;
  currentUrlPath?: string;
  refresh? = false;
  token?: string;
}

export abstract class IdpAuthService {

  constructor(protected tokenAuthService: TokenAuthService) {
  }

  public abstract switchTo(idp: IdpCode);

  public abstract getIdp(): IdpCode;

  public abstract requestAuthentication(params?: any): void;

  public abstract authenticationCallback(params?: any): void;

  public abstract doLogout(params?: any): Promise<boolean>;

  public abstract reset(): void;

  public logout(params?: any): void {
    this.doLogout(params).then(() => {
      let skipRedirect = (params && params.skipRedirect != null ? params.skipRedirect : true);
      this.tokenAuthService.doLogout(skipRedirect);
      this.reset();
    });

  }

  protected onAuthentication(authResult: AuthResult): void {
    this.tokenAuthService.onAuthentication(authResult);
  }

}
