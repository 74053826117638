import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanDeactivate, RouterStateSnapshot } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { Observable } from "rxjs";
import { ConfirmModalComponent } from "../components/confirm-modal/confirm-modal.component";
import { MappedSourcesComponent } from "../components/mapped-sources.component";

@Injectable()
export class SelectedSourcesUnsavedGuard implements CanDeactivate<MappedSourcesComponent> {

    constructor(private modalService: BsModalService) {}

    canDeactivate(
        component: MappedSourcesComponent, 
        currentRoute: ActivatedRouteSnapshot, 
        currentState: RouterStateSnapshot, 
        nextState?: RouterStateSnapshot
    ): Observable<boolean> {

        return new Observable<boolean>((observer) => {
            if(!component.canDeactivate()) {
                this.modalService.show(ConfirmModalComponent, {
                    initialState: {
                        title: $localize`Are you sure?`,
                        description: $localize`Are you sure you wish to leave page? You will lose applied changes.`,
                        cancelLabel: $localize`Cancel`,
                        okLabel: $localize`Leave page`,
                        callback: (result: boolean) => {
                        if (result !== false) {
                            observer.next(true);
                        } else {
                            observer.next(false);
                        }
                        }
                    }
                });
            } else {
                observer.next(true);
            }
        });
        
    }
}
