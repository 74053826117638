import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment as config } from '../../../environments/environment';
import { Observable } from 'rxjs/internal/Observable';

export class Feedback {
    message: string;
    pageUrl: string;
}

@Injectable()
export class SupportService {

    constructor(
        private http: HttpClient
    ) { }

    public sendFeedback(feedback: Feedback): Observable<void> {
        return this.http.post<void>(config.apiUrl + '/support/feedback', feedback);
    }

}
