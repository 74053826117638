export class Person {
  countryCode: string;
  registrationNumber: string;
  displayName: string;
  juridical: boolean;
  idPrivacyType: string;
  dob: string;
}

export class TokenPage {
  content: Token[];
  page: number;
  size: number;
  totalElements: number;
  totalPages: number;
}

export class Token {
  id: number;
  primary = false;
  name: string;
  value: string;
  assigned = false;
  ownerPerson: Person;
  tokenPersons: TokenPerson[] = [];
}

export class TokenPerson {
  id: number;
  assignType: AssignType;
  countryCode: string;
  registrationNumber: string;
  email: string;
  person: Person;
  assignedAt: Date;
}

export class TokenPersonHistory {
  persons: TokenPersonHistoryPerson[] = [];
}

export class TokenPersonHistoryPerson {
  person: TokenPerson;
  revokedAt: Date;
}

export enum AssignType {
  REG_NO = 'REG_NO',
  EMAIL = 'EMAIL',
  PERSON = 'PERSON'
}

export class TokenAssignDetails {
  id: number;
  type: AssignType;
  countryCode: string;
  registrationNumber: string;
  email: string;
  person: Person;
  assignedAt: Date;
}
