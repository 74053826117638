import { Component, OnInit } from '@angular/core';
import {Subject} from 'rxjs/internal/Subject';
import {BsModalRef} from 'ngx-bootstrap/modal';

@Component({
  selector: 'app-terms-of-service-agreement-modal',
  templateUrl: './terms-of-service-agreement-modal.component.html'
})
export class TermsOfServiceAgreementModalComponent implements OnInit {

  public result$ = new Subject<boolean>();

  constructor (public modalRef: BsModalRef) {
  }

  ngOnInit() {
  }

  close() {
    this.modalRef.hide();
    this.result$.next(false);
    this.result$.complete();
  }

  accept() {
    this.modalRef.hide();
    this.result$.next(true);
    this.result$.complete();
  }

}
