import {Component, Input, OnInit} from '@angular/core';
import {Person} from '../../model/person';

export class SharePerson {
  displayName: string;
  initials: string;

}
@Component({
  selector: 'share-person-item-list',
  templateUrl: './share-person-item-list.component.html'
})
export class SharePersonItemListComponent implements OnInit {

  @Input()
  persons: SharePerson[];

  displayItemsLength: number = 5;

  constructor() { }

  ngOnInit(): void {
  }

}
