import {Injectable, OnDestroy} from '@angular/core';
import {SourceGridItemDto, SourceMapping, SourcePage} from '../../models/source';
import {
  AbstractControl,
  UntypedFormArray,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
  ValidationErrors
} from '@angular/forms';
import {RequireAddress} from '../../validators/require-address';
import {RequireCoords} from '../../validators/require-coords';
import {RequireUuid} from '../../validators/require-uuid';
import {ReplaySubject} from 'rxjs/internal/ReplaySubject';
import {Subject} from 'rxjs/internal/Subject';
import {SourceItemRow} from './row/source-item-row.model';
import {environment} from '../../../../environments/environment';
import {PersonCodeValidator} from '../../../core/validators/person-code-validator';

@Injectable()
export class SourceFormService implements OnDestroy {
  private ngDestroy = new Subject<void>();

  public sourcePage$ = new ReplaySubject<SourcePage>();

  public selectedControlArraySource = new ReplaySubject<UntypedFormArray>();
  public selectedControlArray$ = this.selectedControlArraySource.asObservable();

  public submittedSource = new ReplaySubject<boolean>();
  public submitted$ = this.submittedSource.asObservable();

  public bulkEditForm: UntypedFormGroup = this.defaultBulkEditForm();
  public rows: SourceItemRow[] = [];
  public rowsById: Map<number, SourceItemRow> = new Map<number, SourceItemRow>();
  public selectedRowSet = new Set<SourceItemRow>();

  public arr = new Array<SourceItemRow>();
  private selectedControlArray = new UntypedFormArray([]);

  private currentPage = 0;

  constructor() {


    // for (const field of fields) {
    //   this.bulkForm.get(field).valueChanges.pipe(takeUntil(this.ngDestroy)).subscribe(value => {
    //     const groupValue = {};
    //     if (field === 'groupingTag') {
    //       value = value.toUpperCase();
    //     }
    //     groupValue[field] = value;
    //     this.selectedControlArray.patchValue(new Array(this.selectedControlArray.length).fill(groupValue, 0, this.selectedControlArray.length));
    //   });
    // }

    this.sourcePage$.subscribe((page: SourcePage) => {
      this.currentPage = page.page;
    });
  }

  public getBulkEditFormData(): any {
    const fields = [
      'name',
      'groupingTag',
      'seriesType',
      'personCountryCode',
      'personRegistrationNumber',
      'locationType',
      'address',
      'addressUuid',
      'apartment',
      'room',
      'uuid',
      'lat',
      'lng'
    ];

    const groupValue = {};

    for (const field of fields) {
      let value = this.bulkEditForm.get(field).value;
      if (!value) {
        continue;
      }

      if (field === 'groupingTag') {
        value = value.toUpperCase();
      }
      groupValue[field] = value;
    }

    return groupValue;
  }

  ngOnDestroy(): void {
    this.selectedControlArraySource.complete();
    this.submittedSource.complete();
    this.rowsById.clear();
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  public defaultBulkEditForm() {
    return new UntypedFormGroup({
      name: new UntypedFormControl(''),
      groupingTag: new UntypedFormControl(''),
      seriesType: new UntypedFormControl(''),
      personCountryCode: new UntypedFormControl(''),
      personRegistrationNumber: new UntypedFormControl(''),
      locationType: new UntypedFormControl(''),
      address: new UntypedFormControl(''),
      adsOid: new UntypedFormControl(''),
      addressUuid: new UntypedFormControl(''),
      apartment: new UntypedFormControl(''),
      room: new UntypedFormControl(''),
      lat: new UntypedFormControl(''),
      lng: new UntypedFormControl(''),
      uuid: new UntypedFormControl('')
    });
  }

  public createSourceItemRow(item: SourceGridItemDto): SourceItemRow {

    let row = this.rowsById.get(item.id);
    if (row) {
      return row;
    }
    row = {
      item: item,
      control: new UntypedFormGroup({
        id: new UntypedFormControl(item.id, [Validators.required]),
        sourceName: new UntypedFormControl(item.sourceName),
        name: new UntypedFormControl(item.name, [Validators.required]),
        groupingTag: new UntypedFormControl(item.groupingTag, []),
        seriesType: new UntypedFormControl(item.seriesType, [Validators.required]),
        personCountryCode: new UntypedFormControl(item.personCountryCode, []),
        personRegistrationNumber: new UntypedFormControl(item.personRegistrationNumber, [Validators.required, this.validatePersonCode.bind(this)]),
        locationType: new UntypedFormControl(item.locationType, [Validators.required]),
        address: new UntypedFormControl(item.address, [RequireAddress]),
        apartment: new UntypedFormControl(item.apartment, null),
        adsOid: new UntypedFormControl(item.adsOid, null),
        room: new UntypedFormControl(item.room, null),
        lat: new UntypedFormControl(item.lat, [RequireCoords]),
        lng: new UntypedFormControl(item.lng, [RequireCoords]),
        uuid: new UntypedFormControl(item.uuid, [RequireUuid]),
        confirmationStatus: new UntypedFormControl(item.confirmationStatus)
      }),
      pageNumber: this.currentPage,
      index: 0
    };

    this.rowsById.set(item.id, row);

    return row;
  }

  private validatePersonCode(control: AbstractControl): ValidationErrors | null {
    const input = <UntypedFormControl>control;
    const row = <UntypedFormGroup>input.parent;
    if (!row) {
      return null;
    }

    const cc = row.get('personCountryCode').value ? row.get('personCountryCode').value : environment.defaultCountryCode;
    const valid = PersonCodeValidator.validate(input.value, cc);

    if (valid) {
      return;
    }

    return {
      personRegistrationNumber: true
    };
  }

  public mapSourcesToRows(items: SourceGridItemDto[]): SourceItemRow[] {

    return items.map(item => this.createSourceItemRow(item));
  }

  public bulkEditSelectedRows(): void {
    const data = this.getBulkEditFormData();
    this.selectedControlArray.patchValue(new Array(this.selectedControlArray.length).fill(data, 0, this.selectedControlArray.length));
    this.selectedControlArray.markAllAsTouched();
    this.selectedControlArraySource.next(this.selectedControlArray);
  }

  public updateSelectedControls(rows: SourceItemRow[]) {
    let controls = [];
    for (let row of rows) {
      if (!row || !row.control) {
        continue;
      }
      controls.push(row.control);
    }
    this.selectedControlArray = new UntypedFormArray(controls);
    this.selectedControlArraySource.next(this.selectedControlArray);
  }

  public isSelected(row: SourceItemRow): boolean {
    if (!row || !row.control) {
      return false;
    }
    return this.selectedRowSet.has(row);
  }


  public removeRow(itemId: number) {
    const row = this.rowsById.get(itemId);
    const controlIndex = this.selectedControlArray.controls.indexOf(row.control);
    if (controlIndex !== -1) {
      this.selectedControlArray.removeAt(controlIndex);
    }
  }

  public isFormValid() {
    for (const control of this.selectedControlArray.controls) {
      if (control.invalid) {
        return false;
      }
    }
    return true;
  }

  public isFormTouched() {
    for (const control of this.selectedControlArray.controls) {
      if (control.touched) {
        return true;
      }
    }
    return false;
  }

  public getPendingMappingData(): SourceMapping[] {
    return this.selectedControlArray.getRawValue().filter((item) => {
      return item.confirmationStatus === 'PENDING';
    });
  }

  public getMappingData(): SourceMapping[] {
    return this.selectedControlArray.getRawValue();
  }

  public markAllAsTouched() {
    this.selectedControlArray.markAllAsTouched();
  }

  public validate() {
    this.markAllAsTouched();
    this.updateValueAndValidity();
  }

  public updateValueAndValidity() {
    this.selectedControlArray.updateValueAndValidity();
    for (let control of this.selectedControlArray.controls) {
      control.updateValueAndValidity();
      if (control instanceof UntypedFormGroup) {
        let c = <UntypedFormGroup>control;
        for (let i in c.controls) {
          c.controls[i].updateValueAndValidity();
        }
      }
    }
  }

  public updateRowSourceItems(mappings: any[]) {
    for (const mapping of mappings) {
      this.updateRowSourceItem(mapping);
    }
  }

  public updateRowSourceItem(mapping: any) {
    const row = this.rowsById.get(mapping.id);
    if (row) {
      Object.assign(row.item, mapping);
    }
  }
}
