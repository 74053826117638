import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {PersonService} from '../../../../core/services/person.service';
import {ToastrService} from 'ngx-toastr';
import {Subject} from 'rxjs/internal/Subject';
import {NavbarService} from '../../navbar/navbar.service';
import {AssignType, Person, Token, TokenPage, TokenPerson, TokenPersonHistory} from '../../../../core/model/person';
import {TokenPageService} from '../token-page.service';
import {map, takeUntil} from 'rxjs/operators';
import {Mandate, MandatePerson} from '../../../../core/model/profile';
import {BsModalService} from 'ngx-bootstrap/modal';
import {ConfirmModalComponent} from '../../confirm-modal/confirm-modal.component';
import {Clipboard} from '@angular/cdk/clipboard';
import {TokenAuthService} from '../../../../core/auth/token-auth.service';
import {TokenPersonsModalComponent} from '../token-persons-modal/token-persons-modal.component';
import {Observable} from 'rxjs/internal/Observable';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {of} from 'rxjs/internal/observable/of';
import {TokenPersonHistoryModalComponent} from '../token-person-history-modal/token-person-history-modal.component';
import {SharePerson} from '../../../../core/components/share-person-item-list/share-person-item-list.component';


export class TokenItem {
  token: Token;
  history: TokenPersonHistory;
}
@Component({
  selector: 'token-grid',
  templateUrl: './token-grid.component.html',
  providers: [
    PersonService
  ]
})
export class TokenGridComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  @ViewChild('navActions', {static: true})
  public actionsTemplate: TemplateRef<any>;
  public searchForm = this.tokenPageService.searchForm;
  public search$ = this.tokenPageService.search$;
  public tokensPage: TokenPage;
  public currentPerson: MandatePerson;
  public assignTypeEnum = AssignType;

  tokenItems: TokenItem[] = [];

  public pageFilter: any = {
    page: 1,
    size: 100,
    keyword: ''
  };

  constructor (
    private navbarService: NavbarService,
    private personService: PersonService,
    public toastr: ToastrService,
    private tokenPageService: TokenPageService,
    private authService: TokenAuthService,
    private modalService: BsModalService,
    private clipboard: Clipboard
  ) {}

  ngOnInit () {
    this.navbarService.setActionsTemplate(this.actionsTemplate);
    this.search$.pipe(takeUntil(this.ngDestroy)).subscribe((searchFilter: any) => {
      this.search(searchFilter.searchType);
    });
    this.authService.currentMandate$.pipe(takeUntil(this.ngDestroy)).subscribe((currentMandate: Mandate) => {
      this.currentPerson = currentMandate.person;
    });
  }

  ngOnDestroy(): void {
    this.navbarService.setActionsTemplate(null);
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }

  search(searchType): void {
    this.pageFilter.myTokens = false;
    this.pageFilter.mySharedTokens = false;
    this.pageFilter.tokensShared = false;
    this.pageFilter.page = 1;

    if (searchType) {
      switch (searchType) {
        case 'my-tokens':
          this.pageFilter.myTokens = true;
          break;
        case 'my-shared-tokens':
          this.pageFilter.mySharedTokens = true;
          break;
        case 'tokens-shared':
          this.pageFilter.tokensShared = true;
          break;
        default:
          break;
      }
    }

    this.loadPage();
  }

  loadPage () {
    this.personService.getTokens(this.pageFilter).subscribe(
      (data: any) => {




        let observables = [];
        for (let token of data.content) {
          observables.push(this.mapToTokenItemCallback(token));
        }

        forkJoin(observables.length > 0 ? observables : of([])).subscribe((items: TokenItem[]) => {
          this.tokenItems = items;
          this.tokensPage = data;
        })
      },
      (err: any) => {
        console.error(err);
        this.toastr.error($localize`Error on loading page!`);
      }
    );
  }

  private mapToTokenItemCallback(token: Token): Observable<TokenItem> {

    return this.personService.getTokenPersonHistory(token).pipe(map((result: TokenPersonHistory) => {
      return {
        token: token,
        history: result
      }
    }));
  }
  pageChanged (pageNr: any) {
    this.pageFilter.page = pageNr;
    this.loadPage();
  }

  /**
   {
        initialState: {
          title: $localize`Are you sure?`,
          description: $localize`Are you sure you want to map multiple datapoints with the same name? It is easier to view the data later if the names are more accurate / different`,
          cancelLabel: $localize`Change names`,
          okLabel: $localize`Send anyway`,
          callback: (result: boolean) => {
            if (result !== false) {
              this.modalService.show(ConfirmMappingsComponent, {class: "modal-lg", initialState: {
                  mappings: mappingData
                }}).content.savedEmitter.pipe(takeUntil(this.ngDestroy)).subscribe(() => {
                this.loadPage();
              });
            }
          }
        }
   * @param token
   */
  deleteToken(token: Token) {
    let modalDescription: string = $localize`This API key is shared. Are you really sure you want to delete it?`;
    if (this.isTokenSharedByMe(token)) {
      let tokenPersons = token.tokenPersons;
      if (tokenPersons.length == 1) {
        modalDescription = $localize`This API key is shared with ${this.getTokenPersonDescription(tokenPersons[0])}. Are you really sure you want to delete it?`;
      } else if (tokenPersons.length > 1) {
        modalDescription = $localize`This API key is shared with ${tokenPersons.length} persons. Are you really sure you want to delete it?`;
      }
    }

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: modalDescription,
        callback: (result: boolean) => {
          if (result !== false) {
            this.personService.deleteToken(token.id).subscribe(
              (data: any) => {
                this.loadPage();
              }, (err) => {
                console.error(err);
                this.toastr.error($localize`Error occurred when deleting API key`);
              }
            );
          }
        }
      }
    });
  }

  declineToken(token): void {
    if (!this.isTokenSharedToMe(token)) {
      return;
    }

    let modalDescription = $localize`API key is shared with you by ${token.ownerPerson.displayName} (${token.ownerPerson.registrationNumber}). Are you sure you want to decline it?`;

    const modalRef = this.modalService.show(ConfirmModalComponent, {
      initialState: {
        description: modalDescription,
        callback: (result: boolean) => {
          if (result !== false) {
            this.personService.declineToken(token.id).subscribe(
              (data: any) => {
                this.loadPage();
              }
            );
          }
        }
      }
    });
  }

  isMyToken(token: Token): boolean {
    return this.currentPerson?.registrationNumber === token.ownerPerson.registrationNumber
      && this.currentPerson?.countryCode === token.ownerPerson.countryCode;
  }

  isTokenSharedByMe(token): boolean {
    return this.isMyToken(token) && token.assigned;
  }

  isTokenSharedToMe(token): boolean {
    const personMatch = token.tokenPersons.find(tokenPerson => tokenPerson.countryCode === this.currentPerson?.countryCode
      && tokenPerson.registrationNumber === this.currentPerson?.registrationNumber);

    return personMatch != null;
  }

  isMyTokenOrTokenSharedToMe(token: Token): boolean {
    return this.isMyToken(token) || this.isTokenSharedToMe(token);
  }

  copyToClipboard(token: Token): void {

    const success = this.clipboard.copy(token.value);
    if (success) {
      this.toastr.success( $localize`Copied to clipboard!`);
    }
  }

  showTokenPersons(token: Token): void {
    if (!token.tokenPersons.length) {
      return;
    }

    this.modalService.show(TokenPersonsModalComponent, {
      class: 'modal-lg',
      initialState: {
        tokenPersons: token.tokenPersons
      }
    })

  }

  showHistory(item: TokenItem): void {
    if (!item.history || !item.history.persons.length) {
      return;
    }

    this.modalService.show(TokenPersonHistoryModalComponent, {
      class: 'modal-lg',
      initialState: {
        history: item.history
      }
    });
  }

  mapTokenPersonToPersons(tokenPersons: TokenPerson[]): SharePerson[] {
    return tokenPersons.map(tokenPerson => {
      let displayName = '';
      switch (tokenPerson.assignType) {
        case AssignType.PERSON:
          displayName = tokenPerson.person.displayName;
          break;
        case AssignType.REG_NO:
          displayName = tokenPerson.registrationNumber;
          break;
        case AssignType.EMAIL:
          displayName = tokenPerson.email;
          break;
      }

      return {
        displayName: displayName,
        initials: this.getInitials(displayName)
      };
    });
  }

  getInitials(displayName: string): string {
      const nameParts: string[] = displayName.split(" ");
      const initials = nameParts.reduce((acc, curr, index) => {
        if (index === 0 || index === nameParts.length -1) {
          acc = `${acc}${curr.charAt(0).toUpperCase()}`;
        }
        return acc;
      }, '');

      return initials ?? 'N/A';
  }

  private getTokenSharedPersonName(token: Token) {
    let result = '';

    if (token.tokenPersons.length > 0) {
      const tokenPerson = token.tokenPersons[0];
      switch (tokenPerson.assignType) {
        case AssignType.EMAIL:
          result = tokenPerson.email;
          break;
        case AssignType.REG_NO:
        case AssignType.PERSON:
          result = tokenPerson.registrationNumber;
          break;
      }
    }

    return result;
  }

  private getTokenPersonDescription(tokenPerson: TokenPerson): string {
    let result = '';
    switch (tokenPerson.assignType) {
      case AssignType.EMAIL:
        result = tokenPerson.email;
        break;
      case AssignType.REG_NO:
      case AssignType.PERSON:
        if (tokenPerson.person) {
          result = tokenPerson.person.displayName + ' (' + tokenPerson.registrationNumber + ')';
        } else {
          result = tokenPerson.registrationNumber;
        }
        break;
    }

    return result;
  }

}
