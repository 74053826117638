import {Component, OnDestroy, OnInit, ViewEncapsulation} from '@angular/core';
import {NgxPermissionsService} from 'ngx-permissions';
import {ActivatedRoute, Router} from '@angular/router';
import {EnumsService} from './core/services/enums.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {IdpAuthService} from './core/auth/idp-auth.service';
import {TokenAuthService} from './core/auth/token-auth.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  title = 'dal-app';

  constructor (
    public tokenAuthService: TokenAuthService,
    private enumsService: EnumsService,
    private permissionsService: NgxPermissionsService,
    private router: Router,
    private route: ActivatedRoute
  ) {
    this.enumsService.loadEnums();

    this.route.queryParams.pipe(takeUntil(this.ngDestroy)).subscribe(params => {
      if (params['token'] || params['loginMode']) {
        // this.authService.setApiToken(params['token']);
        this.router.navigate([], {
          queryParams: {
            token: null,
            loginMode: null
          },
          queryParamsHandling: 'merge'
        });
      }
    });

    this.tokenAuthService.authenticationResult$.pipe(takeUntil(this.ngDestroy)).subscribe((result) => {
      if (result.skipRedirect) {
        return;
      }

      console.log('AUTH RESULT', result);

      if (result.result) {
        if (result.redirectTo) {
          window.location.href = result.redirectTo;
        }
      } else {
        if (result.forceLogout) {
          this.tokenAuthService.startLogout();
          return;
        }

        this.router.navigate(['/login/login']);
      }
    });
  }

  ngOnInit (): void {

  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
