import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import {FlatTreeControl} from '@angular/cdk/tree';
import {SelectionModel} from '@angular/cdk/collections';
import {SelectedSourcesService} from './selected-sources.service';
import {LoadmoreDatabase} from './loadmore-database';
import {SelectedSourcesDatabase} from './selected-sources-database';
import {Tenant} from '../../../../core/model/tenant';
import {UntypedFormGroup} from '@angular/forms';
import {
  MetaFieldsDto,
  SeriesSourceTenantImportRequest,
  SourceAssetsFlatTree,
  TenantMappingDto,
  TenantSourceDto
} from '../../../models/series';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';
import {SourceService} from '../../../http/source.service';
import {Subject} from 'rxjs/internal/Subject';
import {takeUntil} from 'rxjs/operators';
import {TreeControlUtil} from '../../../utils/tree-control-util';
import {TenantBrowserService} from '../../../services/tenant-browser.service';
import {ObjectNode} from '../../../models/tenant-mapping/object-node';
import {ObjectFlatNode} from '../../../models/tenant-mapping/object-flat-node';
import {NodeType} from '../../../models/tenant-mapping/node-type';

@Component({
  selector: 'tenant-selected-sources',
  templateUrl: './selected-sources.component.html'
})
export class SelectedSourcesComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();

  nestedNodeMap = new Map<string, ObjectNode>();
  flatNodeMap: Map<string, ObjectFlatNode>;

  treeControl: FlatTreeControl<ObjectFlatNode>;
  treeFlattener: MatTreeFlattener<ObjectNode, ObjectFlatNode>;
  dataSource: MatTreeFlatDataSource<ObjectNode, ObjectFlatNode>;
  checklistSelection = new SelectionModel<ObjectFlatNode>(true /* multiple */);
  isLoading = false;

  public mappingForm: UntypedFormGroup = this.selectedSourcesService.mappingForm;

  private treeControlUtil: TreeControlUtil;

  @Input()
  public tenant: Tenant;

  constructor(private selectedSourcesService: SelectedSourcesService,
              private database: LoadmoreDatabase,
              private selectedSourcesDatabase: SelectedSourcesDatabase,
              private toastr: ToastrService,
              private router: Router,
              private sourceService: SourceService,
              private tenantBrowser: TenantBrowserService) {
    this.flatNodeMap = new Map<string, ObjectFlatNode>();
    this.treeFlattener = new MatTreeFlattener(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ObjectFlatNode>(this.getLevel, this.isExpandable);
    this.dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
    this.treeControlUtil = new TreeControlUtil(this.treeControl);
  }

  getChildren = (node: ObjectNode): ObjectNode[] => node.children;

  transformer = (node: ObjectNode, level: number) => {
    const existingNode = this.flatNodeMap.get(node.id);
    let flatNode;
    if (existingNode && existingNode.id === node.id) {
      flatNode = existingNode;
    } else {
      flatNode = new ObjectFlatNode(node.id, node.type, node.name, node.managedObject, node.sourcePath, level, node.hasChildren, node.parentItem, node.disabled);
      flatNode.source = node.source;
    }

    flatNode.control = node.control;
    if (this.selectedSourcesService.selectedSeriesCache.has(node.id)) {
      this.checklistSelection.select(flatNode);
      if (flatNode.control) {
        flatNode.control.get('checked').setValue(true);
      }
    }
    this.flatNodeMap.set(node.id, flatNode);
    this.nestedNodeMap.set(flatNode.id, node);
    return flatNode;
  }

  getLevel = (node: ObjectFlatNode) => node.level;

  isExpandable = (node: ObjectFlatNode) => node.expandable;

  hasChild = (_: number, _nodeData: ObjectFlatNode) => _nodeData.expandable;

  isSeries = (_: number, _nodeData: ObjectFlatNode) => {
    return _nodeData.type == NodeType.TENANT_MEASUREMENT;
  }

  isEvent = (_: number, _nodeData: ObjectFlatNode) => {
    return _nodeData.type == NodeType.TENANT_EVENT;
  }

  ngOnInit(): void {
    this.selectedSourcesDatabase.initialize(this.tenant.id);

    this.selectedSourcesDatabase.dataChange.pipe(takeUntil(this.ngDestroy)).subscribe((data) => {
      this.dataSource.data = data;
    });

    this.selectedSourcesService.seriesSelect$.pipe(takeUntil(this.ngDestroy)).subscribe((node: ObjectFlatNode) => {
      if (!node) {
        return;
      }

      const parentItem = this.database.getManagedObjectStore().get(node.managedObject.id);
      if (!parentItem) {
        return;
      }

      let seriesNode = this.database.getManagedObjectStore().get(node.id);
      if (!seriesNode) {
        return;
      }

      const parentNode = new ObjectNode(
        parentItem.id,
        parentItem.type,
        parentItem.name,
        parentItem.managedObject,
        parentItem.sourcePath,
        true,
        null,
        false,
        node
      );

      if (this.flatNodeMap.has(seriesNode.id)) {
        this.checklistSelection.select(this.flatNodeMap.get(seriesNode.id));
        if (this.flatNodeMap.get(seriesNode.id).control) {
          this.flatNodeMap.get(seriesNode.id).control.get('checked').setValue(true);
        }
      }

      this.selectedSourcesDatabase.addItem(parentNode);

      let flatNode = this.flatNodeMap.get(parentItem.id);
      if (!this.treeControl.isExpanded(flatNode)) {
        this.treeControl.expand(flatNode);
        this.loadChildren(flatNode);
      }

    });

    this.selectedSourcesService.seriesDeselect$.pipe(takeUntil(this.ngDestroy)).subscribe((source: ObjectFlatNode) => {
      if (!source) {
        return;
      }

      const flatNode = this.flatNodeMap.get(source.id);
      if (flatNode) {
        if (flatNode.control) {
          flatNode.control.get('checked').setValue(false);
        }
        this.checklistSelection.deselect(flatNode);
      }
    });

    this.selectedSourcesService.sourceDeselect$.pipe(takeUntil(this.ngDestroy)).subscribe((source: ObjectFlatNode) => {
      if (!source) {
        return;
      }
      const flatNode = this.flatNodeMap.get(source.id);
      if (flatNode) {
        const parentNode = this.treeControlUtil.getParent(flatNode);
        const result = parentNode ? parentNode : flatNode;
        this.deselectAllDescendents(result);
        this.selectedSourcesDatabase.removeItem(result);
        this.flatNodeMap.delete(result.id);
        if (flatNode.control) {
          flatNode.control.get('checked').setValue(false);
        }
      }
    });
  }

  removeNode(node: ObjectFlatNode) {
    if (node.source) {
      this.selectedSourcesService.deselectSource(node.source);
      this.deselectAllDescendents(node);
    }
  }

  private deselectAllDescendents(parentNode: ObjectFlatNode): void {
    const descendants = this.treeControl.getDescendants(parentNode);
    if (descendants.length == 0) {
      return;
    }

    descendants.forEach((childNode) => {
      this.checklistSelection.deselect(childNode);
      if (this.selectedSourcesService.selectedSeriesCache.has(childNode.id)) {
        this.selectedSourcesService.selectedSeriesCache.delete(childNode.id);
      }
      this.deselectAllDescendents(childNode);
    });
  }

  loadChildren(node: ObjectFlatNode) {
    if (!this.treeControl.isExpanded(node)) {
      node.isLoading = false;
      return;
    }
    node.isLoading = true;
    this.selectedSourcesDatabase.loadMore({
      itemId: node.id,
      onlyWithMeasurements: false,
      onlyFirstTime: true
    }, () => {
      node.isLoading = false;
    });
  }

  todoLeafItemSelectionToggle(node: ObjectFlatNode): void {
    this.checklistSelection.toggle(node);
    if (this.checklistSelection.isSelected(node)) {
      this.selectedSourcesService.selectSeries(node);
    } else {
      this.selectedSourcesService.deselectSeries(node);
    }
  }

  startMapping() {
    this.mappingForm.markAllAsTouched();
    this.mappingForm.updateValueAndValidity();
    if (!this.mappingForm.valid) {
      return;
    }

    const values: ObjectFlatNode[] = this.checklistSelection.selected;
    const mappingsData = new SeriesSourceTenantImportRequest();

    values.forEach((value: ObjectFlatNode) => {
      if (value.disabled === true) {
        return;
      }

      const sourcePath = value.type === NodeType.TENANT_EVENT ? value.control?.get('sourcePath').value : value.sourcePath;
      let lat, lng;
      if (value.managedObject.c8y_Position) {
        lat = value.managedObject.c8y_Position.lat;
        lng = value.managedObject.c8y_Position.lng;
      }

      const sourceDto = Object.assign({}, new TenantSourceDto(
        (String)(this.tenant.id),
        value.managedObject.id,
        value.managedObject.creationDate,
        value.type.toString(),
        sourcePath
      ));

      const source = new TenantMappingDto(
        new MetaFieldsDto({
          name: value.managedObject.name,
          lat: lat,
          lng: lng,
          sourceAssetsTree: this.getAssetsFlatTree(value)
        }),
        sourceDto
      );

      mappingsData.mappings.push(source);
    });

    if (mappingsData.mappings.length === 0) {
      this.toastr.error($localize`Nothing to map. Search and select some series`);
      return;
    }

    this.isLoading = true;
    this.sourceService.importSourcesFromTenant(mappingsData).subscribe(
      (data: any) => {
        this.router.navigate(['/mapping/source-grid']);
      },
      (err: any) => {
        this.toastr.error(err.error.message);
      }
    );
  }

  isChecked(node: any): boolean {
    return this.checklistSelection.isSelected(node) || node.disabled;
  }

  ngOnDestroy() {
    this.ngDestroy.next();
    this.ngDestroy.complete();
    this.selectedSourcesDatabase.ngOnDestroy();
    this.selectedSourcesService.ngOnDestroy();
  }

  private getAssetsFlatTree(node: ObjectFlatNode): SourceAssetsFlatTree {
    const tenantBrowserNode: ObjectFlatNode = this.tenantBrowser.getFlatNodeMap().get(node.managedObject.id);
    if (!tenantBrowserNode) {
      return null;
    }

    return new SourceAssetsFlatTree({
      assets: this.tenantBrowser.getAssetsHierarchy(tenantBrowserNode)
    });
  }

}
