<div class="content-container" id="setup-wizard">

  <div class="page-title-bar">
    <div class="d-flex">
      <div class="d-flex flex-column justify-content-center align-items-start">
        <h2 class="mb-1" i18n>Setup wizard</h2>
      </div>
    </div>
    <ul class="navbar-nav navbar-wizard">
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': isActiveStep('source-type'), 'completed': isCompletedStep('source-type')}" (click)="setActiveStep('source-type')">
          <span class="circle mr-2">1</span>
          <span class="label" i18n>Choose source</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': isActiveStep('configure'), 'completed': isCompletedStep('configure'), 'disabled': !isCompletedStep('source-type')}" (click)="setActiveStep('configure')">
          <span class="circle mr-2">2</span>
          <span class="label" i18n>Configure</span>
        </a>
      </li>
      <li class="nav-item">
        <a class="nav-link" [ngClass]="{'active': isActiveStep('finalize'), 'completed': isCompletedStep('finalize'), 'disabled': !isCompletedStep('configure')}" (click)="setActiveStep('finalize')">
          <span class="circle mr-2">3</span>
          <span class="label" i18n>Start mapping</span>
        </a>
      </li>
    </ul>
  </div>

  <div class="container-fluid p-5">
    <div class="hidden-block active" *ngIf="isActiveStep('source-type')">
      <div class="row">
        <h3 class="col-12" i18n>Choose source</h3>
        <div class="col-12">
          <p class="lead" i18n>Configure a Tenant for import sources from Cumulocity or use API key.</p>
          <button type="button" class="btn btn-action mr-3" (click)="setSourceTypeAndContinue('tenant')" i18n>Tenant</button>
          <button type="button" class="btn btn-action" (click)="setSourceTypeAndContinue('dal-api')" i18n>API key</button>
        </div>
      </div>
    </div>

    <div class="hidden-block active" *ngIf="isActiveStep('configure') && sourceType == 'tenant'">
      <app-tenant-setup-component (continue)="confirmSourceConfigAndContinue($event)"></app-tenant-setup-component>
    </div>

    <div class="hidden-block active " *ngIf="isActiveStep('finalize') && sourceType == 'tenant'">

      <div class="row justify-content-center d-flex mt-2">
        <div class="col-12 text-center">
          <h3 class="mb-3">
            <span i18n>Import from Cumulocity</span>
            <i class="fa fa-info-circle ml-2 mt-2 btn-tooltip" [popover]="'Use keyword or Cumulocity query language'" placement="right" triggers="mouseenter:mouseleave"></i>
          </h3>

          <p i18n class="help-text">Choose the objects what would you like to import from Cumulocity for mapping.</p>
        </div>
        <div class="col-md-12 col-lg-10 text-center">
          <app-tenant-browser *ngIf="sourceSelection" [tenant]="sourceSelection"></app-tenant-browser>
        </div>
      </div>
    </div>

    <div class="hidden-block active" *ngIf="isActiveStep('configure') && sourceType == 'dal-api'">
      <app-token-setup-component (continue)="confirmSourceConfigAndContinue($event)"></app-token-setup-component>
    </div>

    <div class="hidden-block active" *ngIf="isActiveStep('finalize') && sourceType == 'dal-api'">
      <app-token-test-component [token]="sourceSelection"></app-token-test-component>
    </div>
  </div>

</div>
