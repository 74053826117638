import {Position} from '../position';
import {ChildDevices} from './child-devices';
import {ChildAssets} from './child-assets';

export class ManagedObject {
  id: string;
  name: string;
  type: string;
  creationDate: Date;
  c8y_IsDevice: any;
  c8y_IsDeviceGroup: any;
  c8y_Position: Position;
  childDevices: ChildDevices;
  childAssets: ChildAssets;

  static TYPE_DAL_AGGREGATION: string = 'DAL_series_aggregation';
}
