import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {HttpClient, HttpXhrBackend} from '@angular/common/http';
import {debounceTime, distinctUntilChanged, map, startWith, switchMap, takeUntil, tap} from 'rxjs/operators';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Subject} from 'rxjs/internal/Subject';
import {Observable} from 'rxjs/internal/Observable';

@Component({
  selector: 'app-address-dawa',
  templateUrl: './address-dawa.component.html',
  styleUrls: ['./address-dawa.component.css']
})
export class AddressDawaComponent implements OnInit, OnDestroy {
  private ngDestroy = new Subject<void>();
  private httpClient: HttpClient;

  @Input()
  form: UntypedFormGroup;

  @Input()
  submitted: any;

  public searching = false;
  public dawaAddress = new UntypedFormControl();

  addressSearch(text$: Observable<string>) {
    return text$.pipe(
      debounceTime(350),
      distinctUntilChanged(),
      tap(() => this.searching = true),
      switchMap((text: string) => {
        return this.httpClient.get('https://dawa.aws.dk/autocomplete?fuzzy=true&q=' + encodeURI(text), {withCredentials: false});
      }),
      map((results: any) => {
        if (!results) {
          return [];
        }
        return results;
      }),
      tap(() => this.searching = false)
    );
  }

  dawaInputFormatter = (input: any) => {
    if ( input.tekst !== undefined ) {
      return input.tekst;
    } else {
      return input;
    }
  };
  dawaResultFormatter = (result: any) => result.tekst;

  constructor(
    private http: HttpClient,
    private httpBackend: HttpXhrBackend,
  ) {
    this.httpClient = new HttpClient(httpBackend);
    this.addressSearch = this.addressSearch.bind(this);

    this.dawaAddress.valueChanges.subscribe((address) => {
      if (address && address.tekst && address.data) {
        this.form.get('address').setValue(address.tekst);
      } else {
        this.form.get('address').setValue(address);
      }
    });
  }

  ngOnInit() {
    this.dawaAddress.setValue(this.form.get('address').value);

    this.form.valueChanges.pipe(startWith(this.form.getRawValue()), takeUntil(this.ngDestroy)).subscribe((value) => {
      this.dawaAddress.setValue(value.address, {emitEvent: false, onlySelf: true});
    });
  }

  ngOnDestroy(): void {
    this.ngDestroy.next();
    this.ngDestroy.complete();
  }
}
