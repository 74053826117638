import {Component, Input, OnInit, ViewEncapsulation} from '@angular/core';
import {Token} from '../../../core/model/person';
import {ApiService} from '../../../core/http/api.service';
import {environment} from '../../../../environments/environment';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-token-test-component',
  templateUrl: './token-test.component.html',
  providers: [ApiService],
  encapsulation: ViewEncapsulation.None
})
export class TokenTestComponent implements OnInit {

  @Input()
  public token: Token = null;

  public contactUrl = 'malito:support@datamaping.iot.telia.ee';
  public contactLabel = 'support@datamaping.iot.telia.ee';

  public dataSourceId = 'device-sensor-property-' + Math.round(Math.random() * 100);

  public sampleData = {
    data: [
      {
        dataSourceId: this.dataSourceId,
        deviceId: 'some-device-unique-id',
        values: [
          {
            time: '',
            unit: '%',
            value: 59
          }
        ]
      }
    ]
  };

  public sampleEventData = {
    data: [
      {
        dataSourceId: this.dataSourceId,
        deviceId: 'some-device-unique-id',
        values: [
          {
            time: '',
            unit: '%',
            value: 'some-string',
            someProperty: {
              someProperty: 'some-value'
            },
          }
        ]
      }
    ]
  };

  public sampleMetadata = {
    mappings: [
      {
        metaFields: {
          name: 'Demo datasource',
          groupingTag: 'BUILDINGS',
          seriesType: 'HEAT',
          locationType: 'ADDRESS',
          address: '123 Main St',
          apartment: '5',
          room: 'Living room',
          lat: 58.3749329,
          lng: 26.6625058,
          uuid: 'some-identifier-123',
          personCountryCode: environment.defaultCountryCode,
          personRegistrationNumber: '50001010006'
        },
        source: {
          deviceId: 'some-device-unique-id',
          dataSourceId: this.dataSourceId,
        }
      }
    ]
  };

  constructor(
    public apiService: ApiService,
    public toastr: ToastrService
  ) {
    const time = new Date();
    this.sampleData.data[0].values[0].time = time.toISOString();
    this.sampleData.data[0].values[0].value = Math.round(Math.random() * 10000) / 100;
  }

  ngOnInit() {}

  regenerateDatasource() {
    this.dataSourceId = 'device-sensor-property-' + Math.round(Math.random() * 100);
    this.sampleData.data[0].dataSourceId = this.dataSourceId;
    this.sampleMetadata.mappings[0].source.dataSourceId = this.dataSourceId;
  }

  test() {
    const time = new Date();
    this.sampleData.data[0].values[0].time = time.toISOString();
    this.sampleData.data[0].values[0].value = Math.round(Math.random() * 10000) / 100;
    this.apiService.postData(this.sampleData, this.token).subscribe(value => {
      this.toastr.success($localize`Demo data posted successfully!`);
    }, (err: any) => {
      console.error(err);
      this.toastr.error($localize`Something went wrong`);
    });
  }

  testEvent() {
    const time = new Date();
    this.sampleData.data[0].values[0].time = time.toISOString();
    this.sampleData.data[0].values[0].value = Math.round(Math.random() * 10000) / 100;

    this.apiService.postData(this.sampleEventData, this.token).subscribe(value => {
      this.toastr.success($localize`Demo event data posted successfully!`);
    }, (err: any) => {
      console.error(err);
      this.toastr.error($localize`Something went wrong`);
    });
  }

  testMapping() {
    this.apiService.postMetadata(this.sampleMetadata, this.token).subscribe(value => {
      this.toastr.success($localize`Demo metadata posted successfully!`);
    }, (err: any) => {
      console.error(err);
      this.toastr.error($localize`Something went wrong`);
    });
  }
}
