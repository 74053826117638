<!-- mobile top nav start -->
<div *ngIf="authService.authenticated && authService.profileData" class="top-navbar-mobile">
  <nav class="navbar navbar-purple">
    <div class="py-3 d-flex justify-content-center align-items-center w-100">
      <a routerLink="/" class="d-block d-lg-none">
        <img [src]="'assets/img/city_portal_logo_h.svg'" class="img-fluid flex-center">
      </a>
    </div>
  </nav>
  <nav class="navbar navbar-light">
    <ul class="navbar-nav nav-icon flex-grow-1">
      <li class="nav-item">
        <app-language-select></app-language-select>
      </li>
    </ul>

    <ul class="navbar-nav nav-icon nav-icon-border">
      <li class="nav-item dropdown" dropdown>
        <a class="nav-link dropdown-toggle text-uppercase no-caret text-truncate" aria-haspopup="true" aria-expanded="false" dropdownToggle>
          <i class="fal fa-user mr-3"></i> {{authService.profileData.mandate?.person.displayName}}
        </a>
        <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
          <!-- text-success -->
          <a [routerLink]="" class="dropdown-item" (click)="selectPerson(person)" *ngFor="let person of authService.profileData.availablePersons">{{person.displayName}}</a>
          <div *ngIf="authService.profileData.availableMandates.length > 0" class="dropdown-divider"></div>
          <a [routerLink]="" class="dropdown-item" (click)="selectPerson(mandate)" *ngFor="let mandate of authService.profileData.availableMandates">{{mandate.displayName}}</a>
        </div>
      </li>
      <li class="nav-item">
        <a class="nav-link sign-out" [routerLink]="" (click)="logout()">
          <i class="fal fa-sign-out"></i>
        </a>
      </li>
    </ul>
  </nav>
</div>

<div class="top-navbar">
  <div class="header__top d-none d-lg-flex" [id]="id + '-desktop'">
    <div class="container header-container">

      <div class="header__top-inner">
        <div class="header__top-left">
          <div class="header__logo">
            <a href class="logo__img"><span class="logo__text">xx</span></a>
          </div>
          <nav class="header__nav" *ngIf="authService.authenticated && authService.profileData">
            <ul class="header__nav-list">
              <li class="header__nav-item" routerLink="/mapping" routerLinkActive="active">
                <a class="header__nav-item-link">
                  <span class="header__nav-item-label" i18n>Sources</span>
                  <i class="fa-light fa-mobile-screen-button"></i>
                </a>
              </li>
              <li class="separator"></li>
              <li class="header__nav-item" routerLink="/mandate" routerLinkActive="active">
                <a class="header__nav-item-link">
                  <span class="header__nav-item-label" i18n>Mandates</span>
                  <i class="fa-light fa-user-group"></i>
                </a>
              </li>
              <li class="separator"></li>
              <li class="header__nav-item" routerLink="/authorizations" routerLinkActive="active">
                <a class="header__nav-item-link">
                  <span class="header__nav-item-label" i18n>Authorizations</span>
                  <i class="fa-light fa-lock-keyhole"></i>
                </a>
              </li>
            </ul>
          </nav>
        </div>
        <div class="header__top-right">
          <div class="header__languages">
            <header-language-select></header-language-select>
          </div>
          <div class="header__actions">
            <button class="header__action-button" (click)="showTermsAndConditions()" >
              <div class="header__button-inner">
                <span class="header__info-icon" tooltip="Terms and conditions" i18n-tooltip container="body"></span>
              </div>
            </button>

            <nav class="header__nav header__actions-nav">
              <ul class="header__nav-list">
                <li class="separator"></li>
                <li class="header__nav-item" dropdown>
                  <a class="nav-link dropdown-toggle no-caret" aria-haspopup="true" aria-expanded="false" dropdownToggle>
                    {{authService.profileData.mandate.person.displayName}}
                  </a>
                  <div class="dropdown-menu dropdown-menu-right" *dropdownMenu>
                    <a [routerLink]="" class="dropdown-item" (click)="selectPerson(person)" *ngFor="let person of authService.profileData.availablePersons">{{person.displayName}}</a>
                    <div *ngIf="authService.profileData.availableMandates.length > 0" class="dropdown-divider"></div>
                    <a [routerLink]="" class="dropdown-item" (click)="selectPerson(mandate)" *ngFor="let mandate of authService.profileData.availableMandates">{{mandate.displayName}}</a>
                  </div>
                </li>
                <li class="separator"></li>
                <li class="header__nav-item">
                  <button class="header__action-button" (click)="openFeedbackModal()" >
                    <div class="header__button-inner">
                      <span class="header__feedback-icon" tooltip="Send feedback" i18n-tooltip container="body"></span>
                    </div>
                  </button>
                </li>
                <li class="separator"></li>
                <li class="header__nav-item">
                  <button class="header__action-button header__action-button-logout" [routerLink]="" (click)="logout()">
                    <div class="header__button-inner">
                      <i class="fa-regular fa-arrow-right-from-bracket"></i>
                    </div>
                  </button>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="header__bottom" *ngIf="(breadcrumbs$ | async | slice:-1)[0]">
    <div class="container header-container">
      <div class="header__bottom-inner">
        <div class="header__bottom-left">
          <div class="header__nav-title">{{(breadcrumbs$ | async | slice:-1)[0]?.name$ | async}}</div>
          <ng-container *ngTemplateOutlet="menuComponent$ | async"></ng-container>
        </div>
        <div class="header__bottom-right" *ngIf="actionsComponent$ | async">
          <ng-container *ngTemplateOutlet="actionsComponent$ | async"></ng-container>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- mobile bottom nav start -->
<nav *ngIf="authService.authenticated && authService.profileData" class="navbar bottom-navbar-mobile">
  <ul class="navbar-nav">
    <li class="nav-item" routerLink="/mapping" routerLinkActive="active">
      <a class="nav-link">
        <i class="icon icon-datapoint"></i>
        <span i18n>Sources</span>
      </a>
    </li>
    <li class="nav-item" routerLink="/mandate" routerLinkActive="active">
      <a class="nav-link">
        <i class="icon icon-mandate"></i>
        <span i18n>Mandates</span></a>
    </li>
    <li class="nav-item" routerLink="/authorizations" routerLinkActive="active">
      <a class="nav-link">
        <i class="icon icon-authorization"></i>
        <span i18n>Authorizations</span>
      </a>
    </li>
  </ul>
</nav>
<!-- mobile bottom nav end -->
